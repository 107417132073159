import moment from 'moment';
export const organizationList = (state) => state.organizationList.map((item, index)  => {

    return {
        ...item,
        sl: index+1,
        created_at: item.created_at?moment(item.created_at).format('D MMM, YYYY. ddd'):'',
        
    }
});
export const organizationMeta = (state) => state.organizationMeta;
export const selectedOrganizationList = (state) => {
    const item  = state.organizationList;
    return item;
};