<template>
    <div
      class="inline-flex"
      :class="[container,
          inline ? 'flex-row align-center' : 'flex-col'
      ]">
      <label
        v-if="label"
        class="pr-4 inline-block mb-1 text-uppercase font-bold"
        :class="[
          requiredLabel ? 'required' : '',
          italic ? 'text-italic' : '',
        ]"
      >
        {{ label }}
      </label>
      <div class="flex flex-col basis relative">
        <md-autocomplete 
          :value="this.value" 
          :md-options="this.items"
          class="autocomplete-component input-box-shadow pointer flat rounded min-h-auto"
          :class="[
            borderNone ? 'border-none' : 'outline-gray-400', ...classes
          ]"
           @input="onSelect"
           :placeholder="placeholder"
           md-layout="box"
           md-dense
        >
          <label v-if="placeholder" class="mt-0">{{ placeholder }}</label>
          <template slot="md-autocomplete-item" slot-scope="{ item, term }">
            <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
          </template>
          <template slot="md-autocomplete-empty">
            No item matching
          </template>
        </md-autocomplete>
        <md-icon class="arrow-icon" :class="[iconClass]">arrow_drop_down</md-icon>
        <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      classes: {
        type: Array,
        default: () => ['py-1 min-h-10 mt-0 mb-0'],
      },
      label: {
        type: String,
        default: null,
      },
      iconClass: {
        type: String,
        default: null,
      },
      container: {
        type: String,
        default: null,
      },
      requiredLabel: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: [String, Boolean],
        default: 'Select one',
      },
      borderNone: {
        type: Boolean,
        default: false,
      },
      italic: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        default: null,
      },
      value: {
        type: String,
        default: () => '',
      },
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      returnType: {
        type: String,
        default: 'id',
      },
      defaultValue: {  
        type: [Object, String],
        default: () => null,
      },
    },
    methods: {
      onSelect(selectedItem) {
        console.log(selectedItem, 'selectedItem')
        if (selectedItem) {
          let value = selectedItem.id;
          if (this.returnType === 'object') {
            value = this.items.find((item) => item.id === value);
            this.$emit('getObject', value);
          } else if (this.returnType === 'index') {
            this.$emit('getIndex', this.items.findIndex((item) => item.id === value));
          } else {
            this.$emit('input', value);
          }
        } else {
          this.$emit('clear', selectedItem);
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .autocomplete-component {
    border-radius: 5px;
    outline: none;
    background-color: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .autocomplete-component .arrow-icon {
    position: absolute;
    top: 10px;
    right: 3px;
    margin: 0;
    pointer-events: none;
  }
  .md-field.md-inline.md-autocomplete-box .md-input {
    padding-left: 12px !important;
  }
  .autocomplete-component .md-input,
  .autocomplete-component .md-field label {
    font-size: 14px !important;
  }
  </style>
  