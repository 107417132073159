import api from '../api'

const state = () => ({
    explanation: {},
    prompts: []
});


const getters = {
    getPrompts: state =>  state.prompts.map((item, index) => {
        item.sl = index + 1;
        return item;
    }),
}


const mutations = {
    setPromptList(state, payload) {
        state.prompts = payload;
    },

}

const actions = {
    async actSavePrompt ( store, payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/prompts`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actListPrompt ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/prompts${query}`)
                    .then(({ items }) => {
                        resolve(items);
                        commit('setPromptList',items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actChangePromptStatus (store, {promptId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/prompts/${promptId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }