
import moment from 'moment';
export const jobList = (state) => state.jobList.map((item, index)  => {
    return {
        id: index+1,
        jobId: item.id,
        jobTitle: item.title,
        description: item.description,
        jobPosition: item.job_position,
        jobType: item.job_circular_detail?item.job_circular_detail.type.replace('_',' '):'',
        salaryType: item.job_circular_detail?item.job_circular_detail.salary_type:'',
        salaryRangeMin: item.job_circular_detail?item.job_circular_detail.salary_range_min:'',
        salaryRangeMax: item.job_circular_detail?item.job_circular_detail.salary_range_max:'',
        workplace: item.job_circular_detail?item.job_circular_detail.workplace:'',
        restrictions: item.job_circular_detail?item.job_circular_detail.restrictions:'',
        addedAt: item.posting_date?moment(item.posting_date).format('D MMM, YYYY. ddd'):'',
        // deadLine: item.job_circular_detail?item.job_circular_detail.deadline?moment(item.job_circular_detail.deadline).format('D MMM, YYYY. ddd'):'':'',
        deadLine: item.job_circular_detail?item.job_circular_detail.deadline?item.job_circular_detail.deadline:'':'',
        numberOfApplicant: item.application_count,
        status: item.active,
    }
});
export const jobMeta = (state) => state.jobMeta;
export const selectedJobList = (state) => {
    
    const item  = state.jobList;
    return {
        jobId: item.id,
        jobTitle: item.title,
        description: item.description,
        jobPosition: item.job_position,
        jobType: item.job_circular_detail?item.job_circular_detail.type.replace('_',' '):'',
        salaryType: item.job_circular_detail?item.job_circular_detail.salary_type:'',
        salaryRangeMin: item.job_circular_detail?item.job_circular_detail.salary_range_min:'',
        salaryRangeMax: item.job_circular_detail?item.job_circular_detail.salary_range_max:'',
        workplace: item.job_circular_detail?item.job_circular_detail.workplace:'',
        restrictions: item.job_circular_detail?item.job_circular_detail.restrictions:'',
        addedAt: item.posting_date?moment(item.posting_date).format('D MMM, YYYY. ddd'):'',
        deadLine: item.job_circular_detail?item.job_circular_detail.deadline?moment(item.job_circular_detail.deadline).format('D MMM, YYYY. ddd'):'':'',
        numberOfApplicant: item.application_count,
        status: item.active,
    }
    
};


export const jobDataFormat = () => (item) => {

    return {
        jobId: item.id,
        jobTitle: item.title,
        description: item.description,
        jobPosition: item.job_position,
        jobType: item.job_circular_detail?item.job_circular_detail.type.replace('_',' '):'',
        salaryType: item.job_circular_detail?item.job_circular_detail.salary_type:'',
        salaryRangeMin: item.job_circular_detail?item.job_circular_detail.salary_range_min:'',
        salaryRangeMax: item.job_circular_detail?item.job_circular_detail.salary_range_max:'',
        workplace: item.job_circular_detail?item.job_circular_detail.workplace:'',
        restrictions: item.job_circular_detail?item.job_circular_detail.restrictions:'',
        addedAt: item.posting_date?moment(item.posting_date).format('D MMM, YYYY. ddd'):'',
        deadLine: item.job_circular_detail?item.job_circular_detail.deadline?moment(item.job_circular_detail.deadline).format('D MMM, YYYY. ddd'):'':'',
        numberOfApplicant: item.application_count,
        status: item.active,
    }
};