import api from '../api'

const state = () => ({
    result: {},
    results: [],
    WrittenDiscourse:[],
    words:[],
    LoadingStatus: true
});


const getters = {
    getResults: state =>  state.results,
    getResult: state =>  state.result,
    getWrittenDiscourse: state =>  state.WrittenDiscourse,
    getWordDetails: state =>  state.words,
    LoadingStatus: state => state.LoadingStatus
}


const mutations = {

    setResultList(state, payload) {
        state.results = payload;
    },
    setWordDetails(state, payload) {
        state.words = payload;
    },
    setResultShow(state, payload) {
        state.result = payload;
    },
    setWrittenDiscourseShow(state, payload) {
        state.result = payload;
    },
    LoadingStatus (state, payload){
        state.LoadingStatus = payload
    }
}

const actions = {

      /*
        answer sheet submit
    */
    async actSubmitactResultList ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/answer-sheets', payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actResultList ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/answer-sheets${query}`)
                    .then( response => {
                        commit('setResultList', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actWordDetails ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/word-details${query}`)
                    .then( response => {
                        commit('setWordDetails', response)
                        commit('LoadingStatus', true)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actShowResult ({ commit }, answer_sheet_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/answer-sheets/${answer_sheet_id}`)
                    .then( response => {
                        commit('setResultShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actWrittenDiscourse ({ commit }, Q_type_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/settings/written-discourse/question-type/${Q_type_id}`)
                    .then( response => {
                        commit('setWrittenDiscourseShow', response)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}