export const questionAndTestManagement = [
     {
        path: 'question-and-test-management',
        name: 'master.questionAndTestManagement',
        component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/question-and-test-management/IndexView.vue'),
        meta: { layout: 'master' },
        redirect: {name: 'master.QATM.toolKit.prompt'},
        children: [
            /*
              01. ======= Toolkit Routes Start ========
            */
            {
                path: 'toolkit',
                name: 'master.QATM.toolKit',
                redirect: {name: 'master.QATM.toolKit.prompt'},
                component: () => import(
                    /* webpackChunkName: "master-QATM-toolKit" */
                    '../../views/master/question-and-test-management/toolkit/IndexView.vue'
                    ),
                meta: { layout: 'master' },
                children: [
                    {
                        path: 'prompt',
                        name: 'master.QATM.toolKit.prompt',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/PromptView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'time-set',
                        name: 'master.QATM.toolKit.timeSet',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/TimeSetView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'intro-communication',
                        name: 'master.QATM.toolKit.introCommunication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/IntroCommunicationView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'end-communication',
                        name: 'master.QATM.toolKit.endCommunication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/EndCommunicationView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'iframe-marker',
                        name: 'master.QATM.toolKit.iframeMarker',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/IframeMarkerView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'study-guide',
                        name: 'master.QATM.toolKit.studyGuide',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-toolKit" */
                            '../../views/master/question-and-test-management/toolkit/StudyGuideView.vue'),
                        meta: { layout: 'master' },                                                                                         
                    }
                ]
            },
            /*
             02. ======= Question Management Routes Start ========
            */
            {
                path: 'question-management',
                name: 'master.QATM.questionManagement',
                redirect: {name: 'master.QATM.questionManagement.list'},
                component: () => import(
                    /* webpackChunkName: "master-QATM-question-management" */
                    '../../views/master/question-and-test-management/question-management/IndexView.vue'
                    ),
                meta: { layout: 'master' },
                children: [
                    {
                        path: 'list',
                        name: 'master.QATM.questionManagement.list',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/ListView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    
                    {
                        path: 'question-answers/:id',
                        name: 'master.QATM.questionManagement.questionAnswers',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/QuestionAnswersView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },

                    {
                        path: 'assign-to-trainer-or-admin',
                        name: 'master.QATM.questionManagement.assignToTrainerOrAdmin',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/AssignToTrainerOrAdminView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-branch',
                        name: 'master.QATM.questionManagement.assignToBranch',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/AssignToBranchView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'draft',
                        name: 'master.QATM.questionManagement.draft',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/DraftView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'bulk-upload',
                        name: 'master.QATM.questionManagement.bulkUpload',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/BulkUploadView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'communication',
                        name: 'master.QATM.questionManagement.communication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-question-management" */
                            '../../views/master/question-and-test-management/question-management/CommunicationView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                ]
            },
            /*
              03. ======= Test Management Routes Start ========
            */
            {
                path: 'test-management',
                name: 'master.QATM.testManagement',
                redirect: {name: 'master.QATM.testManagement.list'},
                component: () => import(
                    /* webpackChunkName: "master-QATM-test-management" */
                    '../../views/master/question-and-test-management/test-management/IndexView.vue'
                    ),
                meta: { layout: 'master' },
                children: [
                    {
                        path: 'list',
                        name: 'master.QATM.testManagement.list',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-test-management" */
                            '../../views/master/question-and-test-management/test-management/ListView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-trainer-or-admin',
                        name: 'master.QATM.testManagement.assignToTrainerOrAdmin',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-test-management" */
                            '../../views/master/question-and-test-management/test-management/AssignToTrainerOrAdminView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-branch',
                        name: 'master.QATM.testManagement.assignToBranch',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-test-management" */
                            '../../views/master/question-and-test-management/test-management/AssignToBranchView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'draft',
                        name: 'master.QATM.testManagement.draft',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-test-management" */
                            '../../views/master/question-and-test-management/test-management/DraftView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'communication',
                        name: 'master.QATM.testManagement.communication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-test-management" */
                            '../../views/master/question-and-test-management/test-management/CommunicationView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                ]
            },
            /*
              04. ======= Material Management Routes Start ========
            */
            {
                path: 'material-management',
                name: 'master.QATM.materialManagement',
                redirect: {name: 'master.QATM.materialManagement.list'},
                component: () => import(
                    /* webpackChunkName: "master-QATM-material-management" */
                    '../../views/master/question-and-test-management/material-management/IndexView.vue'
                    ),
                meta: { layout: 'master' },
                children: [
                    {
                        path: 'list',
                        name: 'master.QATM.materialManagement.list',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/ListView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-trainer-or-admin',
                        name: 'master.QATM.materialManagement.assignToTrainerOrAdmin',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/AssignToTrainerOrAdminView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-branch',
                        name: 'master.QATM.materialManagement.assignToBranch',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/AssignToBranchView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'draft',
                        name: 'master.QATM.materialManagement.draft',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/DraftView.vue'
                            ),
                        meta: { layout: 'master' },
                    },
                    {
                        path: 'explanation',
                        name: 'master.QATM.materialManagement.explanation',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/ExplanationView.vue'
                            ),
                        meta: { layout: 'master' },
                    },

                    {
                        path: 'bulk-upload',
                        name: 'master.QATM.materialManagement.bulkUpload',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/BulkUploadView.vue'
                            ),
                        meta: { layout: 'master' },
                    },
                    {
                        path: 'study-guide',
                        name: 'master.QATM.materialManagement.study-guide',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/StudyGuide.vue'
                            ),
                        meta: { layout: 'master' },
                    },
                    {
                        path: 'sample-answer',
                        name: 'master.QATM.materialManagement.sample-answer',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/SampleAnswer.vue'
                            ),
                        meta: { layout: 'master' },
                    },
                    {
                        path: 'communication',
                        name: 'master.QATM.materialManagement.communication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-material-management" */
                            '../../views/master/question-and-test-management/material-management/CommunicationView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                ]
            },
            /*
              05. ======= Quiz Management Routes Start ========
            */
            {
                path: 'quiz-management',
                name: 'master.QATM.quizManagement',
                redirect: {name: 'master.QATM.quizManagement.list'},
                component: () => import(
                    /* webpackChunkName: "master-QATM-quiz-management" */
                    '../../views/master/question-and-test-management/quiz-management/IndexView.vue'
                    ),
                meta: { layout: 'master' },
                children: [
                    {
                        path: 'list',
                        name: 'master.QATM.quizManagement.list',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/ListView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                     
                    },
                    {
                        path: 'assign-to-trainer-or-admin',
                        name: 'master.QATM.quizManagement.assignToTrainerOrAdmin',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/AssignToTrainerOrAdminView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'assign-to-branch',
                        name: 'master.QATM.quizManagement.assignToBranch',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/AssignToBranchView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'draft',
                        name: 'master.QATM.quizManagement.draft',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/DraftView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'bulk-upload',
                        name: 'master.QATM.quizManagement.bulkUpload',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/BulkUploadView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    },
                    {
                        path: 'communication',
                        name: 'master.QATM.quizManagement.communication',
                        component: () => import(
                            /* webpackChunkName: "master-QATM-quiz-management" */
                            '../../views/master/question-and-test-management/quiz-management/CommunicationView.vue'
                            ),
                        meta: { layout: 'master' },                                                                                         
                    }
                ]
            },
        ]
    }
]
   
