export default [
    {
        path: '/job-circular/:jobCircularId',
        name: 'jobApplicationCircularId',
        // component: () => import(
        //     '../views/job-application/BasicInformationView.vue'),
        meta: { layout: 'job-application' },
        redirect: {name: 'jobApplicationBasicInformation'},
    },
    {

        path: '/job-application',
        name: 'jobApplication',
        component: () => import(
            /* webpackChunkName: "job-application" */
            '../views/job-application/jobApplicationView.vue'
            ),
        meta: { layout: 'job-application' },
        redirect: {name: 'jobApplicationBasicInformation'},
        children: [
            {
                path: 'basic-information/:jobCircularId',
                name: 'jobApplicationBasicInformation',
                component: () => import(
                    /* webpackChunkName: "basic-information" */
                    '../views/job-application/BasicInformationView.vue'),
                meta: { layout: 'job-application' }
            },
            {
                path: 'job-properties',
                name: 'jobProperties',
                component: () => import(
                    /* webpackChunkName: "job-properties" */
                    '../views/job-application/JobPropertiesView.vue'),
                meta: { layout: 'job-application' }
            },
            {
                path: 'educational-qualification',
                name: 'educationalQualification',
                component: () => import(
                    /* webpackChunkName: "educational-qualification" */
                    '../views/job-application/EducationalQualificationView.vue'),
                meta: { layout: 'job-application' }
            },
            {
                path: 'employment-history',
                name: 'employmentHistory',
                component: () => import(
                    /* webpackChunkName: "employment-history" */
                    '../views/job-application/EmploymentHistoryView.vue'),
                meta: { layout: 'job-application' }
            },
            {
                path: 'job-questions',
                name: 'jobApplicationquestions',
                component: () => import(
                    /* webpackChunkName: "questions" */
                    '../views/job-application/QuestionsView.vue'),
                meta: { layout: 'job-application' }
            },
            {
                path: 'review',
                name: 'reviewAndSubmit',
                component: () => import(
                    /* webpackChunkName: "review" */
                    '../views/job-application/ReviewAndSubmitView.vue'),
                meta: { layout: 'job-application' }
            },
        //     {
        //         path: 'success',
        //         name: 'registrationSuccess',
        //         component: () => import(
        //             /* webpackChunkName: "payment" */
        //             '../views/job-application/SuccessView.vue'),
        //         meta: { layout: 'job-application' }
        //     },
            
        ]
    },
]