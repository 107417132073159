import moment from 'moment';
export const packageList = (state) => state.packageList.map((item, index)  => {

    return {
        ...item,
        sl: index+1,
        created_at: item.created_at?moment(item.created_at).format('D MMM, YYYY. ddd'):'',
        
    }
});
export const packageMeta = (state) => state.packageMeta;
export const selectedpackageList = (state) => {
    const item  = state.packageList;
    return item;
};