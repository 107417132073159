<template>
  <div class="flex align-center">
    <label class="text-capitalize inline-block mr-4 font-semibold text-base w-32">{{ label }}</label>
    <div class="outline-gray-400 p-1 rounded center">
      <md-icon
        class="bg-default p-4 text-white m-0 rounded text-2xl"
        :class="[amount <= 1 ? 'cursor-disable' : 'pointer']"
        @click.native="$emit('decrease-amount', amount)"
      >
        keyboard_arrow_left
      </md-icon>
      <span class="mx-4 text-base font-semibold"> {{ amount }} </span>
      <md-icon
        @click.native="$emit('increase-amount', amount)"
        class="bg-default p-4 text-white m-0 rounded text-2xl"
        :class="[amount >= maxAmount ? 'cursor-disable' : 'pointer']"
      >
        keyboard_arrow_right
      </md-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    amount: {
      type: Number,
      default: 0,
    },
    maxAmount: {
      type: Number,
      default: 5,
    },
  },
};
</script>