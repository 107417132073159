<template>
  <Card>
    <CardHeader class="flex justify-between" color="#514b99">
      <div class="p-3 title flex">
        <md-icon class="text-white">event</md-icon>
        <h3 class="pl-2 text-white text-uppercase">{{ $t(label) }}</h3>
      </div>
      <div class="p-3 action">
        <md-icon class="text-white">remove_red_eye</md-icon>
      </div>
    </CardHeader>
    <CardBody class="p-3">
      <div class="flex">
        <div>
          <EventItem title="Promotion" />
          <EventItem title="Workshop"  />
        </div>
        <div>
          <EventItem title="Special class" divider />
          <EventItem title="Webiners" divider />
        </div>
      </div>
    </CardBody>
  </Card>
</template>

<script>
import { Card, CardHeader, CardBody } from "@/components";
import EventItem from "../atom/EventItem";
export default {
  name: "Event-Component",
  components: {
    Card,
    CardHeader,
    CardBody,
    EventItem,
  },
  props: {
    label: String
  }
};
</script>

<style lang="scss">
.horizontal-divider {
  width: 1px;
  height: 80px;
  background: rgb(206, 206, 206);
}
</style>
