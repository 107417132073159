const branches = [
    {id: 1, name: 'Dhanmondi'},
    {id: 2, name: 'Baridhara'},
    {id: 3, name: 'Gulshan 1'},
    {id: 4, name: 'Uttara'}
];

const source = [
    {id: 'EXAM_QUESTION', name: 'Exam Question'},
    {id: 'PRACTICE', name: 'Practice'}
];
const frequency = [
    {id: 'HIGH', name: 'High', color: 'text-success'},
    {id: 'MODERATE', name: 'Moderate', color: 'text-tahiti-gold'},
    {id: 'LOW', name: 'low', color: 'text-gray-500'}
];

const statuses = [
    {id: 1, name: 'All'},
    {id: 2, name: 'Pending'},
    {id: 3, name: 'Canceled'},
    {id: 4, name: 'Success'},
    {id: 5, name: 'Completed'}
];

const file_type = [
    {id: "DOC", name: 'DOC'},
    {id: "AUDIO", name: 'AUDIO'},
    {id: "VIDEO", name: 'VIDEO'},
    {id: "IMAGE", name: 'IMAGE'}
];

const applicantStatus = [
    {id: 1, name: 'Very Fit'}
];

const minutes = [
    {id: 0, name: '00'},
    {id: 1, name: '01'},
    {id: 2, name: '02'},
    {id: 3, name: '03'},
    {id: 4, name: '04'},
    {id: 5, name: '05'},
    {id: 10, name: '10'},
    {id: 15, name: '15'},
];

const seconds = [
    {id: 0, name: '00'},
    {id: 1, name: '01'},
    {id: 2, name: '02'},
    {id: 3, name: '03'},
    {id: 4, name: '04'},
    {id: 5, name: '05'},
    {id: 10, name: '10'},
    {id: 15, name: '15'},
];

const sampleAndExplanation = [
    {id: 1, name: 'Yes'},
    {id: 2, name: 'No'},
];
const verified = [
    {id: 1, name: 'By Admin'},
    {id: 2, name: 'By Supper admin'},
    {id: 3, name: 'By Teacher'},
];

const dates = [
    {id: 1, name: '01 Oct 2020'},
    {id: 2, name: '02 Oct 2020'},
    {id: 3, name: '03 Oct 2020'},
    {id: 4, name: '04 Oct 2020'}
];

const topics = [
    {id: 1, name: 'Reading'},
    {id: 2, name: 'Writing'},
    {id: 3, name: 'Listening'},
    {id: 4, name: 'Speaking'}
];

const teachers = [
    {id: 1, name: 'Kazi Shahin'},
    {id: 2, name: 'Mr. Fahim Ahmed'},
    {id: 3, name: 'Kabir Khan'},
    {id: 4, name: 'Sabbir Khan'}
];

const sections = [
    {id: 1, name: 'Reading'},
    {id: 2, name: 'Writing'},
    {id: 3, name: 'Speaking'},
    {id: 4, name: 'Listening'}
];

const types = [
    {id: '11', name: 'All'},
    {id: '21', name: 'Reading', slug: 'READING'},
    {id: '31', name: 'Writing', slug:'WRITING'},
    {id: '41', name: 'Speaking', slug: 'SPEAKING'},
    {id: '51', name: 'Listening', slug: 'LISTENING'}
];
const buttonFilters = [
    {id: 1, label: 'PTE Practice'},
    {id: 2, label: 'Practice Test'},
    {id: 3, label: 'Mock Test'},
    {id: 4, label: 'Quiz'},
  ]
  const Q_Types = [
    {id: 1,  name: 'All'},
    {id: 2,  group: 'listening', name: 'Write From Dictation',slug: 'write-from-dictation'},
    {id: 3,  group: 'writing', name: 'Summarization',slug: 'summarize-written-text'},
    {id: 4,  group: 'listening', name: 'Summarize Spoken Test', slug: 'summarize-spoken-test'},
    {id: 5,  group: 'listening', name: 'Select Missing Word',slug: 'select-missing-word'},
    {id: 6,  group: 'speaking', name: 'Retell Lecture',slug: 'retell-lecture'},
    {id: 7,  group: 'speaking', name: 'Repeat Sentence',slug: 'repeat-sentence'},
    {id: 8,  group: 'reading', name: 'Re-Order Paragraph', slug: 're-order-paragraph'},
    {id: 9,  group: 'speaking', name: 'Read Aloud',slug: 'read-aloud'},
    {id: 10, group: 'listening', name: 'MCQ - Single Answers',slug: 'listening-mcq-single-answers'},
    {id: 11, group: 'listening',  name: 'MCQ - Multiple Answers',slug: 'listening-mcq-multiple-answers'},
    {id: 12, group: 'reading',  name: 'MCQ - Choose Single Answer',slug: 'mcq-choose-single-answer'},
    {id: 13, group: 'reading',  name: 'MCQ - Choose Multiple Answer',slug: 'mcq-choose-multiple-answer'},
    {id: 14, group: 'listening',  name: 'Fill in the Gap', slug: 'fill-in-the-gap'},
    {id: 15, group: 'listening',  name: 'Highlight Incorrect Word',slug: 'highlight-incorrect-word'},
    {id: 16, group: 'listening',  name: 'Highlight Correct Summery',slug: 'highlight-correct-summery'},
    {id: 17, group: 'reading',  name: 'Fill in the Gap - Drag & Drop', slug: 'fill-in-the-gap-drag-drop'},
    {id: 18, group: 'reading',  name: 'Fill in the Gap - Drop Down',slug: 'fill-in-the-gap-drop-down'},
    {id: 19, group: 'writing',  name: 'Writing Essay',slug: 'essay'},
    {id: 20, group: 'speaking',  name: 'Describe Image',slug: 'describe-image'},
    {id: 21, group: 'speaking',  name: 'Answer Short Question',slug: 'answer-short-question'},
  ];
  const questionTypes = [
    {id: 1,  group: 'listening', name: 'Write From Dictation', component: 'WriteFromDictation', slug: 'write-from-dictation'},
    {id: 2,  group: 'writing', name: 'Summarization', component: 'Summarization', slug: 'summarize-written-text'},
    {id: 3,  group: 'listening', name: 'Summarize Spoken Test', component: 'SummarizeSpokenTest', slug: 'summarize-spoken-test'},
    {id: 4,  group: 'listening', name: 'Select Missing Word', component: 'SelectMissingWord', slug: 'select-missing-word'},
    {id: 5,  group: 'speaking', name: 'Retell Lecture', component: 'RetellLecture', slug: 'retell-lecture'},
    {id: 6,  group: 'speaking', name: 'Repeat Sentence', component: 'RepeatSentence', slug: 'repeat-sentence'},
    {id: 7,  group: 'reading', name: 'Re-Order Paragraph', component: 'ReOrderParagraph', slug: 're-order-paragraph'},
    {id: 8,  group: 'speaking', name: 'Read Aloud', component: 'ReadAloud', slug: 'read-aloud'},
    {id: 9,  group: 'listening', name: 'MCQ - Single Answers', component: 'MCQSingleAnswers', slug: 'listening-mcq-single-answers'},
    {id: 10, group: 'listening',  name: 'MCQ - Multiple Answers', component: 'MCQMultipleAnswers', slug: 'listening-mcq-multiple-answers'},
    {id: 11, group: 'reading',  name: 'MCQ - Choose Single Answer', component: 'MCQChooseSingleAnswer', slug: 'mcq-choose-single-answer'},
    {id: 12, group: 'reading',  name: 'MCQ - Choose Multiple Answer', component: 'MCQChooseMultipleAnswer', slug: 'mcq-choose-multiple-answer'},
    {id: 13, group: 'listening',  name: 'Fill in the Gap', component: 'FillInTheGap', slug: 'fill-in-the-gap'},
    {id: 14, group: 'listening',  name: 'Highlight Incorrect Word', component: 'HighlightIncorrectWord', slug: 'highlight-incorrect-word'},
    {id: 15, group: 'listening',  name: 'Highlight Correct Summery', component: 'HighlightCorrectSummery', slug: 'highlight-correct-summery'},
    {id: 16, group: 'reading',  name: 'Fill in the Gap - Drag & Drop', component: 'FillInTheGapDragAndDrop', slug: 'fill-in-the-gap-drag-drop'},
    {id: 17, group: 'reading',  name: 'Fill in the Gap - Drop Down', component: 'FillInTheGapDropDown', slug: 'fill-in-the-gap-drop-down'},
    {id: 18, group: 'writing',  name: 'Writing Essay', component: 'WritingEssay', slug: 'essay'},
    {id: 19, group: 'speaking',  name: 'Describe Image', component: 'DescribeImage', slug: 'describe-image'},
    {id: 20, group: 'speaking',  name: 'Answer Short Question', component: 'AnswerShortQuestion', slug: 'answer-short-question'},
  ];

const sortBy = [
  {id: 1, name: 'Sort by Date'},
  {id: 2, name: 'Sort by Title'}
];

const sortByPoint = [
  {id: 1, name: 'Sort by Point ASC'},
  {id: 2, name: 'Sort by Point DESC'}
];

const jobType = [
  {id: 'all', name: 'All'},
  {id: 'FULL_TIME', name: 'Full Time'},
  {id: 'CASUAL', name: 'CASUAL'}
];

const jobTypeCheckBox = [

    {id: 'FULL_TIME', label: 'Full Time'},
    {id: 'CASUAL', label: 'Casual'}
  ];

const salaryType = [

    {id: 'MONTHLY', label: 'Monthly'},
    {id: 'HOURLY', label: 'Hourly'}
];

const workplace = [

  {id: 'OFFLINE', label: 'Offline'},
  {id: 'ONLINE', label: 'Online'}
];
const courseType = [

  {id: 'BOTH', label: 'Both'},
  {id: 'BRANCH', label: 'Branch'},
  {id: 'ONLINE', label: 'Online'}
];

const ApplicantType = [
  {id: 'all', name: 'All'},
  {id: 'TRAINER', name: 'TRAINER'},
  {id: 'COACHING_ADMIN', name: 'COACHING ADMIN'}
];

const questionType = [
  {id: '', name: 'Select Question Type'},
  {id: 'CHECK_LIST', name: 'CHECK LIST'},
  {id: 'SHORT_ANSWER', name: 'SHORT ANSWER'},
  {id: 'LONG_ANSWER', name: 'LONG ANSWER'},
  {id: 'RADIO_BUTTON', name: 'RADIO BUTTON'},
];
  // Finding component by title any word mismatch ?? .
export default {
    sampleAndExplanation,
    questionTypes,
    buttonFilters,
    branches,
    source,
    verified,
    frequency,
    topics,
    teachers,
    dates,
    seconds,
    minutes,
    statuses,
    applicantStatus,
    sections,
    types,
    Q_Types,
    sortBy,
    sortByPoint,
    jobType,
    jobTypeCheckBox,
    ApplicantType,
    salaryType,
    workplace,
    questionType,
    courseType,
    file_type
}