<template>
  <div
    class="rounded-tr inline-block rounded-tl pointer w-24 h-24 text-center text-white font-semibold rounded-b-full"
    :style="{backgroundColor: color}"
  >
    <p class="mt-3 text-uppercase mb-3">{{ label }}</p>
    <span class="text-3xl font-bold"> {{ value}} </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    value: {
      type: Number,
    },
  },
};
</script>

<style></style>
