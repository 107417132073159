export default [
    {
        name: 'nav.myPortal',
        path: 'student.portal',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 612">
   <g>
       <g>
           <path d="M566.104,323.594h-21.531c-5.826,0-12.619-5.272-14.834-11.515c-2.053-5.786-4.465-11.545-7.16-17.121
               c-2.871-5.93-1.814-14.418,2.305-18.537l15.326-15.328c10.738-10.737,10.738-28.21,0-38.948l-24.877-24.877
               c-5.203-5.202-12.117-8.066-19.475-8.066c-7.355,0-14.271,2.864-19.473,8.066l-15.328,15.328
               c-2.461,2.46-6.559,3.929-10.957,3.929c-2.754,0-5.375-0.563-7.574-1.628c-5.578-2.699-11.346-5.113-17.145-7.176
               c-6.24-2.215-11.512-9.008-11.512-14.832v-21.533c0-15.184-12.354-27.54-27.541-27.54h-35.189
               c-15.188,0-27.541,12.356-27.541,27.54v21.533c0,5.826-5.271,12.62-11.512,14.832c-5.791,2.057-11.557,4.465-17.129,7.158
               c-2.163,1.046-4.847,1.622-7.558,1.622c-4.416,0-8.525-1.472-10.992-3.938l-15.303-15.297c-5.202-5.202-12.118-8.066-19.477-8.066
               c-7.356,0-14.272,2.864-19.474,8.063l-24.902,24.887c-5.205,5.199-8.069,12.114-8.072,19.471c0,7.356,2.864,14.272,8.066,19.474
               l15.328,15.328c4.122,4.122,5.181,12.616,2.311,18.55c-2.69,5.566-5.098,11.322-7.151,17.111
               c-2.215,6.239-9.005,11.512-14.832,11.512h-21.53c-15.187,0-27.54,12.356-27.54,27.54v35.19c0,15.184,12.353,27.54,27.54,27.54
               h21.53c5.827,0,12.62,5.269,14.835,11.509c2.05,5.773,4.458,11.532,7.157,17.111c2.868,5.93,1.806,14.425-2.319,18.55
               l-15.328,15.327c-10.737,10.737-10.737,28.21,0,38.947l24.912,24.915c5.202,5.202,12.118,8.066,19.474,8.066
               c7.356,0,14.272-2.864,19.474-8.066l15.328-15.327c2.466-2.467,6.573-3.938,10.985-3.938c2.751,0,5.364,0.56,7.558,1.622
               c5.576,2.695,11.333,5.104,17.115,7.157c6.238,2.215,11.512,9.008,11.512,14.835v21.533c0,15.184,12.354,27.539,27.539,27.539
               h35.191c15.186,0,27.539-12.355,27.539-27.539v-21.533c0-5.827,5.273-12.62,11.512-14.832c5.793-2.057,11.559-4.465,17.131-7.157
               c2.162-1.047,4.846-1.622,7.557-1.622c4.416,0,8.523,1.472,10.99,3.938l15.326,15.327c5.203,5.202,12.115,8.066,19.471,8.066
               c7.359,0,14.275-2.867,19.477-8.072l24.889-24.902c10.73-10.74,10.73-28.213-0.004-38.951l-15.314-15.321
               c-4.119-4.118-5.176-12.613-2.309-18.547c2.693-5.569,5.098-11.331,7.154-17.12c2.213-6.239,9.004-11.509,14.83-11.509h21.529
               c15.188,0,27.541-12.356,27.541-27.54v-35.19C593.643,335.948,581.287,323.594,566.104,323.594z M449.441,368.729
               c0,44.502-36.207,80.708-80.709,80.708c-44.5,0-80.707-36.206-80.707-80.708c0-44.501,36.207-80.707,80.707-80.707
               C413.234,288.022,449.441,324.225,449.441,368.729z"/>
           <path d="M201.868,146.904c0.521-1.466,2.262-2.702,3.082-2.702h10.784c10.123,0,18.36-8.234,18.36-18.36v-17.595
               c0-10.125-8.237-18.36-18.36-18.36h-10.781c-0.82,0-2.561-1.236-3.081-2.702c-1.083-3.051-2.353-6.089-3.776-9.024
               c-0.664-1.374-0.312-3.446,0.263-4.021l7.666-7.665c7.157-7.161,7.157-18.807,0-25.964l-12.439-12.439
               c-3.467-3.467-8.079-5.376-12.983-5.376c-4.905,0-9.517,1.913-12.984,5.379l-7.662,7.663c-0.239,0.239-1.059,0.621-2.237,0.621
               c-0.67,0-1.319-0.128-1.784-0.355c-2.94-1.423-5.976-2.693-9.024-3.773c-1.469-0.52-2.705-2.265-2.705-3.082V18.366
               c0-10.126-8.237-18.36-18.36-18.36h-17.595c-10.123,0-18.36,8.234-18.36,18.36v10.783c0,0.82-1.236,2.558-2.702,3.079
               c-3.063,1.086-6.102,2.359-9.03,3.776c-0.462,0.223-1.114,0.352-1.784,0.352c-1.178,0-1.995-0.379-2.233-0.618l-7.666-7.663
               c-3.467-3.467-8.078-5.376-12.983-5.376s-9.514,1.909-12.98,5.376L28.073,40.514c-7.157,7.161-7.157,18.81,0,25.967l7.662,7.662
               c0.575,0.575,0.93,2.647,0.266,4.018c-1.423,2.938-2.693,5.976-3.776,9.027c-0.52,1.466-2.265,2.705-3.082,2.705H18.36
               C8.238,89.893,0,98.127,0,108.253v17.595c0,10.123,8.235,18.36,18.357,18.36h10.784c0.82,0,2.561,1.236,3.082,2.702
               c1.083,3.054,2.353,6.089,3.776,9.024c0.664,1.374,0.312,3.445-0.263,4.021l-7.666,7.666c-3.467,3.467-5.376,8.079-5.376,12.983
               c0,4.905,1.909,9.514,5.379,12.984l12.439,12.436c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l7.666-7.665
               c0.238-0.239,1.059-0.621,2.237-0.621c0.67,0,1.319,0.128,1.784,0.354c2.941,1.423,5.977,2.693,9.024,3.773
               c1.469,0.52,2.705,2.264,2.705,3.081v10.784c0,10.125,8.238,18.36,18.36,18.36h17.595c10.123,0,18.36-8.234,18.36-18.36v-10.781
               c0-0.82,1.236-2.558,2.702-3.078c3.051-1.083,6.086-2.353,9.024-3.776c0.465-0.227,1.117-0.355,1.787-0.355
               c1.178,0,1.995,0.382,2.237,0.621l7.666,7.665c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l12.439-12.438
               c7.157-7.161,7.157-18.807,0-25.964l-7.662-7.662c-0.576-0.575-0.931-2.65-0.267-4.021
               C199.519,152.987,200.788,149.952,201.868,146.904z M152.811,117.044c0,19.719-16.043,35.765-35.762,35.765
               c-19.722,0-35.765-16.043-35.765-35.765s16.043-35.765,35.765-35.765C136.767,81.279,152.811,97.323,152.811,117.044z"/>
       </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>
   `
    },
    {
        name: 'nav.portfolio',
        path: 'student.portfolio',
        icon: `<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" class="icon-svg" xml:space="preserve">
   <g>
       <g>
           <polygon points="346,8.789 346,120 457.211,120 		"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M316,150V0H46v512h420V150H316z M136,120h120v30H136V120z M316,422H136v-30h180V422z M376,422h-30v-30h30V422z M376,332
               H136v-30h240V332z M376,242H136v-30h240V242z"/>
       </g>
   </g>
   </svg>`
    },
    {
        name: 'nav.mySchedule',
        path: 'student.schedule',
        icon: `<svg version="1.1" id="Capa_3" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512"
        xml:space="preserve">
   <g>
       <g>
           <path d="M177.854,269.311c0-6.115-4.96-11.069-11.08-11.069h-38.665c-6.113,0-11.074,4.954-11.074,11.069v38.66
               c0,6.123,4.961,11.079,11.074,11.079h38.665c6.12,0,11.08-4.956,11.08-11.079V269.311L177.854,269.311z"/>
           <path d="M274.483,269.311c0-6.115-4.961-11.069-11.069-11.069h-38.67c-6.113,0-11.074,4.954-11.074,11.069v38.66
               c0,6.123,4.961,11.079,11.074,11.079h38.67c6.108,0,11.069-4.956,11.069-11.079V269.311z"/>
           <path d="M371.117,269.311c0-6.115-4.961-11.069-11.074-11.069h-38.665c-6.12,0-11.08,4.954-11.08,11.069v38.66
               c0,6.123,4.96,11.079,11.08,11.079h38.665c6.113,0,11.074-4.956,11.074-11.079V269.311z"/>
           <path d="M177.854,365.95c0-6.125-4.96-11.075-11.08-11.075h-38.665c-6.113,0-11.074,4.95-11.074,11.075v38.653
               c0,6.119,4.961,11.074,11.074,11.074h38.665c6.12,0,11.08-4.956,11.08-11.074V365.95L177.854,365.95z"/>
           <path d="M274.483,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.113,0-11.074,4.95-11.074,11.075v38.653
               c0,6.119,4.961,11.074,11.074,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95z"/>
           <path d="M371.117,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.12,0-11.08,4.95-11.08,11.075v38.653
               c0,6.119,4.96,11.074,11.08,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95L371.117,365.95z"/>
           <path d="M440.254,54.354v59.05c0,26.69-21.652,48.198-48.338,48.198h-30.493c-26.688,0-48.627-21.508-48.627-48.198V54.142
               h-137.44v59.262c0,26.69-21.938,48.198-48.622,48.198H96.235c-26.685,0-48.336-21.508-48.336-48.198v-59.05
               C24.576,55.057,5.411,74.356,5.411,98.077v346.061c0,24.167,19.588,44.015,43.755,44.015h389.82
               c24.131,0,43.755-19.889,43.755-44.015V98.077C482.741,74.356,463.577,55.057,440.254,54.354z M426.091,422.588
               c0,10.444-8.468,18.917-18.916,18.917H80.144c-10.448,0-18.916-8.473-18.916-18.917V243.835c0-10.448,8.467-18.921,18.916-18.921
               h327.03c10.448,0,18.916,8.473,18.916,18.921L426.091,422.588L426.091,422.588z"/>
           <path d="M96.128,129.945h30.162c9.155,0,16.578-7.412,16.578-16.567V16.573C142.868,7.417,135.445,0,126.29,0H96.128
               C86.972,0,79.55,7.417,79.55,16.573v96.805C79.55,122.533,86.972,129.945,96.128,129.945z"/>
           <path d="M361.035,129.945h30.162c9.149,0,16.572-7.412,16.572-16.567V16.573C407.77,7.417,400.347,0,391.197,0h-30.162
               c-9.154,0-16.577,7.417-16.577,16.573v96.805C344.458,122.533,351.881,129.945,361.035,129.945z"/>
       </g>
   </g>
   </svg>`
    },
    {
        name: 'nav.studyPlan',
        path: 'student.studyPlan',
        icon: `<svg class="icon-svg"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
        <g>
            <path d="M482,121h-31V61h-60V30c0-16.569-13.431-30-30-30s-30,13.431-30,30v31h-60v180h180v-60h31c16.569,0,30-13.433,30-30   C512,134.431,498.569,121,482,121z"/>
            <path d="M0,151c0,16.567,13.431,30,30,30h31v60h60v-30c0-16.569,13.431-30,30-30s30,13.431,30,30v30h60V61h-60V30   c0-16.569-13.431-30-30-30s-30,13.431-30,30v31H61v60H30C13.431,121,0,134.431,0,151z"/>
            <path d="M482,331h-31v-60H271v60h30c16.569,0,30,13.431,30,30c0,16.567-13.431,30-30,30h-30v60h60v31c0,16.567,13.431,30,30,30   s30-13.433,30-30v-31h60v-60h31c16.569,0,30-13.433,30-30C512,344.431,498.569,331,482,331z"/>
        </g>
        </svg>`
    },
    {
        name: 'nav.practiceTest',
        path: 'student.practiceTest',
        icon: `<svg version="1.1" id="Capa_4" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 340 340" style="enable-background:new 0 0 340 340;" xml:space="preserve">
   <path d="M170,0C76.109,0,0,76.112,0,170s76.109,170,170,170c93.89,0,170-76.112,170-170S263.89,0,170,0z M169.633,281.611
       c-12.574,0-22.803-10.23-22.803-22.804c0-12.573,10.229-22.803,22.803-22.803c12.575,0,22.806,10.229,22.806,22.803
       C192.439,271.381,182.208,281.611,169.633,281.611z M205.357,169.659c-9.856,9.221-16.367,15.312-16.367,30.062v10.911
       c0,2.761-2.238,5-5,5h-27.122c-2.762,0-5-2.239-5-5v-10.911c0-30.293,15.744-45.076,27.239-55.87
       c9.785-9.179,16.248-15.242,16.248-29.778c0-4.591,0-18.562-24.925-18.562c-23.37,0-24.306,12.883-24.705,18.384l-0.813,11.186
       c-0.099,1.346-0.736,2.596-1.771,3.464c-1.031,0.87-2.372,1.282-3.715,1.148l-27.051-2.707c-2.7-0.27-4.691-2.645-4.488-5.351
       l0.799-10.613c2.41-31.974,26.749-52.634,62.006-52.634c18.438,0,33.952,5.176,44.865,14.968
       c11.066,9.931,16.918,24.102,16.918,40.98C232.477,144.265,216.802,158.942,205.357,169.659z"/>
   </svg>`
    },
    {
        name: 'nav.mockExam',
        path: 'student.mockExam',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 438.891 438.891" style="enable-background:new 0 0 438.891 438.891;" xml:space="preserve">
   <g>
       <g>
           <g>
               <path d="M347.968,57.503h-39.706V39.74c0-5.747-6.269-8.359-12.016-8.359h-30.824c-7.314-20.898-25.6-31.347-46.498-31.347
                   c-20.668-0.777-39.467,11.896-46.498,31.347h-30.302c-5.747,0-11.494,2.612-11.494,8.359v17.763H90.923
                   c-23.53,0.251-42.78,18.813-43.886,42.318v299.363c0,22.988,20.898,39.706,43.886,39.706h257.045
                   c22.988,0,43.886-16.718,43.886-39.706V99.822C390.748,76.316,371.498,57.754,347.968,57.503z M151.527,52.279h28.735
                   c5.016-0.612,9.045-4.428,9.927-9.404c3.094-13.474,14.915-23.146,28.735-23.51c13.692,0.415,25.335,10.117,28.212,23.51
                   c0.937,5.148,5.232,9.013,10.449,9.404h29.78v41.796H151.527V52.279z M370.956,399.185c0,11.494-11.494,18.808-22.988,18.808
                   H90.923c-11.494,0-22.988-7.314-22.988-18.808V99.822c1.066-11.964,10.978-21.201,22.988-21.42h39.706v26.645
                   c0.552,5.854,5.622,10.233,11.494,9.927h154.122c5.98,0.327,11.209-3.992,12.016-9.927V78.401h39.706
                   c12.009,0.22,21.922,9.456,22.988,21.42V399.185z"/>
               <path d="M179.217,233.569c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                   c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                   c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                   C179.628,233.962,179.427,233.761,179.217,233.569z"/>
               <path d="M329.16,256.034H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                   c5.771,0,10.449-4.678,10.449-10.449S334.931,256.034,329.16,256.034z"/>
               <path d="M179.217,149.977c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                   c-3.919-4.131-10.425-4.364-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                   c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                   C179.628,150.37,179.427,150.169,179.217,149.977z"/>
               <path d="M329.16,172.442H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                   c5.771,0,10.449-4.678,10.449-10.449S334.931,172.442,329.16,172.442z"/>
               <path d="M179.217,317.16c-3.919-4.131-10.425-4.363-14.629-0.522l-33.437,31.869l-14.106-14.629
                   c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                   c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                   C179.628,317.554,179.427,317.353,179.217,317.16z"/>
               <path d="M329.16,339.626H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                   c5.771,0,10.449-4.678,10.449-10.449S334.931,339.626,329.16,339.626z"/>
           </g>
       </g>
   </g>
   </svg>`
    },
    {
        name: 'nav.ptePractice',
        path: 'student.ptePractice',
        icon: `<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 511.999 511.999" class="icon-svg" xml:space="preserve">
   <g>
       <g>
           <path d="M466.865,211.499H98.994c-14.941,0-28.608,7.251-35.713,18.223c-3.75,4.688-6.592,10.664-8.013,16.904L8.452,456.641
               c-2.974,13.418,0.234,27.261,8.818,37.969c8.613,10.737,21.445,16.89,35.229,16.89h367.563c20.991,0,39.507-14.78,44.033-35.142
               l46.802-210c0.015-0.015,0.015-0.015,0.015-0.015C517.163,238.108,495.665,211.499,466.865,211.499z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M355.587,94.875L265.624,4.912c-2.795-2.803-8.302-4.413-10.624-4.413H105c-8.291,0-15,6.709-15,15v166
               c101.384,0,167.41,0,270,0v-76C360,102.823,358.3,97.58,355.587,94.875z M285,150.499H165c-8.291,0-15-6.709-15-15s6.709-15,15-15
               h90h30c8.291,0,15,6.709,15,15S293.291,150.499,285,150.499z M270,90.499V51.71l38.789,38.789H270z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M45,91.499c-24.814,0-45,20.186-45,45v220.19l25.986-116.596c2.344-10.313,6.812-19.907,12.95-27.949
               c5.4-7.917,12.812-14.198,21.064-19.261V91.499H45z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M405,121.499h-15v60h60v-15C450,141.685,429.814,121.499,405,121.499z"/>
       </g>
   </g>
   </svg>`
    },
    {
        name: 'nav.quizTest',
        path: 'student.quizTest',
        icon: `
        <svg class="icon-svg" enable-background="new 0 0 511.406 511.406"  viewBox="0 0 511.406 511.406" xmlns="http://www.w3.org/2000/svg"><g><path d="m511.203 411.406v-45h-48v-30h-16v-25h16v-45h-119.643v15h104.643v15h-310.358v15h294.357v25h-326.5c-6.893 0-12.5-5.607-12.5-12.5s5.607-12.5 12.5-12.5h17.143v-15h-17.143c-15.163 0-27.5 12.336-27.5 27.5s12.337 27.5 27.5 27.5h342.5v15h-258.5v15h306.5v15h-423.5c-23.435 0-42.5 19.065-42.5 42.5s19.065 42.5 42.5 42.5h353.285v-15h-353.284c-15.164 0-27.5-12.336-27.5-27.5s12.336-27.5 27.5-27.5h407.5v25h-332.5v15h332.5v15h-39.215v15h55.215v15h-423.5c-31.706 0-57.5-25.794-57.5-57.5s25.794-57.5 57.5-57.5h102v-15h-69c-23.435 0-42.5-19.065-42.5-42.5s19.065-42.5 42.5-42.5h222.857v-15h-222.857c-31.706 0-57.5 25.794-57.5 57.5 0 16.942 7.369 32.192 19.066 42.725-37.446 2.791-67.066 34.128-67.066 72.275 0 39.977 32.523 72.5 72.5 72.5h438.5v-45h-16v-55z"/><path d="m117.703 436.406h15v15h-15z"/><path d="m138.203 222.919 3.34 2.227c1.302.868 32.824 21.26 114.16 21.26s112.858-20.392 114.16-21.26l3.34-2.227v-126.274l45-15v70.809c-11.528 3.274-20 13.889-20 26.452v37.5h55v-37.5c0-12.563-8.472-23.178-20-26.452v-75.809l26.217-8.739-78.766-26.256-4.744 14.23 36.076 12.025-156.283 52.095-156.283-52.094 156.283-52.094 105.977 35.326 4.744-14.23-110.721-36.908-203.717 67.906 86.217 28.739zm300-44.013v22.5h-25v-22.5c0-6.893 5.607-12.5 12.5-12.5s12.5 5.607 12.5 12.5zm-285-77.261 102.5 34.167 102.5-34.167v58.761h-18.5v15h18.5v15h-32.5v15h32.5v9.042c-9.666 4.707-40.765 16.958-102.5 16.958-61.782 0-92.882-12.27-102.5-16.954z"/><path d="m87.703 436.406h15v15h-15z"/><path d="m295.703 190.406h15v15h-15z"/></g></svg>`
    },
    {
        name: 'nav.practiceMaterial',
        path: 'student.practiceMaterial',
        icon: `
        <svg class="icon-svg" enable-background="new 0 0 500.175 500.175" viewBox="0 0 500.175 500.175" xmlns="http://www.w3.org/2000/svg"><g id="XMLID_1607_"><circle id="XMLID_1678_" cx="77.554" cy="214.175" r="45"/><path id="XMLID_1692_" d="m112.651 147.92c9.235 4.912 17.319 11.7 23.759 19.851l96.477-69.613-.021-.028 74.345-48.085-33.108-50.045-74.301 48.058 16.063 25.378-.533.394z"/><path id="XMLID_1693_" d="m239.659 129.958-.494 35.553 32.421 50.486 102.923-66.57 25.514-16.501-34.907-48.878-33.087-14.329-83.719 54.149z"/><path id="XMLID_1694_" d="m297.071 261.408h42.427v30h-42.427z" transform="matrix(.204 -.979 .979 .204 -17.334 531.49)"/><path id="XMLID_1695_" d="m428.789 173.045h30v42.427h-30z" transform="matrix(.204 -.979 .979 .204 162.937 588.984)"/><path id="XMLID_1696_" d="m372.335 222.455h30v45.001h-30z" transform="matrix(.837 -.548 .548 .837 -70.901 252.132)"/><path id="XMLID_1697_" d="m272.328 425.175h-149.545c-16.472 0-30.102 13.184-30.227 29.654-.001.115-.002.23-.002.346v15h-45.5v30h45.5 14.5 195.5v-45c0-.115 0-.23-.001-.346-.124-16.471-13.755-29.654-30.225-29.654z"/><path id="XMLID_1698_" d="m98.329 286.225 71.405 108.95 9.83 15h35.87l-9.831-15-80.595-122.971c-7.754 6.353-16.803 11.168-26.679 14.021z"/></g></svg>`
    },
    {
        name: 'nav.result',
        path: 'student.result',
        icon: `<svg class="icon-svg" viewBox="-50 0 366 366" xmlns="http://www.w3.org/2000/svg"><path d="m238.847656 27.160156h-34.6875v11.773438c-.023437 22.59375-18.335937 40.90625-40.929687 40.929687h-60.683594c-22.59375-.023437-40.90625-18.335937-40.929687-40.929687v-11.773438h-34.6875c-14.867188.019532-26.9101568 12.0625-26.929688 26.929688v284.980468c.015625 14.867188 12.0625 26.914063 26.929688 26.929688h211.917968c14.867188-.015625 26.914063-12.0625 26.929688-26.929688v-284.980468c-.015625-14.867188-12.0625-26.910156-26.929688-26.929688zm-44.589844 152.773438-72.640624 70.917968c-2.71875 2.652344-7.058594 2.652344-9.777344 0l-40.320313-39.367187c-2.765625-2.699219-2.820312-7.132813-.117187-9.898437 2.699218-2.765626 7.132812-2.820313 9.898437-.117188l35.429688 34.589844 67.746093-66.144532c2.765626-2.699218 7.199219-2.648437 9.898438.117188 2.703125 2.769531 2.648438 7.199219-.117188 9.902344zm0 0"/><path d="m102.546875 65.863281h60.683594c14.867187-.015625 26.914062-12.0625 26.929687-26.929687v-11.773438h-22.832031c-3.285156 0-6.128906-2.289062-6.835937-5.5-2.757813-12.550781-14.363282-21.660156-27.601563-21.660156s-24.847656 9.109375-27.601563 21.664062c-.703124 3.210938-3.550781 5.5-6.835937 5.5h-22.835937v11.773438c.019531 14.863281 12.066406 26.910156 26.929687 26.925781zm0 0"/></svg>`
    },
    
    {
        name: 'nav.liveClass',
        path: 'student.liveClass',
        icon: `
        <svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <g>
                <path d="M502,20.031H10c-5.523,0-10,4.478-10,10v451.938c0,5.522,4.477,10,10,10h97c5.523,0,10-4.478,10-10
                    c0-5.522-4.477-10-10-10H20V111.98c0.33,0.033,0.665,0.051,1.004,0.051H492v359.938h-17.788v-43.205
                    c0-19.523-12.451-36.767-30.983-42.906l-35.288-11.694c4.307-5.068,7.822-10.824,10.358-17.082h29.715c5.523,0,10-4.478,10-10
                    v-28.205c0-53.986-43.921-97.908-97.908-97.908s-97.908,43.922-97.908,97.908v28.205c0,5.522,4.477,10,10,10h29.715
                    c2.537,6.258,6.051,12.014,10.358,17.082l-35.288,11.694C258.451,391.997,246,409.24,246,428.763v43.205h-46.513
                    c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10H256h208.212H502c5.523,0,10-4.478,10-10V30.031
                    C512,24.508,507.523,20.031,502,20.031z M438.014,337.081h-15.235c0.064-1.161,0.103-2.329,0.103-3.506v-13.768
                    c0-0.093-0.007-0.184-0.007-0.276h15.139V337.081z M282.198,337.081v-17.55h15.139c0,0.093-0.007,0.184-0.007,0.276v13.768
                    c0,1.177,0.039,2.345,0.103,3.506H282.198z M300.709,299.53h-16.078c8.625-33.631,39.192-58.563,75.475-58.563
                    c36.283,0,66.85,24.932,75.475,58.563h-16.078c-8.453-24.692-31.877-42.5-59.397-42.5
                    C332.586,257.03,309.162,274.838,300.709,299.53z M360.106,332.364c-5.523,0-10,4.478-10,10v10.334c0,5.522,4.477,10,10,10h31.292
                    c-2.269,2.436-4.814,4.61-7.591,6.465c-0.657,0.32-1.278,0.711-1.852,1.168c-6.4,3.818-13.871,6.02-21.849,6.02
                    c-7.978,0-15.448-2.201-21.847-6.019c-0.575-0.457-1.197-0.849-1.856-1.17c-11.489-7.678-19.073-20.762-19.073-35.587v-13.768
                    c0-23.587,19.189-42.776,42.776-42.776c23.587,0,42.776,19.189,42.776,42.776v13.768c0,3.131-0.348,6.181-0.989,9.123h-31.787
                    v-0.334C370.106,336.842,365.629,332.364,360.106,332.364z M454.211,471.968H266v0v-43.205c0-10.885,6.942-20.498,17.274-23.922
                    l47.555-15.76c8.748,4.633,18.708,7.269,29.277,7.269c10.569,0,20.529-2.636,29.277-7.269l47.555,15.76
                    c10.332,3.424,17.273,13.037,17.273,23.922V471.968z M492,92.031H21.004c-0.339,0-0.674,0.018-1.004,0.051V40.031h472V92.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M161.55,474.899c-1.86-1.869-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.061-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07
                    s1.06,5.21,2.93,7.07c1.86,1.859,4.43,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
                    S163.41,476.759,161.55,474.899z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M448,56.031H222.065c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10H448c5.523,0,10-4.478,10-10
                    C458,60.509,453.523,56.031,448,56.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M111.07,58.96c-1.86-1.861-4.44-2.93-7.07-2.93s-5.21,1.069-7.07,2.93C95.07,60.819,94,63.399,94,66.03
                    c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
                    C114,63.399,112.93,60.819,111.07,58.96z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M71.07,58.96c-1.86-1.861-4.44-2.93-7.07-2.93s-5.21,1.069-7.07,2.93C55.07,60.819,54,63.399,54,66.03
                    c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.07-2.931C72.93,71.24,74,68.66,74,66.03
                    C74,63.399,72.93,60.819,71.07,58.96z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M151.07,58.96c-1.86-1.861-4.44-2.93-7.07-2.93s-5.21,1.069-7.07,2.93c-1.86,1.859-2.93,4.439-2.93,7.07
                    c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
                    C154,63.399,152.93,60.819,151.07,58.96z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M214.349,138.031H94.63c-22.404,0-40.63,18.227-40.63,40.631V261.4c0,22.404,18.227,40.631,40.63,40.631h119.718
                    c22.404,0,40.631-18.227,40.631-40.631v-82.738C254.979,156.258,236.752,138.031,214.349,138.031z M214.349,282.031H94.63
                    c-11.375,0-20.63-9.255-20.63-20.631v-82.738c0-11.376,9.255-20.631,20.63-20.631h119.718c11.375,0,20.63,9.255,20.63,20.631
                    V261.4h0.001C234.979,272.776,225.724,282.031,214.349,282.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M184.375,203.225l-38.225-24.501c-6.146-3.939-13.944-4.211-20.35-0.709c-6.406,3.503-10.385,10.214-10.385,17.515v49.002
                    c0,7.301,3.979,14.012,10.385,17.515c2.992,1.636,6.288,2.448,9.577,2.448c3.753,0,7.498-1.058,10.773-3.157l38.225-24.501
                    c5.754-3.688,9.19-9.971,9.19-16.806C193.565,213.196,190.128,206.913,184.375,203.225z M135.415,244.458l0.003-48.857
                    l38.112,24.431L135.415,244.458z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M137.494,378.031H64c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h73.494c5.523,0,10-4.478,10-10
                    C147.494,382.509,143.017,378.031,137.494,378.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M210.814,378.031H183.11c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h27.704c5.523,0,10-4.478,10-10
                    C220.814,382.509,216.337,378.031,210.814,378.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M169.387,330.031H64c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h105.387c5.523,0,10-4.478,10-10
                    C179.387,334.509,174.91,330.031,169.387,330.031z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M218.25,332.959c-1.86-1.86-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.069-7.07,2.93c-1.86,1.859-2.93,4.439-2.93,7.07
                    c0,2.63,1.07,5.21,2.93,7.069c1.86,1.86,4.43,2.931,7.07,2.931c2.63,0,5.21-1.07,7.07-2.931c1.86-1.859,2.93-4.439,2.93-7.069
                    S220.11,334.819,218.25,332.959z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M210.8,426.031H64c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h146.8c5.523,0,10-4.478,10-10
                    C220.8,430.509,216.323,426.031,210.8,426.031z"/>
            </g>
        </g>
        </svg>
        `
    },
    {
        name: 'nav.communication',
        path: 'student.communication',
        icon: `<svg id="bold" class="icon-svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="18.5" cy="14.5" r="3"/><path d="m21.25 19h-5.5c-1.517 0-2.75 1.233-2.75 2.75v1.5c0 .414.336.75.75.75h9.5c.414 0 .75-.336.75-.75v-1.5c0-1.517-1.233-2.75-2.75-2.75z"/><circle cx="4" cy="17" r="2"/><path d="m5.25 20h-2.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h6.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z"/><path d="m12.25 0h-9.5c-1.517 0-2.75 1.233-2.75 2.75v7.5c0 1.517 1.233 2.75 2.75 2.75h4.957l3.034 2.801c.142.131.324.199.509.199.102 0 .204-.021.301-.063.272-.12.449-.389.449-.687v-2.25h.25c1.517 0 2.75-1.233 2.75-2.75v-7.5c0-1.517-1.233-2.75-2.75-2.75zm-2 8.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>`
    },
    {
        name: 'nav.onlineClass',
        path: 'student.onlineClass',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve">
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><g><path d="M889.3,883.8h-16.7h-4.9v-55.9l0.4,0.5V703.9l-0.4-0.5V482.6H759.6c2.7-26.1,5.1-55.2,6.4-84.9c4.1-93.7,18.1-391.1-251.6-391.1C244.7,6.7,252,255,252,255L266,482.6H147.6v400.2c-0.6,0.6-1,1-1,1h-30.5v14.4H10v95h70H370h305.5h271.3H990v-95l-101.3,0.6L889.3,883.8z M516.1,742.5c-27.4,0-49.6-21.9-49.6-48.9c0-27,22.2-48.9,49.6-48.9c27.4,0,49.6,21.9,49.6,48.9C565.7,720.6,543.5,742.5,516.1,742.5z M576.2,453v29.6H456.1v-31.8c-87.6-27.3-151-109-151-205.5c0-7.2,0.4-14.2,1.1-21.2c58.6-4,261.1-22.4,266.3-87.9c19.2,35.6,121.5,125.1,162,131C725.3,357,661.1,430.2,576.2,453z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
        </svg>`
    }
]