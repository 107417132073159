<template>
  <div class="md-layout my-2 ">
    <div class="md-layout-item md-size-20 md-small-size-100 md-xsmall-size-100">
      <p class="text-xl text-uppercase mr-2">
        <span>{{ label }} </span>
        <span class="font-bold">{{ (value > 100) ? 100 : value }} % </span>
      </p>
    </div>
    <div class="md-layout-item md-size-80 md-small-size-100 md-xsmall-size-100">
      <div class="h-8" :style="{ width: `${(value > 100) ? 100 : value}%`, backgroundColor: color }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    value: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style></style>
