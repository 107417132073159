<template>
  <div class="pb-2 panel-body">
      <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
