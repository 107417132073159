import api from '../api'
// import { merge } from 'lodash'
const state = () => ({
    dropdowns: {},
    statuses: []
});


const getters = {
    getStatuses: state => {
      
       return state.statuses
    },
}

const mutations = {
    setDropdowns(state, items) {
        state.dropdowns = items;
        state.statuses = items.status;
        state.statuses.unshift({
            id: 'all',
            name: 'All'
        });
    }
}

const actions = {
    async actGetDropdowns ( { commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/common/dropdowns${query}`)
                    .then( response => {
                        commit('setDropdowns', response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }