import api from '../api'

const state = () => ({
    emailVerification: [],
});


const getters = {
    getEmailVerification: state =>  state.emailVerification,
}


const mutations = {

    setEmailVerification(state, payload) {
        state.emailVerification = payload
    },
}

const actions = {

    async actEmailVerification ({ commit }, token)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/user/verify/${token}`)
                    .then( response => {
                        commit('setEmailVerification', response)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}