<template>
  <router-link class="side-bar-item" :to="{ name: item.path }">
    <div class="item">
      <SvgIcon :path="icon" />
      <p>{{ $t(name) }}</p>
    </div>
  </router-link>
</template>

<script>
import defaultImage from "@/assets/images/icons/1.png";
import { SvgIcon } from "@/components";
import { mapState } from "vuex";
export default {
  name: "Sidebar-Menu-Item",
  components: {
    SvgIcon
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.sidebar,
    }),
    name() {
      return this.item.name ? this.item.name : "N/A";
    },
    icon() {
      return this.item.icon ? this.item.icon : defaultImage;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/components/menu-item";

</style>
