<template>
    <div class="relative">
        <label v-if="label" class="pr-4 inline-block mb-1 text-uppercase font-bold">{{ label }}</label>
        <Datepicker :value="value" @selected="onSelected" @input="onInput" input-class="w-full py-3 pl-3 rounded outline-gray-400" />
        <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
            <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
        </svg>
        <div id="scrollTop" class="text-xs text-danger" v-if="message">{{ message }}</div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
    components: {
    Datepicker
  },
  props: {
      label: {
          type: String
      },
      message: {
        type: String,
        default: () => null,
    },
    value: {
        
    }
  },
  methods: {
      onSelected(value){
        this.$emit('input', value);
      },
      onInput(value) {
          this.$emit('input', value);
      }
  }
}
</script>

<style>
    .date-picker-icon {
        position: absolute;
        top: 34px;
        right: 8px;
        pointer-events: none;
    }
</style>