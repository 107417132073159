import api from '../api'

const state = () => ({
    material: {},
    materials: [],
    materialMeta:[],
    isLoading: false,
});


const getters = {
    getMaterials: state =>  state.materials,
    getmaterial: state =>  state.material,
    getMaterialMeta: state =>  state.materialMeta,
    getLoading: state => state.isLoading,
}


const mutations = {

    setMaterialList(state, payload) {
        state.materials = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setMaterialMeta(state, materialMeta) {
        state.materialMeta = materialMeta
    },
    setMaterialShow(state, payload) {
        state.material = payload;
    },
    setLoading(state, payload) {
        state.isLoading = payload;
    },
}

const actions = {
    async actAnswerSubmit(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/demos`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actMaterialsCreate(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/materials`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },

    async actMaterialList ({ commit }, query = '')  {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/materials${query}`)
                    .then( response => {
                        console.log(response.items)
                        commit('setMaterialList', response.items)
                        commit('setMaterialMeta', response.meta);
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
            } catch (error) {
                reject(error);
            }
        })
    },
    async actShowMaterial ({ commit }, material_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/materials/${material_id}`)
                    .then( response => {
                        console.log(response.items)
                        commit('setMaterialShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },

    async actDeleteMaterial ({ dispatch }, material_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('delete', `api/v1/materials/${material_id}`)
                    .then( () => {
                        dispatch('actMaterialList', ``)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },

    async actUpdateMaterial (store, {material_id, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/materials/${material_id}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}