export default [
    {
        name: 'nav.myPortal',
        path: 'master.portal',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 612">
   <g>
       <g>
           <path d="M566.104,323.594h-21.531c-5.826,0-12.619-5.272-14.834-11.515c-2.053-5.786-4.465-11.545-7.16-17.121
               c-2.871-5.93-1.814-14.418,2.305-18.537l15.326-15.328c10.738-10.737,10.738-28.21,0-38.948l-24.877-24.877
               c-5.203-5.202-12.117-8.066-19.475-8.066c-7.355,0-14.271,2.864-19.473,8.066l-15.328,15.328
               c-2.461,2.46-6.559,3.929-10.957,3.929c-2.754,0-5.375-0.563-7.574-1.628c-5.578-2.699-11.346-5.113-17.145-7.176
               c-6.24-2.215-11.512-9.008-11.512-14.832v-21.533c0-15.184-12.354-27.54-27.541-27.54h-35.189
               c-15.188,0-27.541,12.356-27.541,27.54v21.533c0,5.826-5.271,12.62-11.512,14.832c-5.791,2.057-11.557,4.465-17.129,7.158
               c-2.163,1.046-4.847,1.622-7.558,1.622c-4.416,0-8.525-1.472-10.992-3.938l-15.303-15.297c-5.202-5.202-12.118-8.066-19.477-8.066
               c-7.356,0-14.272,2.864-19.474,8.063l-24.902,24.887c-5.205,5.199-8.069,12.114-8.072,19.471c0,7.356,2.864,14.272,8.066,19.474
               l15.328,15.328c4.122,4.122,5.181,12.616,2.311,18.55c-2.69,5.566-5.098,11.322-7.151,17.111
               c-2.215,6.239-9.005,11.512-14.832,11.512h-21.53c-15.187,0-27.54,12.356-27.54,27.54v35.19c0,15.184,12.353,27.54,27.54,27.54
               h21.53c5.827,0,12.62,5.269,14.835,11.509c2.05,5.773,4.458,11.532,7.157,17.111c2.868,5.93,1.806,14.425-2.319,18.55
               l-15.328,15.327c-10.737,10.737-10.737,28.21,0,38.947l24.912,24.915c5.202,5.202,12.118,8.066,19.474,8.066
               c7.356,0,14.272-2.864,19.474-8.066l15.328-15.327c2.466-2.467,6.573-3.938,10.985-3.938c2.751,0,5.364,0.56,7.558,1.622
               c5.576,2.695,11.333,5.104,17.115,7.157c6.238,2.215,11.512,9.008,11.512,14.835v21.533c0,15.184,12.354,27.539,27.539,27.539
               h35.191c15.186,0,27.539-12.355,27.539-27.539v-21.533c0-5.827,5.273-12.62,11.512-14.832c5.793-2.057,11.559-4.465,17.131-7.157
               c2.162-1.047,4.846-1.622,7.557-1.622c4.416,0,8.523,1.472,10.99,3.938l15.326,15.327c5.203,5.202,12.115,8.066,19.471,8.066
               c7.359,0,14.275-2.867,19.477-8.072l24.889-24.902c10.73-10.74,10.73-28.213-0.004-38.951l-15.314-15.321
               c-4.119-4.118-5.176-12.613-2.309-18.547c2.693-5.569,5.098-11.331,7.154-17.12c2.213-6.239,9.004-11.509,14.83-11.509h21.529
               c15.188,0,27.541-12.356,27.541-27.54v-35.19C593.643,335.948,581.287,323.594,566.104,323.594z M449.441,368.729
               c0,44.502-36.207,80.708-80.709,80.708c-44.5,0-80.707-36.206-80.707-80.708c0-44.501,36.207-80.707,80.707-80.707
               C413.234,288.022,449.441,324.225,449.441,368.729z"/>
           <path d="M201.868,146.904c0.521-1.466,2.262-2.702,3.082-2.702h10.784c10.123,0,18.36-8.234,18.36-18.36v-17.595
               c0-10.125-8.237-18.36-18.36-18.36h-10.781c-0.82,0-2.561-1.236-3.081-2.702c-1.083-3.051-2.353-6.089-3.776-9.024
               c-0.664-1.374-0.312-3.446,0.263-4.021l7.666-7.665c7.157-7.161,7.157-18.807,0-25.964l-12.439-12.439
               c-3.467-3.467-8.079-5.376-12.983-5.376c-4.905,0-9.517,1.913-12.984,5.379l-7.662,7.663c-0.239,0.239-1.059,0.621-2.237,0.621
               c-0.67,0-1.319-0.128-1.784-0.355c-2.94-1.423-5.976-2.693-9.024-3.773c-1.469-0.52-2.705-2.265-2.705-3.082V18.366
               c0-10.126-8.237-18.36-18.36-18.36h-17.595c-10.123,0-18.36,8.234-18.36,18.36v10.783c0,0.82-1.236,2.558-2.702,3.079
               c-3.063,1.086-6.102,2.359-9.03,3.776c-0.462,0.223-1.114,0.352-1.784,0.352c-1.178,0-1.995-0.379-2.233-0.618l-7.666-7.663
               c-3.467-3.467-8.078-5.376-12.983-5.376s-9.514,1.909-12.98,5.376L28.073,40.514c-7.157,7.161-7.157,18.81,0,25.967l7.662,7.662
               c0.575,0.575,0.93,2.647,0.266,4.018c-1.423,2.938-2.693,5.976-3.776,9.027c-0.52,1.466-2.265,2.705-3.082,2.705H18.36
               C8.238,89.893,0,98.127,0,108.253v17.595c0,10.123,8.235,18.36,18.357,18.36h10.784c0.82,0,2.561,1.236,3.082,2.702
               c1.083,3.054,2.353,6.089,3.776,9.024c0.664,1.374,0.312,3.445-0.263,4.021l-7.666,7.666c-3.467,3.467-5.376,8.079-5.376,12.983
               c0,4.905,1.909,9.514,5.379,12.984l12.439,12.436c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l7.666-7.665
               c0.238-0.239,1.059-0.621,2.237-0.621c0.67,0,1.319,0.128,1.784,0.354c2.941,1.423,5.977,2.693,9.024,3.773
               c1.469,0.52,2.705,2.264,2.705,3.081v10.784c0,10.125,8.238,18.36,18.36,18.36h17.595c10.123,0,18.36-8.234,18.36-18.36v-10.781
               c0-0.82,1.236-2.558,2.702-3.078c3.051-1.083,6.086-2.353,9.024-3.776c0.465-0.227,1.117-0.355,1.787-0.355
               c1.178,0,1.995,0.382,2.237,0.621l7.666,7.665c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l12.439-12.438
               c7.157-7.161,7.157-18.807,0-25.964l-7.662-7.662c-0.576-0.575-0.931-2.65-0.267-4.021
               C199.519,152.987,200.788,149.952,201.868,146.904z M152.811,117.044c0,19.719-16.043,35.765-35.762,35.765
               c-19.722,0-35.765-16.043-35.765-35.765s16.043-35.765,35.765-35.765C136.767,81.279,152.811,97.323,152.811,117.044z"/>
       </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>
   `
    },
    {
        name: 'nav.coachingCenter',
        path: 'master.coachingCenter',
        icon: `<svg class="icon-svg" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m500.625 60.445312-241-60c-2.382812-.59375-4.867188-.59375-7.25 0l-241 60c-6.683594 1.664063-11.375 7.667969-11.375 14.554688 0 6.890625 4.691406 12.894531 11.375 14.558594l241 60c1.191406.292968 2.40625.441406 3.625.441406s2.433594-.148438 3.625-.441406l222.375-55.367188v70.808594c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-90c0-6.886719-4.691406-12.890625-11.375-14.554688zm0 0"/><path d="m266.871094 178.667969c-3.550782.882812-7.207032 1.332031-10.871094 1.332031s-7.320312-.449219-10.875-1.332031l-169.125-42.105469v143.4375h360v-143.4375zm0 0"/><path d="m512 325c0-8.285156-6.714844-15-15-15h-422c-41.355469 0-75 33.644531-75 75s33.644531 75 75 75h422c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-422c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45h422c8.285156 0 15-6.714844 15-15zm0 0"/><path d="m60 385c0 8.273438 6.730469 15 15 15h407v-30h-407c-8.269531 0-15 6.730469-15 15zm0 0"/></svg> `
    },
    {
        name: 'nav.questionManagement',
        path: 'master.questionManagement',
        icon: `<svg version="1.1" id="Capa_4" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 340 340" style="enable-background:new 0 0 340 340;" xml:space="preserve">
        <path d="M170,0C76.109,0,0,76.112,0,170s76.109,170,170,170c93.89,0,170-76.112,170-170S263.89,0,170,0z M169.633,281.611
            c-12.574,0-22.803-10.23-22.803-22.804c0-12.573,10.229-22.803,22.803-22.803c12.575,0,22.806,10.229,22.806,22.803
            C192.439,271.381,182.208,281.611,169.633,281.611z M205.357,169.659c-9.856,9.221-16.367,15.312-16.367,30.062v10.911
            c0,2.761-2.238,5-5,5h-27.122c-2.762,0-5-2.239-5-5v-10.911c0-30.293,15.744-45.076,27.239-55.87
            c9.785-9.179,16.248-15.242,16.248-29.778c0-4.591,0-18.562-24.925-18.562c-23.37,0-24.306,12.883-24.705,18.384l-0.813,11.186
            c-0.099,1.346-0.736,2.596-1.771,3.464c-1.031,0.87-2.372,1.282-3.715,1.148l-27.051-2.707c-2.7-0.27-4.691-2.645-4.488-5.351
            l0.799-10.613c2.41-31.974,26.749-52.634,62.006-52.634c18.438,0,33.952,5.176,44.865,14.968
            c11.066,9.931,16.918,24.102,16.918,40.98C232.477,144.265,216.802,158.942,205.357,169.659z"></path>
        </svg>`
    },
    {
        name: 'nav.testManagement',
        path: 'master.testManagement',
        icon: `<svg class="icon-svg"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
        <g>
            <path d="M482,121h-31V61h-60V30c0-16.569-13.431-30-30-30s-30,13.431-30,30v31h-60v180h180v-60h31c16.569,0,30-13.433,30-30   C512,134.431,498.569,121,482,121z"/>
            <path d="M0,151c0,16.567,13.431,30,30,30h31v60h60v-30c0-16.569,13.431-30,30-30s30,13.431,30,30v30h60V61h-60V30   c0-16.569-13.431-30-30-30s-30,13.431-30,30v31H61v60H30C13.431,121,0,134.431,0,151z"/>
            <path d="M482,331h-31v-60H271v60h30c16.569,0,30,13.431,30,30c0,16.567-13.431,30-30,30h-30v60h60v31c0,16.567,13.431,30,30,30   s30-13.433,30-30v-31h60v-60h31c16.569,0,30-13.433,30-30C512,344.431,498.569,331,482,331z"/>
        </g>
        </svg>`
    },
    {
        name: 'nav.studentManagement',
        icon: `<svg id="bold" class="icon-svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="18.5" cy="14.5" r="3"/><path d="m21.25 19h-5.5c-1.517 0-2.75 1.233-2.75 2.75v1.5c0 .414.336.75.75.75h9.5c.414 0 .75-.336.75-.75v-1.5c0-1.517-1.233-2.75-2.75-2.75z"/><circle cx="4" cy="17" r="2"/><path d="m5.25 20h-2.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h6.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z"/><path d="m12.25 0h-9.5c-1.517 0-2.75 1.233-2.75 2.75v7.5c0 1.517 1.233 2.75 2.75 2.75h4.957l3.034 2.801c.142.131.324.199.509.199.102 0 .204-.021.301-.063.272-.12.449-.389.449-.687v-2.25h.25c1.517 0 2.75-1.233 2.75-2.75v-7.5c0-1.517-1.233-2.75-2.75-2.75zm-2 8.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>`,
        path: 'master.ourStudent'
    },
  
   
    {
        name: 'nav.administrativeRoles',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" xml:space="preserve">
   <g>
       <g>
           <path d="M105,392c-8.276,0-15,6.724-15,15s6.724,15,15,15s15-6.724,15-15S113.276,392,105,392z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M347,60h-30c0,33.091-26.909,60-60,60H135c-33.091,0-60-26.909-60-60H45C20.186,60,0,80.186,0,105v362
               c0,24.814,20.186,45,45,45h302c24.814,0,45-20.186,45-45V105C392,80.186,371.814,60,347,60z M64.395,175.605
               c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0L105,173.789l19.395-19.395c5.859-5.859,15.352-5.859,21.211,0
               s5.859,15.352,0,21.211L126.211,195l19.395,19.395c5.859,5.859,5.859,15.352,0,21.211c-5.859,5.859-15.352,5.859-21.211,0
               L105,216.211l-19.395,19.395c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211L83.789,195L64.395,175.605z
                M105,452c-24.814,0-45-20.186-45-45c0-19.53,12.578-36.024,30-42.237V315c0-24.814,20.186-45,45-45h122c8.276,0,15-6.724,15-15
               v-53.789l-4.395,4.395c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211l30-30
               c5.859-5.859,15.352-5.859,21.211,0l30,30c5.86,5.859,5.86,15.351,0,21.21c-5.859,5.859-15.352,5.859-21.211,0L302,201.211V255
               c0,24.814-20.186,45-45,45H135c-8.276,0-15,6.724-15,15v49.763c17.422,6.213,30,22.707,30,42.237C150,431.814,129.814,452,105,452
               z M327.605,426.395c5.859,5.859,5.859,15.352,0,21.211s-15.352,5.859-21.211,0L287,428.211l-19.395,19.395
               c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211L265.789,407l-19.395-19.395
               c-5.859-5.859-5.859-15.352,0-21.211c5.859-5.859,15.352-5.859,21.211,0L287,385.789l19.395-19.395
               c5.859-5.859,15.352-5.859,21.211,0s5.859,15.352,0,21.211L308.211,407L327.605,426.395z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M257,30h-29.154l-3.545-9.99C220.053,8.042,208.686,0,196,0c-12.686,0-24.053,8.042-28.301,20.01L164.154,30H135
               c-16.538,0-30,13.462-30,30s13.462,30,30,30h122c16.538,0,30-13.462,30-30S273.538,30,257,30z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M422,210v182.383C434.574,382.865,450.052,377,467,377s32.426,5.865,45,15.383V210H422z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M467,120c-24.814,0-45,20.186-45,45v15h90v-15C512,140.186,491.814,120,467,120z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M467,407c-15.348,0-28.89,7.747-37.017,19.51l23.599,47.199C456.131,478.792,461.316,482,467,482
               c5.684,0,10.869-3.208,13.418-8.291l23.599-47.199C495.89,414.747,482.348,407,467,407z"/>
       </g>
   </g>
   </svg>`,
        path: 'master.administrativeRoles'
    },
]