import api from '../api';

const state = () => ({
    classes: [],
    classesMeta: null,
    classInfo: {},
    faq: {
        why_important: "",
        faqs: [],
    },
    modules: [],
    module: null,
    chapterDetails: {},
    rules: [],
    rule: {},
    classDetail: {},
    selectedChapter: {},
    selectedChapterModule: {},
    typeWiseQuestion: {},
    ribbonses: [],
    isLoading: false,
});

const getters = {
    getLoading: state => state.isLoading,
    getClasses: state => state.classes,
    getClassDetail: state => state.classDetail,
    getClassesMeta: state => state.classesMeta,
    getClassInfo: state => state.classInfo,
    getFaq: state => state.faq,
    getModules: state => state.modules,
    getModule: state => state.module,
    getChapterDetails: state => state.chapterDetails,
    getSelectedChapter: state => state.selectedChapter,
    getSelectedChapterModule: state => state.selectedChapterModule,
    getRules: state => state.rules,
    getRule: state => state.rule,
    getTypeWiseQuestion: state => state.typeWiseQuestion,
    getRibbonses: state => state.ribbonses,
};

const mutations = {
    setLoading(state, payload) {
        state.isLoading = payload;
    },

    setClassList(state, payload) {
        state.classes = payload;
    },

    setClassListMeta(state, payload) {
        state.classesMeta = payload;
    },

    setClassInfo(state, payload) {
        state.classInfo = payload;
        localStorage.setItem('classBasicInfo', JSON.stringify(payload));
    },

    setFaq(state, payload) {
        state.faq = payload;
    },

    setModules(state, payload) {
        state.modules = payload;
    },

    newAddModule(state, payload) {
        state.modules.push(payload);
    },

    setModule(state, payload) {
        state.module = payload;
    },

    setChapterDetails(state, payload){
        state.chapterDetails = payload;
    },
   
    setSelectedChapter(state, payload) {
        state.selectedChapter = payload;
    },

    setSelectedChapterModule(state, payload) {
        state.selectedChapterModule = payload;
    },

    setRules(state, payload) {
        state.rules = payload;
    },

    setRule(state, payload) {
        state.rules = payload;
    },

    setClassDetail(state, payload) {
        state.classDetail = payload;
    },

    setTypeWiseQuestion(state, payload) {
        state.typeWiseQuestion = payload;
    },

    setOnlineClassRibbons(state, payload) {
        state.ribbonses = payload;
    },

};

const actions = {
    async fetchOnLineClassList ({ commit } , query)  {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/online-class${query ? query : ''}`)
                    .then( (response) => {
                        commit('setClassListMeta',response.meta);
                        commit('setClassList',response.items);
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
                } catch (error) {
                    reject(error);
                }
        })
    },

    async fetchOnLineClassById ({ commit } , id)  {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/online-class/${id}`)
                    .then( ({ items }) => {
                        commit('setClassDetail',items);
                        commit('setClassInfo',items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
                } catch (error) {
                    reject(error);
                }
        })
    },

    async onOnlineClassBasicCreationSave ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/basic', payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassDuplicateCreate ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/duplicate', payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassFaqCreation ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/faq', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassModuleCreation ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/module', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassChapterCreation ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/chapter', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassRulesCreation ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/rules', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

    async onOnlineClassPublish ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/online-class/publish', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        });
    },

     /*
        Question listing api, query string for filtering
    */
    async actTypeWiseQuestion ({ commit } , param)  {
        let url = '';

        if(param.type === 'practice_test') {
            url = 'api/v1/quiz-set';
        } else if(param.type === 'quiz') {
            url = 'api/v1/quiz-set'
        } else if(param.type === 'pte_practice') {
            url = 'api/v1/tests/pte-practice-list'
        }

        return new Promise((resolve, reject) => {
            try {
                api('get', `${url}${param.query ? param.query : ''}`)
                    .then((response) => {
                        commit('setTypeWiseQuestion', response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Properties / ribbons listing api
    */
    async fetchAllOnlineClassRibbons ({ commit })  {
        let url = 'api/v1/online-class-ribbons';

        return new Promise((resolve, reject) => {
            try {
                api('get', `${url}`)
                    .then((response) => {
                        commit('setOnlineClassRibbons', response?.items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    initialClassInfo({ commit }) {
        let data = JSON.parse(localStorage.getItem("classBasicInfo"));
        if(data) {
            commit('setClassInfo', data);
            
            let faq = {
                why_important: data.why_important ? data.why_important : '',
                faqs: data.faqs,
            };
            commit('setFaq', faq);
            commit('setModules', data.modules);
            commit('setRules', data.modules);
        }
    },
    removeAllClassInfo({commit}) {
        localStorage.removeItem('classBasicInfo');
        commit('setClassInfo', null);
        let faq = {
            why_important: '',
            faqs: [],
        };
        commit('setFaq', null);
        commit('setClassInfo', faq);
        commit('setModules', []);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
