
import registration from './registration'
import jobApplication from './jobApplication'
import axios from 'axios'

export const common = [
    ...jobApplication,
    ...registration,
    
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { layout: 'login' }
    },
    {
        path: '/ref',
        name: 'auth-token',
        beforeEnter: (to) => {
          
            
            try {
                console.log(to.query.auth_token);
                let baseURL = (process.env.NODE_ENV === "development") ? process.env.VUE_APP_BASE_URL_DEV: process.env.VUE_APP_BASE_URL_PROD;
                axios.get(baseURL+'/api/v1/auth-user', {
                    headers: {
                        Authorization: 'Bearer ' + to.query.auth_token,
                        token2: to.query.auth_token2
                      }
                
                }).then((res) => {
                    localStorage.setItem('token', to.query.auth_token);
                    localStorage.setItem('token2', to.query.auth_token2);
                    localStorage.setItem('user_id', res.data.user.id);
                    localStorage.setItem('user_type', res.data.user_type);
                    localStorage.setItem('organization_id', res.data.organization_id);
                    if(!res.data.user_type || !res.data.subdomain){
                        window.location.replace('master');
                      }else{
                        window.location.replace(res.data.user_type.toLowerCase().replace('_', '-'));

                      }
                  })
                
            } catch (e) {
                // console.log(e);
                location.replace('/');
                this.errors.push(e)
            }
              
        }
    },
    {
        path: '/components',
        name: 'components',
        component: () => import(/* webpackChunkName: "components" */ '../views/SampleComponentsStore.vue'),
        meta: { layout: 'teacher' }
    },
    {
        path: '/recover-your-password',
        name: 'recoverPassword',
        component: () => import(/* webpackChunkName: "recover-password" */ '../views/RecoverPassword.vue'),
        meta: { layout: 'login' }
    },
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "recover-password" */ '../views/Test.vue'),
        meta: { layout: 'login' }
    },
    {
        path: '/email-verification-success',
        name: 'EmailVerificationSuccess',
        component: () => import(/* webpackChunkName: "recover-password" */ '../views/EmailVerificationSuccess.vue'),
        meta: { layout: 'login' }
    },
    {
       path: '*',
       name: '404',
       component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
       meta: { layout: 'not-found' }
    }
];