import api from '../../api';
// import router from '@/router'

export const actGetPackageList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/packages${query}`)
                .then( ({ items, meta }) => {
                    
                    commit('setPackageList', items);
                    commit('setPackageMeta', meta);
                    commit('setSelectedPackageList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actSavePackage = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/packages`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdatePackage = ( store, {packageId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/packages/${packageId}`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actDeletePackage = ({ commit } , packageId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/packages/${packageId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', packageId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetPackageByPackageId = (store, packageId = '') => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/packages/${packageId}`)
                .then( (response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}




