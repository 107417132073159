import { filter } from 'lodash';
export const setPackageList = (state, packageList) => {
    state.packageList = packageList;
}
export const setPackageMeta = (state, packageMeta) => {
    state.packageMeta = packageMeta;
}
export const setSelectedPackageList = (state, packageList) => {
    state.selectedPackageList = packageList
}

export const setRemoveDeletedItem = (state, packageId) => {
    state.packageList = filter(state.packageList, (item) => item.id !== packageId);
}