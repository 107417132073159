<template>
  <div>
    <label v-if="label" :class="titleClass" class="pr-4 text-right text-uppercase font-bold">
     {{ label }}
    </label>
    <div :class="{'flex': !block}">
      <div v-for="(item, index) in checkboxItems" :key="index">
        <md-checkbox
          class="flex"
          v-model="checkbox"
          :value="item.id"
          :class="[labelClass, item.active]"
        >

        
          <span
            class="rounded  pr-6 flex align-center"
            :class="{ 'outline-gray-400': labelOutline }"
            >
            <span 
              v-show="isShowBadge"
              class="c-badge mr-2" 
              :style="{backgroundColor: item?.color_code}"
            ></span>
            {{ item[titleKey] }}</span
          >
        </md-checkbox>
      </div>
    </div>

    <div id="scrollTop" class="text-xs text-danger" v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => null,
    },
    titleKey: {
      type: String,
      default: 'label'
    },
    maxLimit: {
      type: Number,
      default:() => 0,
    },
    message: {
      type: String,
      default: () => null,
    },
    labelClass: {
      type: String,
      default: () => null,
    },
    titleClass: {
      type: String,
      default: () => null,
    },
    labelOutline: {
      type: Boolean,
      default: () => true,
    },
    block: {
      type: Boolean,
      default: () => true,
    },
    value: {
      type: [String, Array]
    },
    items: {
      type: Array,
      default: () => [
        { id: 1, label: "Answer 01", active: "" },
        { id: 2, label: "Answer 02", active: "" },
      ],
    },
	isShowBadge: {
		type: Boolean,
		default: false
	}
  },
  data: () => ({
    checkbox: [],
  }),
  computed: {
    checkboxItems() {
      return this.items;
    },
  },
  watch: {
    checkbox(value) {
      if(this.maxLimit && this.maxLimit < this.checkbox.length) {
        this.checkbox.shift();
        this.$emit("input", this.checkbox);
      } else if(value) {
        this.$emit("input", value);
      }
    },
    value:{
      handler(val) {
        if(val) {
          this.checkbox = val;
        }
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss" scoped>
.c-badge {
	position: relative;
	display: flex;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	z-index: 11;
}
</style>
