<template>
  <div 
  class="panel-header"
  :style="{backgroundColor: color}"
  :class="classObject"
  >
      <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: () => 'transparent'
        },
        classObject: {
            type: String
        }
    }
};
</script>

<style lang="scss">
   .panel-header {
       border-top-left-radius: 10px;
       border-top-right-radius: 10px;
   }
</style>
