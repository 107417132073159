import moment from 'moment';
export const user = (state) => state.user;
export const creatorInfo = state => state.creatorInfo;

export const userList = (state) => state.userList.map((item, index)  => {
    return {
        ...item,
        sl: index+1,
        created_at: item.created_at?moment(item.created_at).format('D MMM, YYYY. ddd'):'', 
    }
});
export const userMeta = (state) => state.userMeta;
export const selecteduserList = (state) => {
    const item  = state.userList;
    return item;
};