import api from '../../api';
// import router from '@/router'

export const actGetJobList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/job-circular${query}`)
                .then( ({ items, meta }) => {
                    
                    commit('setJobList', items);
                    commit('setJobMeta', meta);
                    commit('setSelectedJobList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actSaveJob = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/job-circular`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdateJob = ( store, {jobId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/job-circular/${jobId}`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actDeleteJob = ({ commit } , jobId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/job-circular/${jobId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', jobId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetJobByJobId = (store, jobId = '') => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/job-circular/${jobId}`)
                .then( (response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actSaveJobQuestion = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/job-question`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export  const  actGetQuestionsByJobId = (store, jobId = '') => {
    return new  Promise((resolve, reject) => {
        try {
            api('get', `api/v1/job-question/${jobId}`)
                .then( (response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}




