export const course = [

    {
        path: '/courses',
        name: 'courses',
        component: () => import(/* webpackChunkName: "courses" */ '../views/courses/IndexView.vue'),
        meta: {layout: 'teacher'}
    },
    {
        path: '/course',
        name: 'course.create',
        component: () => import('../views/courses/CreateView.vue'),
        meta: {layout: 'teacher'},
        redirect: {name: 'courseCreation.basicInformation'},
        children: [
            {
                path: 'basic-information',
                name: 'courseCreation.basicInformation',
                component: () => import('../views/courses/BasicInformationView.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'type',
                name: 'courseCreation.courseType',
                component: () => import('../views/courses/CourseTypeView.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'adons',
                name: 'courseCreation.adons',
                component: () => import('../views/courses/adOns.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties',
                name: 'courseCreation.properties',
                component: () => import('../views/courses/PtePractice.vue'),
                // component: () => import('../views/courses/CreateView.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/pte-practice',
                name: 'courseCreation.properties.ptePractice',
                component: () => import('../views/courses/PtePractice.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/practice-test',
                name: 'courseCreation.properties.practiceTest',
                component: () => import('../views/courses/PracticeTest.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/mock-test',
                name: 'courseCreation.properties.mockTest',
                component: () => import('../views/courses/MockTest.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/quiz',
                name: 'courseCreation.properties.quiz',
                component: () => import('../views/courses/Quiz.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/materials',
                name: 'courseCreation.properties.materials',
                component: () => import('../views/courses/Materials.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/live-class',
                name: 'courseCreation.properties.liveClass',
                component: () => import('../views/courses/LiveClass.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/webinars',
                name: 'courseCreation.properties.webinars',
                component: () => import('../views/courses/Webinars.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'properties/one-to-one-appointment',
                name: 'courseCreation.properties.OneToOneAppointment',
                component: () => import('../views/courses/OneToOneAppointment.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'duration-price',
                name: 'courseCreation.durationAndPrice',
                component: () => import('../views/courses/DurationAndPrice.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'review',
                name: 'courseCreation.review',
                component: () => import('../views/courses/Review.vue'),
                meta: {layout: 'teacher'}
            },
            {
                path: 'preview',
                name: 'courseCreation.preview',
                component: () => import('../views/courses/Preview.vue'),
                meta: {layout: 'teacher'}
            },
        ]
    },  
];