import api from '../api'
// import { merge } from 'lodash'
const state = () => ({
    sampleAnswer: {},
    sampleAnswers: [],
    QuestionList:[],
    sampleAnswerQuestionListMeta: []
});


const getters = {
    getSampleAnswerShow: state =>  state.sampleAnswer,
    getSampleAnswers: state =>  state.sampleAnswers.map((item, index) => {
        item.sl = index;
        return item;
    }),
    getSampleAnswersList: state => state.QuestionList,
    getSampleAnswerQuestionListMeta: state => state.sampleAnswerQuestionListMeta,
}


const mutations = {
    setSampleAnswerList(state, payload) {
        state.sampleAnswers = payload;
    },
    setQuestionList(state, payload) {
        state.QuestionList = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setSampleAnswerQuestionListMeta(state, sampleAnswerQuestionListMeta) {
        state.sampleAnswerQuestionListMeta = sampleAnswerQuestionListMeta
    },
    setSampleAnswerShow(state, payload) {
        state.sampleAnswer = payload;
    },
}

const actions = {
    async actSaveSampleAnswer ( store, payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/sample-answer`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actListSampleAnswer ( { commit }, questionTypeId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/sample-answer/choose-from-archive/${questionTypeId}`)
                    .then( response => {
                        resolve(response);
                        commit('setSampleAnswerList', response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actChangeStatustSampleAnswer (store, {sampleAnswerId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/sample-answer/change-default-status/${sampleAnswerId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actAnswerListByQuestion ( store, questionId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/sample-answer/question-list/${questionId}`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actSampleAnswerQuestionList ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/sample-answer/question-list${query}`)
                    .then( response => {
                        commit('setQuestionList', response.items)
                        commit('setSampleAnswerQuestionListMeta', response.meta);
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actShowSampleAnswer ({ commit }, SampleAnswerId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/sample-answer/${SampleAnswerId}`)
                    .then( response => {
                        commit('setSampleAnswerShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }