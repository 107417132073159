<template>
  <div>
    <slot name="outer"></slot>
    <div class="panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.panel {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}
</style>
