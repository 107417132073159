export default {
    methods: {
        /**
         * Get data from localStorage by key
         * @param {String} key 
         */
        getLocal(key) {
           const items = localStorage.getItem(key);
          return  items!='undefined'?JSON.parse(localStorage.getItem(key)):'';
        },
        /**
         * Save data in localStorage
         * @param {String} key 
         * @param {Object} value 
         */
        setLocal(key, value) {
            localStorage.setItem(key ,JSON.stringify(value));
        },

        /**
         * Delete localStorage data by key
         * @param {String} key 
         */
        remove(key) {
            localStorage.removeItem(key);
        }

    }
}