import moment from 'moment';
export const applicantList = (state) => state.applicantList.map((item, index)  => {
    console.log(item)
    return {
        id: index+1,
        applicantId: item.id,
        name: item.applicant_name,
        email: item.applicant_email,
        contact_number: item.applicant_contact_number,
        appliedAt: item.application_date?moment(item.application_date).format('D MMM, YYYY. ddd'):'',
        point: item.point,
        expectedSalary: item.expected_salary,
        cv: item.cv,
        pte_score_card: item.pte_score_card,
        educational_details: item.educational_details,
        experience_details: item.experience_details,
        choosing_reason: item.choosing_reason,
        status: item.active,
    }
});
export const applicantMeta = (state) => state.applicantMeta;