export const admin = [
    {
        path: '/admin',
        name: 'admin',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        redirect: {name: 'admin.portal'},
        children: [
            {
                path: 'portal',
                name: 'admin.portal',
                component: () => import(/* webpackChunkName: "admin-portal" */ '../views/admin/AdminPortalView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'task',
                name: 'admin.task',
                component: () => import(/* webpackChunkName: "admin-task" */ '../views/admin/AdminTaskView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'our-student',
                name: 'admin.ourStudent',
                component: () => import(/* webpackChunkName: "student" */ '../views/admin/AdminOurStudentView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'rostering',
                name: 'admin.rostering',
                component: () => import(/* webpackChunkName: "admin-rostering" */ '../views/admin/AdminRosteringView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'question-creation',
                name: 'admin.questionCreation',
                component: () => import(/* webpackChunkName: "admin-question-creation" */ '../views/admin/question-creation/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'test-creation',
                name: 'admin.testCreation',
                component: () => import(/* webpackChunkName: "admin-test-creation" */ '../views/admin/test-creation/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'materials',
                name: 'admin.materials',
                component: () => import(/* webpackChunkName: "admin-material" */ '../views/admin/materials/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'pending-evaluation',
                name: 'admin.pendingEvaluation',
                component: () => import(/* webpackChunkName: "admin-pending-evaluation" */ '../views/admin/pending-evaluation/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'group-creation',
                name: 'admin.groupCreation',
                component: () => import(/* webpackChunkName: "admin-group-creation" */ '../views/admin/group-creation/IndexView.vue'),
                meta: {layout: 'admin'}
            },            
            {
                path: 'crm',
                name: 'admin.crm',
                component: () => import(/* webpackChunkName: "admin-crm" */ '../views/admin/crm/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'communication',
                name: 'admin.communication',
                component: () => import(/* webpackChunkName: "admin-communication" */ '../views/admin/communication/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'form',
                name: 'admin.form',
                component: () => import(/* webpackChunkName: "admin-form" */ '../views/admin/form/IndexView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'how-to-use-this-portal',
                name: 'adminHowToUseThisPortal',
                component: () => import(/* webpackChunkName: "admin-how-to-use" */ '../views/common/HowToUseThisPortalView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'faq',
                name: 'adminFaq',
                component: () => import(/* webpackChunkName: "admin-faq" */ '../views/common/FAQView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'blog',
                name: 'adminBlog',
                component: () => import(/* webpackChunkName: "admin-blog" */ '../views/common/BlogView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'material',
                name: 'adminMaterial',
                component: () => import(/* webpackChunkName: "admin-page-material" */ '../views/common/MaterialView.vue'),
                meta: {layout: 'admin'}
            },
            {
                path: 'registration-process',
                name: 'adminRegistrationProcess',
                component: () => import(/* webpackChunkName: "admin-registration-process" */ '../views/common/RegistrationProcessView.vue'),
                meta: {layout: 'admin'}
            }

        ]
    }
];