export default [
    {
        name: 'nav.myPortal',
        path: 'teacher.portal',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 612">
   <g>
       <g>
           <path d="M566.104,323.594h-21.531c-5.826,0-12.619-5.272-14.834-11.515c-2.053-5.786-4.465-11.545-7.16-17.121
               c-2.871-5.93-1.814-14.418,2.305-18.537l15.326-15.328c10.738-10.737,10.738-28.21,0-38.948l-24.877-24.877
               c-5.203-5.202-12.117-8.066-19.475-8.066c-7.355,0-14.271,2.864-19.473,8.066l-15.328,15.328
               c-2.461,2.46-6.559,3.929-10.957,3.929c-2.754,0-5.375-0.563-7.574-1.628c-5.578-2.699-11.346-5.113-17.145-7.176
               c-6.24-2.215-11.512-9.008-11.512-14.832v-21.533c0-15.184-12.354-27.54-27.541-27.54h-35.189
               c-15.188,0-27.541,12.356-27.541,27.54v21.533c0,5.826-5.271,12.62-11.512,14.832c-5.791,2.057-11.557,4.465-17.129,7.158
               c-2.163,1.046-4.847,1.622-7.558,1.622c-4.416,0-8.525-1.472-10.992-3.938l-15.303-15.297c-5.202-5.202-12.118-8.066-19.477-8.066
               c-7.356,0-14.272,2.864-19.474,8.063l-24.902,24.887c-5.205,5.199-8.069,12.114-8.072,19.471c0,7.356,2.864,14.272,8.066,19.474
               l15.328,15.328c4.122,4.122,5.181,12.616,2.311,18.55c-2.69,5.566-5.098,11.322-7.151,17.111
               c-2.215,6.239-9.005,11.512-14.832,11.512h-21.53c-15.187,0-27.54,12.356-27.54,27.54v35.19c0,15.184,12.353,27.54,27.54,27.54
               h21.53c5.827,0,12.62,5.269,14.835,11.509c2.05,5.773,4.458,11.532,7.157,17.111c2.868,5.93,1.806,14.425-2.319,18.55
               l-15.328,15.327c-10.737,10.737-10.737,28.21,0,38.947l24.912,24.915c5.202,5.202,12.118,8.066,19.474,8.066
               c7.356,0,14.272-2.864,19.474-8.066l15.328-15.327c2.466-2.467,6.573-3.938,10.985-3.938c2.751,0,5.364,0.56,7.558,1.622
               c5.576,2.695,11.333,5.104,17.115,7.157c6.238,2.215,11.512,9.008,11.512,14.835v21.533c0,15.184,12.354,27.539,27.539,27.539
               h35.191c15.186,0,27.539-12.355,27.539-27.539v-21.533c0-5.827,5.273-12.62,11.512-14.832c5.793-2.057,11.559-4.465,17.131-7.157
               c2.162-1.047,4.846-1.622,7.557-1.622c4.416,0,8.523,1.472,10.99,3.938l15.326,15.327c5.203,5.202,12.115,8.066,19.471,8.066
               c7.359,0,14.275-2.867,19.477-8.072l24.889-24.902c10.73-10.74,10.73-28.213-0.004-38.951l-15.314-15.321
               c-4.119-4.118-5.176-12.613-2.309-18.547c2.693-5.569,5.098-11.331,7.154-17.12c2.213-6.239,9.004-11.509,14.83-11.509h21.529
               c15.188,0,27.541-12.356,27.541-27.54v-35.19C593.643,335.948,581.287,323.594,566.104,323.594z M449.441,368.729
               c0,44.502-36.207,80.708-80.709,80.708c-44.5,0-80.707-36.206-80.707-80.708c0-44.501,36.207-80.707,80.707-80.707
               C413.234,288.022,449.441,324.225,449.441,368.729z"/>
           <path d="M201.868,146.904c0.521-1.466,2.262-2.702,3.082-2.702h10.784c10.123,0,18.36-8.234,18.36-18.36v-17.595
               c0-10.125-8.237-18.36-18.36-18.36h-10.781c-0.82,0-2.561-1.236-3.081-2.702c-1.083-3.051-2.353-6.089-3.776-9.024
               c-0.664-1.374-0.312-3.446,0.263-4.021l7.666-7.665c7.157-7.161,7.157-18.807,0-25.964l-12.439-12.439
               c-3.467-3.467-8.079-5.376-12.983-5.376c-4.905,0-9.517,1.913-12.984,5.379l-7.662,7.663c-0.239,0.239-1.059,0.621-2.237,0.621
               c-0.67,0-1.319-0.128-1.784-0.355c-2.94-1.423-5.976-2.693-9.024-3.773c-1.469-0.52-2.705-2.265-2.705-3.082V18.366
               c0-10.126-8.237-18.36-18.36-18.36h-17.595c-10.123,0-18.36,8.234-18.36,18.36v10.783c0,0.82-1.236,2.558-2.702,3.079
               c-3.063,1.086-6.102,2.359-9.03,3.776c-0.462,0.223-1.114,0.352-1.784,0.352c-1.178,0-1.995-0.379-2.233-0.618l-7.666-7.663
               c-3.467-3.467-8.078-5.376-12.983-5.376s-9.514,1.909-12.98,5.376L28.073,40.514c-7.157,7.161-7.157,18.81,0,25.967l7.662,7.662
               c0.575,0.575,0.93,2.647,0.266,4.018c-1.423,2.938-2.693,5.976-3.776,9.027c-0.52,1.466-2.265,2.705-3.082,2.705H18.36
               C8.238,89.893,0,98.127,0,108.253v17.595c0,10.123,8.235,18.36,18.357,18.36h10.784c0.82,0,2.561,1.236,3.082,2.702
               c1.083,3.054,2.353,6.089,3.776,9.024c0.664,1.374,0.312,3.445-0.263,4.021l-7.666,7.666c-3.467,3.467-5.376,8.079-5.376,12.983
               c0,4.905,1.909,9.514,5.379,12.984l12.439,12.436c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l7.666-7.665
               c0.238-0.239,1.059-0.621,2.237-0.621c0.67,0,1.319,0.128,1.784,0.354c2.941,1.423,5.977,2.693,9.024,3.773
               c1.469,0.52,2.705,2.264,2.705,3.081v10.784c0,10.125,8.238,18.36,18.36,18.36h17.595c10.123,0,18.36-8.234,18.36-18.36v-10.781
               c0-0.82,1.236-2.558,2.702-3.078c3.051-1.083,6.086-2.353,9.024-3.776c0.465-0.227,1.117-0.355,1.787-0.355
               c1.178,0,1.995,0.382,2.237,0.621l7.666,7.665c3.467,3.467,8.078,5.376,12.983,5.376s9.514-1.909,12.98-5.376l12.439-12.438
               c7.157-7.161,7.157-18.807,0-25.964l-7.662-7.662c-0.576-0.575-0.931-2.65-0.267-4.021
               C199.519,152.987,200.788,149.952,201.868,146.904z M152.811,117.044c0,19.719-16.043,35.765-35.762,35.765
               c-19.722,0-35.765-16.043-35.765-35.765s16.043-35.765,35.765-35.765C136.767,81.279,152.811,97.323,152.811,117.044z"/>
       </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>
   `
    },
    {
        name: 'nav.myTask',
        path: 'teacher.task',
        icon: `<svg class="icon-svg"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
        <g>
            <path d="M482,121h-31V61h-60V30c0-16.569-13.431-30-30-30s-30,13.431-30,30v31h-60v180h180v-60h31c16.569,0,30-13.433,30-30   C512,134.431,498.569,121,482,121z"/>
            <path d="M0,151c0,16.567,13.431,30,30,30h31v60h60v-30c0-16.569,13.431-30,30-30s30,13.431,30,30v30h60V61h-60V30   c0-16.569-13.431-30-30-30s-30,13.431-30,30v31H61v60H30C13.431,121,0,134.431,0,151z"/>
            <path d="M482,331h-31v-60H271v60h30c16.569,0,30,13.431,30,30c0,16.567-13.431,30-30,30h-30v60h60v31c0,16.567,13.431,30,30,30   s30-13.433,30-30v-31h60v-60h31c16.569,0,30-13.433,30-30C512,344.431,498.569,331,482,331z"/>
        </g>
        </svg>`
    },
    {
        name: 'nav.courses',
        path: 'courses',
        icon: `
        <svg class="icon-svg" viewBox="0 0 712 712" xmlns="http://www.w3.org/2000/svg"><path d="m440.976562 93.765625 6.480469 36.933594c15.78125-2.769531 33.449219 3.382812 46.121094 16.054687s18.824219 30.347656 16.054687 46.128906l36.9375 6.472657c4.917969-28.046875-4.980468-57.621094-26.472656-79.117188-21.5-21.496093-51.074218-31.394531-79.121094-26.472656zm0 0"/><path d="m582.625 57.707031c-44.421875-44.425781-105.238281-64.925781-162.683594-54.855469l6.480469 36.933594c45.421875-7.964844 93.898437 8.648438 129.691406 44.4375 35.789063 35.789063 52.402344 84.269532 44.433594 129.6875l36.933594 6.480469c10.074219-57.445313-10.429688-118.261719-54.855469-162.683594zm0 0"/><path d="m344.75 181.554688c-8.066406 8.085937-14.179688 17.585937-18.164062 27.933593l-126.140626 47.445313 29.109376 29.113281 92.65625-34.851563c1.652343 10.488282 5.335937 20.460938 10.871093 29.445313l-54.390625 54.535156 26.511719 26.515625 54.390625-54.53125c9.175781 5.644532 19.238281 9.285156 29.5625 10.917969l-34.863281 92.699219 29.109375 29.109375 47.460937-126.195313c10.238281-3.929687 19.835938-10.003906 28.066407-18.257812 15.210937-15.246094 23.570312-35.507813 23.539062-57.042969-.027344-21.539063-8.441406-41.777344-23.691406-56.984375-31.476563-31.394531-82.628906-31.328125-114.027344.148438zm0 0"/><path d="m0 363.753906c.0976562 73.976563 28.996094 143.484375 81.371094 195.71875 52.292968 52.152344 121.722656 80.859375 195.558594 80.859375h.375c68.378906-.085937 132.933593-24.804687 183.582031-69.925781l-390.960938-390.960938c-45.285156 50.832032-70.0117185 115.675782-69.925781 184.308594zm0 0"/></svg>`,  
    },
    {
        name: 'nav.mySchedule',
        path: 'teacher.schedule',
        icon: `
        <svg version="1.1" id="Capa_3" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512 512"
             xml:space="preserve">
        <g>
            <g>
                <path d="M177.854,269.311c0-6.115-4.96-11.069-11.08-11.069h-38.665c-6.113,0-11.074,4.954-11.074,11.069v38.66
                    c0,6.123,4.961,11.079,11.074,11.079h38.665c6.12,0,11.08-4.956,11.08-11.079V269.311L177.854,269.311z"/>
                <path d="M274.483,269.311c0-6.115-4.961-11.069-11.069-11.069h-38.67c-6.113,0-11.074,4.954-11.074,11.069v38.66
                    c0,6.123,4.961,11.079,11.074,11.079h38.67c6.108,0,11.069-4.956,11.069-11.079V269.311z"/>
                <path d="M371.117,269.311c0-6.115-4.961-11.069-11.074-11.069h-38.665c-6.12,0-11.08,4.954-11.08,11.069v38.66
                    c0,6.123,4.96,11.079,11.08,11.079h38.665c6.113,0,11.074-4.956,11.074-11.079V269.311z"/>
                <path d="M177.854,365.95c0-6.125-4.96-11.075-11.08-11.075h-38.665c-6.113,0-11.074,4.95-11.074,11.075v38.653
                    c0,6.119,4.961,11.074,11.074,11.074h38.665c6.12,0,11.08-4.956,11.08-11.074V365.95L177.854,365.95z"/>
                <path d="M274.483,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.113,0-11.074,4.95-11.074,11.075v38.653
                    c0,6.119,4.961,11.074,11.074,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95z"/>
                <path d="M371.117,365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.12,0-11.08,4.95-11.08,11.075v38.653
                    c0,6.119,4.96,11.074,11.08,11.074h38.67c6.108,0,11.069-4.956,11.069-11.074V365.95L371.117,365.95z"/>
                <path d="M440.254,54.354v59.05c0,26.69-21.652,48.198-48.338,48.198h-30.493c-26.688,0-48.627-21.508-48.627-48.198V54.142
                    h-137.44v59.262c0,26.69-21.938,48.198-48.622,48.198H96.235c-26.685,0-48.336-21.508-48.336-48.198v-59.05
                    C24.576,55.057,5.411,74.356,5.411,98.077v346.061c0,24.167,19.588,44.015,43.755,44.015h389.82
                    c24.131,0,43.755-19.889,43.755-44.015V98.077C482.741,74.356,463.577,55.057,440.254,54.354z M426.091,422.588
                    c0,10.444-8.468,18.917-18.916,18.917H80.144c-10.448,0-18.916-8.473-18.916-18.917V243.835c0-10.448,8.467-18.921,18.916-18.921
                    h327.03c10.448,0,18.916,8.473,18.916,18.921L426.091,422.588L426.091,422.588z"/>
                <path d="M96.128,129.945h30.162c9.155,0,16.578-7.412,16.578-16.567V16.573C142.868,7.417,135.445,0,126.29,0H96.128
                    C86.972,0,79.55,7.417,79.55,16.573v96.805C79.55,122.533,86.972,129.945,96.128,129.945z"/>
                <path d="M361.035,129.945h30.162c9.149,0,16.572-7.412,16.572-16.567V16.573C407.77,7.417,400.347,0,391.197,0h-30.162
                    c-9.154,0-16.577,7.417-16.577,16.573v96.805C344.458,122.533,351.881,129.945,361.035,129.945z"/>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        `,
    },
    {
        name: 'nav.questionCreation',
        path: 'teacher.questionCreation',
        icon: `<svg version="1.1" id="Capa_4" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 340 340" style="enable-background:new 0 0 340 340;" xml:space="preserve">
   <path d="M170,0C76.109,0,0,76.112,0,170s76.109,170,170,170c93.89,0,170-76.112,170-170S263.89,0,170,0z M169.633,281.611
       c-12.574,0-22.803-10.23-22.803-22.804c0-12.573,10.229-22.803,22.803-22.803c12.575,0,22.806,10.229,22.806,22.803
       C192.439,271.381,182.208,281.611,169.633,281.611z M205.357,169.659c-9.856,9.221-16.367,15.312-16.367,30.062v10.911
       c0,2.761-2.238,5-5,5h-27.122c-2.762,0-5-2.239-5-5v-10.911c0-30.293,15.744-45.076,27.239-55.87
       c9.785-9.179,16.248-15.242,16.248-29.778c0-4.591,0-18.562-24.925-18.562c-23.37,0-24.306,12.883-24.705,18.384l-0.813,11.186
       c-0.099,1.346-0.736,2.596-1.771,3.464c-1.031,0.87-2.372,1.282-3.715,1.148l-27.051-2.707c-2.7-0.27-4.691-2.645-4.488-5.351
       l0.799-10.613c2.41-31.974,26.749-52.634,62.006-52.634c18.438,0,33.952,5.176,44.865,14.968
       c11.066,9.931,16.918,24.102,16.918,40.98C232.477,144.265,216.802,158.942,205.357,169.659z"/>
   </svg>`
    },
    {
        name: 'nav.testCreation',
        icon: `
        <svg class="icon-svg" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m500.625 60.445312-241-60c-2.382812-.59375-4.867188-.59375-7.25 0l-241 60c-6.683594 1.664063-11.375 7.667969-11.375 14.554688 0 6.890625 4.691406 12.894531 11.375 14.558594l241 60c1.191406.292968 2.40625.441406 3.625.441406s2.433594-.148438 3.625-.441406l222.375-55.367188v70.808594c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-90c0-6.886719-4.691406-12.890625-11.375-14.554688zm0 0"/><path d="m266.871094 178.667969c-3.550782.882812-7.207032 1.332031-10.871094 1.332031s-7.320312-.449219-10.875-1.332031l-169.125-42.105469v143.4375h360v-143.4375zm0 0"/><path d="m512 325c0-8.285156-6.714844-15-15-15h-422c-41.355469 0-75 33.644531-75 75s33.644531 75 75 75h422c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-422c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45h422c8.285156 0 15-6.714844 15-15zm0 0"/><path d="m60 385c0 8.273438 6.730469 15 15 15h407v-30h-407c-8.269531 0-15 6.730469-15 15zm0 0"/></svg>`,

        path: 'testCreation'
    },
    {
        name: 'nav.materials',
        icon: `<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 511.999 511.999" class="icon-svg" xml:space="preserve">
   <g>
       <g>
           <path d="M466.865,211.499H98.994c-14.941,0-28.608,7.251-35.713,18.223c-3.75,4.688-6.592,10.664-8.013,16.904L8.452,456.641
               c-2.974,13.418,0.234,27.261,8.818,37.969c8.613,10.737,21.445,16.89,35.229,16.89h367.563c20.991,0,39.507-14.78,44.033-35.142
               l46.802-210c0.015-0.015,0.015-0.015,0.015-0.015C517.163,238.108,495.665,211.499,466.865,211.499z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M355.587,94.875L265.624,4.912c-2.795-2.803-8.302-4.413-10.624-4.413H105c-8.291,0-15,6.709-15,15v166
               c101.384,0,167.41,0,270,0v-76C360,102.823,358.3,97.58,355.587,94.875z M285,150.499H165c-8.291,0-15-6.709-15-15s6.709-15,15-15
               h90h30c8.291,0,15,6.709,15,15S293.291,150.499,285,150.499z M270,90.499V51.71l38.789,38.789H270z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M45,91.499c-24.814,0-45,20.186-45,45v220.19l25.986-116.596c2.344-10.313,6.812-19.907,12.95-27.949
               c5.4-7.917,12.812-14.198,21.064-19.261V91.499H45z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M405,121.499h-15v60h60v-15C450,141.685,429.814,121.499,405,121.499z"/>
       </g>
   </g>
   </svg>`,
        path: 'material.nav'
    },
    {
        name: 'nav.pendingEvaluation',
        icon: `<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" class="icon-svg" xml:space="preserve">
   <g>
       <g>
           <polygon points="346,8.789 346,120 457.211,120 		"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M316,150V0H46v512h420V150H316z M136,120h120v30H136V120z M316,422H136v-30h180V422z M376,422h-30v-30h30V422z M376,332
               H136v-30h240V332z M376,242H136v-30h240V242z"/>
       </g>
   </g>
   </svg>`,
        path: 'pendingEvaluation'
    },
    {
        name: 'nav.studyPlan',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" xml:space="preserve">
   <g>
       <g>
           <path d="M105,392c-8.276,0-15,6.724-15,15s6.724,15,15,15s15-6.724,15-15S113.276,392,105,392z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M347,60h-30c0,33.091-26.909,60-60,60H135c-33.091,0-60-26.909-60-60H45C20.186,60,0,80.186,0,105v362
               c0,24.814,20.186,45,45,45h302c24.814,0,45-20.186,45-45V105C392,80.186,371.814,60,347,60z M64.395,175.605
               c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0L105,173.789l19.395-19.395c5.859-5.859,15.352-5.859,21.211,0
               s5.859,15.352,0,21.211L126.211,195l19.395,19.395c5.859,5.859,5.859,15.352,0,21.211c-5.859,5.859-15.352,5.859-21.211,0
               L105,216.211l-19.395,19.395c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211L83.789,195L64.395,175.605z
                M105,452c-24.814,0-45-20.186-45-45c0-19.53,12.578-36.024,30-42.237V315c0-24.814,20.186-45,45-45h122c8.276,0,15-6.724,15-15
               v-53.789l-4.395,4.395c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211l30-30
               c5.859-5.859,15.352-5.859,21.211,0l30,30c5.86,5.859,5.86,15.351,0,21.21c-5.859,5.859-15.352,5.859-21.211,0L302,201.211V255
               c0,24.814-20.186,45-45,45H135c-8.276,0-15,6.724-15,15v49.763c17.422,6.213,30,22.707,30,42.237C150,431.814,129.814,452,105,452
               z M327.605,426.395c5.859,5.859,5.859,15.352,0,21.211s-15.352,5.859-21.211,0L287,428.211l-19.395,19.395
               c-5.859,5.859-15.352,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211L265.789,407l-19.395-19.395
               c-5.859-5.859-5.859-15.352,0-21.211c5.859-5.859,15.352-5.859,21.211,0L287,385.789l19.395-19.395
               c5.859-5.859,15.352-5.859,21.211,0s5.859,15.352,0,21.211L308.211,407L327.605,426.395z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M257,30h-29.154l-3.545-9.99C220.053,8.042,208.686,0,196,0c-12.686,0-24.053,8.042-28.301,20.01L164.154,30H135
               c-16.538,0-30,13.462-30,30s13.462,30,30,30h122c16.538,0,30-13.462,30-30S273.538,30,257,30z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M422,210v182.383C434.574,382.865,450.052,377,467,377s32.426,5.865,45,15.383V210H422z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M467,120c-24.814,0-45,20.186-45,45v15h90v-15C512,140.186,491.814,120,467,120z"/>
       </g>
   </g>
   <g>
       <g>
           <path d="M467,407c-15.348,0-28.89,7.747-37.017,19.51l23.599,47.199C456.131,478.792,461.316,482,467,482
               c5.684,0,10.869-3.208,13.418-8.291l23.599-47.199C495.89,414.747,482.348,407,467,407z"/>
       </g>
   </g>
   </svg>`,
        path: 'studyPlan'
    },
    {
        name: 'nav.liveClass',
        icon: `
        <svg class="icon-svg" viewBox="0 0 712 712" xmlns="http://www.w3.org/2000/svg"><path d="m440.976562 93.765625 6.480469 36.933594c15.78125-2.769531 33.449219 3.382812 46.121094 16.054687s18.824219 30.347656 16.054687 46.128906l36.9375 6.472657c4.917969-28.046875-4.980468-57.621094-26.472656-79.117188-21.5-21.496093-51.074218-31.394531-79.121094-26.472656zm0 0"/><path d="m582.625 57.707031c-44.421875-44.425781-105.238281-64.925781-162.683594-54.855469l6.480469 36.933594c45.421875-7.964844 93.898437 8.648438 129.691406 44.4375 35.789063 35.789063 52.402344 84.269532 44.433594 129.6875l36.933594 6.480469c10.074219-57.445313-10.429688-118.261719-54.855469-162.683594zm0 0"/><path d="m344.75 181.554688c-8.066406 8.085937-14.179688 17.585937-18.164062 27.933593l-126.140626 47.445313 29.109376 29.113281 92.65625-34.851563c1.652343 10.488282 5.335937 20.460938 10.871093 29.445313l-54.390625 54.535156 26.511719 26.515625 54.390625-54.53125c9.175781 5.644532 19.238281 9.285156 29.5625 10.917969l-34.863281 92.699219 29.109375 29.109375 47.460937-126.195313c10.238281-3.929687 19.835938-10.003906 28.066407-18.257812 15.210937-15.246094 23.570312-35.507813 23.539062-57.042969-.027344-21.539063-8.441406-41.777344-23.691406-56.984375-31.476563-31.394531-82.628906-31.328125-114.027344.148438zm0 0"/><path d="m0 363.753906c.0976562 73.976563 28.996094 143.484375 81.371094 195.71875 52.292968 52.152344 121.722656 80.859375 195.558594 80.859375h.375c68.378906-.085937 132.933593-24.804687 183.582031-69.925781l-390.960938-390.960938c-45.285156 50.832032-70.0117185 115.675782-69.925781 184.308594zm0 0"/></svg>`,
        path: 'teacher.liveClass'
    },
    {
        name: 'nav.crm',
        icon: `
        <svg class="icon-svg" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><circle cx="256" cy="180.964" r="47.882"/><path d="m0 361.928v60h170.944v30.048h-60.06v30h290.232v-30h-60.06v-30.048h170.944v-60z"/><path d="m512 331.928v-301.904h-512v301.904zm-391.94-75.916h-60.024v-30h60.024zm0-60.048h-60.024v-30h60.024zm0-60.048h-60.024v-30h60.024zm241.856 60.048h-29.498c-2.027 10.346-6.098 19.967-11.791 28.414l20.873 20.873-21.213 21.213-20.873-20.873c-8.447 5.693-18.068 9.764-28.414 11.791v29.499h-30v-29.499c-10.345-2.027-19.967-6.098-28.414-11.791l-20.874 20.874-21.212-21.214 20.873-20.874c-5.693-8.447-9.764-18.069-11.791-28.414h-29.498v-30h29.498c2.027-10.345 6.098-19.967 11.791-28.414l-20.873-20.872 21.213-21.213 20.874 20.874c8.447-5.693 18.068-9.763 28.414-11.791v-29.499h30v29.499c10.345 2.027 19.967 6.098 28.414 11.791l20.873-20.873 21.213 21.213-20.873 20.873c5.693 8.447 9.764 18.069 11.791 28.414h29.498v29.999zm90.048 60.048h-60.024v-30h60.023v30zm0-60.048h-60.024v-30h60.023v30zm0-60.048h-60.024v-30h60.023v30z"/></g></svg>`,
        path: 'crm'
    },
    {
        name: 'nav.onlineClass',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve">
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><g><path d="M889.3,883.8h-16.7h-4.9v-55.9l0.4,0.5V703.9l-0.4-0.5V482.6H759.6c2.7-26.1,5.1-55.2,6.4-84.9c4.1-93.7,18.1-391.1-251.6-391.1C244.7,6.7,252,255,252,255L266,482.6H147.6v400.2c-0.6,0.6-1,1-1,1h-30.5v14.4H10v95h70H370h305.5h271.3H990v-95l-101.3,0.6L889.3,883.8z M516.1,742.5c-27.4,0-49.6-21.9-49.6-48.9c0-27,22.2-48.9,49.6-48.9c27.4,0,49.6,21.9,49.6,48.9C565.7,720.6,543.5,742.5,516.1,742.5z M576.2,453v29.6H456.1v-31.8c-87.6-27.3-151-109-151-205.5c0-7.2,0.4-14.2,1.1-21.2c58.6-4,261.1-22.4,266.3-87.9c19.2,35.6,121.5,125.1,162,131C725.3,357,661.1,430.2,576.2,453z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
        </svg>`,
        path: 'onlineClass'
    },
    {
        name: 'nav.blog',
        icon: `<svg version="1.1" class="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 95.938 95.938" style="enable-background:new 0 0 95.938 95.938;"
        xml:space="preserve">
   <g>
       <path d="M95.329,59.861c-0.031-7.134-0.053-12.289-3.562-16.824c-3.975-5.134-10.106-6.004-15.076-6.152
           c0.837-3.587,1.079-7.564,0.692-11.863C77.277,10.705,66.624,0.414,51.839,0.006L51.626,0L29.012,0.018l-0.243-0.001
           c-8.795,0-15.747,2.626-20.664,7.804c-3.043,3.203-7.085,8.798-7.513,17.845l-0.018,36.48c-0.044,0.689,0.025,1.385,0.016,2.1
           c0,10.379,1.661,17.85,6.848,23.371c5.183,5.521,12.847,8.32,22.776,8.32c0.215,0,0.432-0.001,0.649-0.004H65.41h0.082
           c8.858-0.074,16.343-2.998,21.646-8.455c5.293-5.447,8.143-13.208,8.24-22.441C95.377,65.037,95.335,61.32,95.329,59.861z
            M30.846,19.457l19.322-0.023c4.554,0.072,8.188,4.151,8.114,8.705c-0.072,4.509-3.749,7.799-8.242,7.799
           c-0.044,0-19.455,0.008-19.455,0.008c-4.554-0.073-8.186-3.823-8.113-8.375C22.544,23.017,26.286,19.413,30.846,19.457z
            M67.164,73.732H28.521c-4.553,0-8.246-3.691-8.246-8.246c0-4.554,3.693-8.246,8.246-8.246h38.644c4.555,0,8.246,3.692,8.246,8.246
           C75.411,70.041,71.719,73.732,67.164,73.732z"/>
   </g>
   </svg>`,
        path: 'blog'
    },
    {
        name: 'nav.communication',
        icon: `<svg id="bold" class="icon-svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle cx="18.5" cy="14.5" r="3"/><path d="m21.25 19h-5.5c-1.517 0-2.75 1.233-2.75 2.75v1.5c0 .414.336.75.75.75h9.5c.414 0 .75-.336.75-.75v-1.5c0-1.517-1.233-2.75-2.75-2.75z"/><circle cx="4" cy="17" r="2"/><path d="m5.25 20h-2.5c-1.517 0-2.75 1.233-2.75 2.75v.5c0 .414.336.75.75.75h6.5c.414 0 .75-.336.75-.75v-.5c0-1.517-1.233-2.75-2.75-2.75z"/><path d="m12.25 0h-9.5c-1.517 0-2.75 1.233-2.75 2.75v7.5c0 1.517 1.233 2.75 2.75 2.75h4.957l3.034 2.801c.142.131.324.199.509.199.102 0 .204-.021.301-.063.272-.12.449-.389.449-.687v-2.25h.25c1.517 0 2.75-1.233 2.75-2.75v-7.5c0-1.517-1.233-2.75-2.75-2.75zm-2 8.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3.5h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>`,
        path: 'communication'
    },
    {
        name: 'nav.form',
        icon: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 912 912" class="icon-svg"
        xml:space="preserve">
   <g>
       <path d="M817.9,108.4h-28v687.901c0,45.699-37.2,82.898-82.899,82.898H423.3H197.7v25.5c0,29.201,23.7,52.9,52.9,52.9h283.6H817.8
           c29.2,0,52.899-23.699,52.899-52.9V161.3C870.7,132.1,847.1,108.4,817.9,108.4z"/>
       <path d="M423.3,849.199h283.6c29.2,0,52.9-23.699,52.9-52.898V108.4V52.9c0-29.2-23.7-52.9-52.9-52.9H423.3H329v17.5
           c0.199,1.8,0.3,3.7,0.3,5.6v115.3V168c0,41.1-33.4,74.5-74.5,74.5h-29.6H109.9c-1.5,0-3.1-0.1-4.6-0.2H86.9v554.001
           c0,29.199,23.7,52.898,52.9,52.898h58H423.3L423.3,849.199z M434,669.4H249.1c-13.8,0-25-11.201-25-25c0-13.801,11.2-25,25-25h185
           c13.8,0,25,11.199,25,25C459.1,658.199,447.8,669.4,434,669.4z M619,541.801H249.1c-13.8,0-25-11.201-25-25c0-13.801,11.2-25,25-25
           H619c13.8,0,25,11.199,25,25C644,530.6,632.8,541.801,619,541.801z M249.1,356.3H619c13.8,0,25,11.2,25,25c0,13.8-11.2,25-25,25
           H249.1c-13.8,0-25-11.2-25-25C224.1,367.5,235.3,356.3,249.1,356.3z"/>
       <path d="M109.9,212.5h144.9c0.1,0,0.3,0,0.4,0c24.2-0.2,43.8-19.8,44-44c0-0.1,0-0.3,0-0.4v-145c0-13.4-11-22.3-22.399-22.3
           c-5.5,0-11,2-15.6,6.6L94.1,174.5C80.1,188.5,90,212.5,109.9,212.5z"/>
   </g>
   </svg>`,
        path: 'form'
    }
]