<template>
    <div style="position:relative" class="flex align-center justify-center">
        <md-progress-spinner class="md-prograss_border" md-mode="determinate" :md-value="parseInt(percentage * 100) / 100"> 
        <circle cx="100" cy="100" r="50" fill="#000" />
        </md-progress-spinner>
        <span class="md-prograss_circle font-bold">{{parseInt(percentage * 100) / 100}}</span>
        <b class="md-prograss_text">{{label}}</b>
    </div>
</template>
<script>
export default {
    props:{
        percentage: {
           default: true 
        },
        label:{
           default: true 
        }
    },
}
</script>
<style scoped>
.md-progress-spinner {
  margin: 24px;
      -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari 3-8 */
    transform: rotate(-54deg);
}

</style>
