import api from '../api'
// import { merge } from 'lodash'
const state = () => ({
    explanation: {},
    explanations: [],
    explanationlist:[],
    explanationQuestionListMetaMeta:[],
    explanationlistMeta: []
});


const getters = {
    getExplanations: state =>  state.explanations,
    getExplanation: state =>  state.explanation,
    getExplanationlist: state => state.explanationlist,
    getExplanationQuestionListMetaMeta: state => state.explanationQuestionListMetaMeta,
    getExplanationlistMeta: state =>  state.explanationlistMeta,
}

const mutations = {
    
    setExplanationList(state, payload) {
        state.explanationlist = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setExplanationlistMeta(state, explanationlistMeta) {
        state.explanationlistMeta = explanationlistMeta
    },
    setExplanationQuestionList(state, payload) {
        state.explanations = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setExplanationQuestionListMeta(state, explanationQuestionListMetaMeta) {
        state.explanationQuestionListMetaMeta = explanationQuestionListMetaMeta
    },
    setExplanationShow(state, payload) {
        state.explanation = payload;
    },

}

const actions = {
    async actSaveExplanation ( store, payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/explanations`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actListExplanation ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/explanations/list${query}`)
                    .then( response => {
                        commit('setExplanationList', response.items)
                        commit('setExplanationlistMeta', response.meta);
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actQuestionListExplanation ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/explanation/question-list${query}`)
                    .then( response => {
                        commit('setExplanationQuestionList', response.items)
                        commit('setExplanationQuestionListMeta', response.meta);
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actShowExplanation ({ commit }, explanationId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/explanations/${explanationId}`)
                    .then( response => {
                        commit('setExplanationShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actChangeStatusExplanation (store, {explanationId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/explanations/change-status/${explanationId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actChangeDetaultStatusExplanation (store, {explanationId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/explanations/change-default-status/${explanationId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actUpdateExplanation (store, {explanationId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/explanations/${explanationId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }