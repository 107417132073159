<template>
  <div :style="{backgroundColor: backgroundColor}" :class="classObject">
      <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        backgroundColor: {
            type: String,
            default: () => ''
        },
        classObject: {
            type: String
        }
    },
}
</script>

<style>

</style>