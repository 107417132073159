import api from '../api';
import { includes, reject } from 'lodash';
const state = () => ({
    
    cartQuestions: [],
    practiceTestList: [],
    sectionWiseTests: [],
    sectionQuestionTypes:[],
    QuizQuestion:[],
    questions: [],
    QuizListMeta: []
});


const getters = {
    
    getCartQuestions: state => state.cartQuestions,
    getPracticeTestList: state => {
        return state.practiceTestList.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    getSectionWiseTests: state => state.sectionWiseTests.map(item => ({
              id: item.id,
              name: item.title
            }
    )),
    getSectionQuestionTypes: state => state.sectionQuestionTypes.map(item => ({
        id: item.id,
        name: item.title
    }
    )),
    getPTEPracticeQuestion: state => state.QuizQuestion.map(item => ({
        id: item.id,
        name: item.title,
        Quiz_id: item.q_s_id,
        total_quiz_no: item.quizzes.length,
        template_type: item.template_type,
        t_id: item.t_id,
        created_at: item.created_at,
        created_by: item.created_by,
        status: item.active
    }
    )),
    getPTEPracticeQuestionDetails: state => state.QuizQuestion.map(item => ({
        id: item.id,
        questions: item.questions
    }
    )),
    getQuizListMeta: state => state.QuizListMeta,
    
}

const mutations = {
    setCartQuestions(state, quiz) {
        if(quiz && !includes(state.cartQuestions, quiz)) { // need to update with object
            try {
                const index = state.cartQuestions.findIndex(cq => cq.id === quiz.id);
                if(index > -1) {
                    return;
                }
                console.log('index',index)
            } catch(e) {
                console.log(e);
            }
            state.cartQuestions.push(quiz);
        }
    },
    setCartClear: state => state.cartQuestions = [],
    setRemoveCartQuestion: (state, quiz) => {
        state.cartQuestions = reject(state.cartQuestions, quiz)
    },
    setPracticeTestList: (state, payload) => state.practiceTestList = payload,
    setSectionWiseTests: (state, payload) => state.sectionWiseTests = payload,
    setSectionQuestionTypes:(state, payload) => state.sectionQuestionTypes = payload,
    setQuizQuestion:(state, payload) => state.QuizQuestion = payload,
    setQuizListMeta(state, QuizListMeta) {
        state.QuizListMeta = QuizListMeta
    },
}

const actions = {
    async actTestCreationSave ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/quiz-set', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actTestStepUpdate ( store , {testId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/quiz-set/${testId}`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    
    async actQuizPreview ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/quiz-set/quizzes`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPracticeTestList ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-set/list${query}`)
                    .then( ({ items }) => {
                        commit('setPracticeTestList',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actSectionWiseTests ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests${query}`)
                    .then( ({ items }) => {
                        commit('setSectionWiseTests', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actSectionQuestionTypes ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types${query}`)
                    .then( ({ items }) => {
                        commit('setSectionQuestionTypes', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actQuizQuestion ({ commit }, query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-set${query}`)
                    .then(response => {
                        commit('setQuizQuestion', response.items);
                        commit('setQuizListMeta', response.meta);
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestSections ()  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/section-wise-count`)
                    .then( ({ items }) => {
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestDetails (store, testId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/${testId}`)
                    .then( ({ items }) => {
                        resolve(items)
                        
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actTestQuestionsIds (store, testId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-set/review/quizzes/${testId}`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestPublish ( store , {testId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/quiz-set/${testId}/publish`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actTestStatusUpdate (store, {testId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/tests/change-status/${testId}`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }