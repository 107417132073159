<template>
<div>
    <div class="md-layout">
        <div class="md-layout-item md-size-100 mt-5" v-if="isAddressOneShow">
        <InputFieldComponent
            label="Full Address"
            label-class="text-sm"
            v-model.trim="form.address_1"
            
        />
        </div>
        <div class="md-layout-item md-size-100 mt-4" v-if="isAddressTwoShow">
        <InputFieldComponent
            label-class="text-sm"
            v-model.trim="form.address_2"
            
        />
        </div>
        
        <div v-if="!orderCountries" class="md-layout-item md-size-50  mt-5">
       
        <SelectComponent
            :items="postalCodes"
            :requiredLabel="true"
            label="Postal Code"
            class="w-full mb-2"
            return-type="object"
            :selected="form.postal_code_id"
            @getObject="setPostalCode"
            v-model.trim="$v.form.postal_code_id.$model"
            :message="!$v.form.postal_code_id.required && $v.form.postal_code_id.$dirty ? 'Field is required' : null"
        />
        </div>
        <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            :requiredLabel="true"
            label="Postal code"
            label-class="text-sm"
            v-model.trim="$v.form.postal_code_id.$model"
            :message="!$v.form.postal_code_id.required && $v.form.postal_code_id.$dirty ? 'Field is required' : null"
            />
        </div>
    </div>
    <div class="md-layout mt-5">
        <div class="md-layout-item md-size-50 ">
        <InputFieldComponent
            :requiredLabel="true"
            label="State"
            label-class="text-sm"
            v-model.trim="$v.form.state.$model"
            :message="!$v.form.state.required && $v.form.state.$dirty ? 'Field is required' : null"
            />
        </div>
        <div class="md-layout-item md-size-50">

        <InputFieldComponent
            :requiredLabel="true"
            label="City"
            label-class="text-sm"
            v-model.trim="$v.form.city.$model"
            :message="!$v.form.city.required && $v.form.city.$dirty ? 'Field is required' : null"
            />
        </div>
    </div>

    <div class="md-layout-item md-size-100 mt-5">
        <SelectComponent
        :items="countries"
        label="Country"
        :requiredLabel="true"
        class="w-full mb-2"
        :selected="form.country_id"
        v-model="$v.form.country_id.$model"
        :message="!$v.form.country_id.required && $v.form.country_id.$dirty ? 'Field is required' : null"
        @input="getCountryInputChange"
        @getObject="getCountryId"
    />
    </div>
    <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
    <SelectComponent
        :items="otherCountries"
        label="Your Country"
        class="w-full mb-2"
        @input="getOtherCountryInputChange"
        v-model.trim="form.your_country_id"
        />
    </div>
</div>
</template>

<script>

import {
  InputFieldComponent,SelectComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import {mapActions} from 'vuex';

export default {

   props: {
    data: {
      type: Object,
      default: () => null
    },
    isAddressOneShow: {
      type: Boolean,
      default: () => true
    },
    isAddressTwoShow: {
      type: Boolean,
      default: () => true
    },
  },
  components: {
    InputFieldComponent,
    SelectComponent
  },
  data() {
    return {
        orderCountries: false,
        countries: [],
        postalCodes: [],
        otherCountries: [],
        form: {
            address_1: "",
            address_2: '',
            postal_code_id:'',
            country_id: "",
            state: '',
            city: '', 
            country: '',
            your_country: '',
            your_country_id: '',
            postal_code: '',
        }
    };
  },
  methods: {

    ...mapActions({
      actCountryList: "registration/actCountryList",
      actPostalCodesByCountry: "registration/actPostalCodesByCountry",
      actPostalCodes: "registration/actPostalCodes",
    }),

    clearAll(){
      this.form = {
            address_1: "",
            address_2: '',
            postal_code_id:'',
            country_id: "",
            state: '',
            city: '', 
            country: '',
            your_country: '',
            your_country_id: '',
            postal_code: '',
        }
    },

    setPostalCode(postalCode) {
      
      this.form.postal_code_id = postalCode.id;
      this.form.postal_code = postalCode.name;
      this.form.state = postalCode.state;
      this.form.city = postalCode.city;  
    },
    checkValidation(){
        this.$v.$touch();
        if(this.$v.$invalid) return this.$v.$invalid;
       
    },
    getCountryName() {
      let country =  this.countries.filter(country => {
        if(country.id === this.form.country_id) return country;
      });
      if(typeof country !== "undefined" && country) return country[0];
    },
    getCountryId(countryId) {
      this.getPostalCodes(countryId);
    },

    getPostalCodes(countryId='') {

    let selectedPostCodeId = '';
    let country_id = '';
    
    if(this.data.postal_code_id) selectedPostCodeId = this.data.postal_code_id;
    if(this.data.country_id)   country_id = this.data.country_id;
    if(!countryId) countryId = country_id;

      if(!countryId && selectedPostCodeId){
        this.actPostalCodes().then(({items}) => {
            let postalCode = items.filter(item => item.id ==  selectedPostCodeId);
            
            if(postalCode.length > 0){
                  countryId = postalCode[0].country_list_id;
                  this.form.country_id = postalCode[0].country_list_id;
                  this.form.postal_code_id = postalCode[0].id;
                  this.form.postal_code = postalCode[0].name;
                  this.form.state = postalCode[0].state;
                  this.form.city = postalCode[0].city;
              }else{
                this.postalCodes = items;
                postalCode = items.slice(0, 1).shift();
                if(typeof postalCode !== "undefined" && postalCode){
                    this.form.country_id = postalCode.country_list_id;
                    this.form.postal_code_id = postalCode.id;
                    this.form.postal_code = postalCode.name;
                    this.form.state = postalCode.state;
                    this.form.city = postalCode.city;
                }
              }
        });  
      }else{
       
        this.actPostalCodesByCountry(countryId)
          .then(({items}) => {
           
            this.postalCodes = items;
           
            if(selectedPostCodeId){

                let postalCode = items.filter(item => item.id ==  selectedPostCodeId);
               
                if(postalCode){
                    this.form.country_id = postalCode[0].country_list_id;
                    this.form.postal_code_id = postalCode[0].id;
                    this.form.postal_code = postalCode[0].name;
                    this.form.state = postalCode[0].state;
                    this.form.city = postalCode[0].city;
                }

               }else{
          
                var postalCode = items.slice(0, 1).shift();
                if(typeof postalCode !== "undefined" && postalCode){
                    this.form.country_id = postalCode.country_list_id;
                    this.form.postal_code_id = postalCode.id;
                    this.form.postal_code = postalCode.name;
                    this.form.state = postalCode.state;
                    this.form.city = postalCode.city;
                }else{
                    this.form.postal_code_id = "";
                    this.form.postal_code = "";
                    this.form.state = "";
                    this.form.city = "";
                }
            }
          });
        }
    },

    getCountryInputChange(countryId) {
     
      if (countryId == 'others') {
        this.form.country = countryId;
        this.form.country_id = countryId;
        this.form.city = '';
        this.form.state = '';
        this.form.postal_code_id = '';
        this.orderCountries = true;

        //load first country details
        let otherCountry = this.otherCountries.slice(0, 1).shift();
        if(typeof otherCountry !== "undefined" && otherCountry) {
          this.form.your_country = otherCountry.name;
          this.form.country_id = otherCountry.id;
          this.getPostalCodes(otherCountry.id);
        }
      } else {
        this.getPostalCodes(countryId);
        this.orderCountries = false;
        let countryDerails = this.getCountryName();
        this.form.country_id = countryDerails.id;
        this.form.country = countryDerails.name;
      }
    },

    getOtherCountryName() {
      let otherCountry =  this.otherCountries.filter(otherCountry => {
        if(otherCountry.id === this.form.your_country) return otherCountry;
      });
      if(typeof otherCountry !== "undefined" && otherCountry) return otherCountry[0];
    },
    
    getOtherCountryInputChange(otherCountryId){
      this.form.your_country = otherCountryId;
      this.getPostalCodes(otherCountryId);
      let otherCountryDerails = this.getOtherCountryName();
      this.form.your_country = otherCountryDerails.name;
    },

  },

  created() {

    
    if(this.data.address_1) this.form.address_1 = this.data.address_1;
    if(this.data.address_2) this.form.address_2 = this.data.address_2;

    if(this.data.postal_code_id) this.form.postal_code_id = this.data.postal_code_id;
    if(this.data.your_country) this.form.your_country = this.data.your_country;
    if(this.data.country_id) this.form.country_id = this.data.country_id;
    if(this.data.your_country_id) this.form.your_country_id = this.data.your_country_id;
    if(this.data.country) this.form.country = this.data.country;

    this.actCountryList().then(({main, others}) => {
      this.countries = main;
      this.otherCountries = others;
      this.countries.push({
        name: 'OTHER',
        id: 'others'
      })

      this.getPostalCodes();
    });
    console.log(this.form.postal_code_id);
    
  },
  validations: {
    form: {
     
      // address_1: {required},
      country_id: {required},
      state: {required},
      city: {required},
      postal_code_id: {required},
    }
  }
}
</script>