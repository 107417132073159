import api from '../api';
import { find, filter } from 'lodash';
import cms from '@/data/cms';
//import data from '@/data/pte-quiz/data';
// initial state
const state = () => ({
    questionTypes: [],
    quiz: [],
    selectedQuestionType: {},
    typeWiseQuestion: [],
    sectionWiseQuestion: [],
    sectionWiseQuiz: [],
    quizWiseQuestion: [],
    materials: [],
    typeWiseQuestions: [],
    singleQuestion: {},
    singleQuiz: {},
    questionHeader: {},
    PTEPracticeQuestionDetails: [],
    QuizWiseQuestionMeta: [],
    SectionWiseQuestionMeta: [],
    selectedQuizTypeId: "",
    selectedQuestionTypeId: "",
    isLoading: false, 
});

// getters
const getters = {
    getQuestionTypes: state => {
        return state.questionTypes.map(item => {
            return {
                id: item.id,
                name: item.title,
                slug: item.slug
            }
        });
    },
    getQuiz: state => {
        return state.quiz.map(item => {
            return item
        });
    },
    getSectionWiseQuestion: state => {
        return state.sectionWiseQuestion.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    getSectionWiseQuiz: state => {
    return state.sectionWiseQuiz.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    getQuizWiseQuestion: state => {

    return state.quizWiseQuestion.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },

    getMaterials: state => {

    return state.materials.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },

    getTypeWiseQuestions: state => state.typeWiseQuestions,
    getSelectedQuestionType: state => state.selectedQuestionType,
    getSelectedQuiz: state => state.SelectedQuiz,
    getSectionWiseQuestionMeta: state => state.SectionWiseQuestionMeta,
    getSelectedQuizTypeId: state => state.selectedQuizTypeId,
    getSelectedQuestionTypeId: state => state.selectedQuestionTypeId,
    getSingleQuestion: state => state.singleQuestion,
    getSingleQuiz: state => state.singleQuiz,
    PTEPracticeQuestionDetails: state => state.PTEPracticeQuestionDetails,
    getQuizWiseQuestionMeta: state => state.QuizWiseQuestionMeta,
    getIsLoading: state => state.isLoading,
    //PTEPracticeQuestionDetails:(state, payload) => state.PTEPracticeQuestionDetails = payload,
}
// mutations
const mutations = {
    setQuestionTypes(state, questionTypes) {
        state.questionTypes = questionTypes
    },
    setQuiz(state, setQuiz) {
        state.quiz = setQuiz
    },
    setSelectedQuiz(state, type) { 
        // let { component } = find(data.questionTypes, ['component',  type.component]);
        // type.component = component;
        state.selectedQuiz = type;
    },
    setSelectedQuizTypeId(state, id) { 
        state.selectedQuizTypeId = id;
    },
    setSelectedQuestionTypeId(state, id) {
        state.selectedQuestionTypeId = id;
    },
    setSelectedQuestionType(state, type) {
        let { component } = find(cms.questionTypes, ['slug',  type.slug]);
        type.component = component;
        state.selectedQuestionType = type;
    },

    setSectionWiseQuestion(state, questions) {
        state.sectionWiseQuestion = questions;
    },
    setSectionWiseQuestionMeta(state, SectionWiseQuestionMeta) {
        state.SectionWiseQuestionMeta = SectionWiseQuestionMeta
    },
    
    setSectionWiseQuiz(state, Quizs) {
        state.sectionWiseQuiz = Quizs;
    },
    setQuizWiseQuestion(state, questions) {
        state.quizWiseQuestion = questions;
    },
    setQuizWiseQuestionMeta(state, QuizWiseQuestionMeta) {
        state.QuizWiseQuestionMeta = QuizWiseQuestionMeta
    },
    setMaterials(state, questions) {
        state.materials = questions;
    },
    setTypeWiseQuestions (state, questions) {
        state.typeWiseQuestions = questions;
    },
    setRemoveDeletedItem(state, questionId) {
        state.sectionWiseQuestion = filter(state.sectionWiseQuestion, (item) => item.id !== questionId)
    },
    setRemoveDeletedQuizItem(state, quizId) {
        state.quizWiseQuestion = filter(state.quizWiseQuestion, (item) => item.id !== quizId)
    },
    setPTEPracticeQuestionDetails(state, question) {
        state.PTEPracticeQuestionDetails = question;
    },
    setQuestionDetails(state, question) {
        state.singleQuestion = question;
    },
    setQuizDetails(state, quiz) {
        let index = state.quizWiseQuestion.findIndex((qz) => qz.id === quiz.id);
        if(index > -1) {
            quiz.prev = index > 0 ? state.quizWiseQuestion[index - 1].id : null;
            quiz.next = index + 1 < state.quizWiseQuestion.length ? state.quizWiseQuestion[index + 1].id : null;
        }
        
        state.singleQuiz = quiz;
    },
    setSelectedQuestion(state, payload) {
       let question =  find(state.sectionWiseQuestion, ['id', payload.id])
       question.isSelected = true;
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading; 
    }
}

const actions = {
    /*
        Section wise questions [section means 4 main section or module {Reading, Writing, Speaking, Listening}]
    */
    async actGetQuestionTypes ({ commit } , query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types${query}`)
                    .then( ({ items }) => {
                        commit('setQuestionTypes', items);
                        commit('setSelectedQuestionType', items[0]);
                        resolve(items[0]);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actGetQuiz ({ commit } , query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-types${query}`)
                    .then( ({items}) => {
                        commit('setQuiz', items);
                        commit('setSelectedQuiz', items[0]);
                        resolve(items[0]);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    
    async actQuizWiseQuestion ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz${query}`)
                    .then( response => {
                        commit('setQuizWiseQuestion',response.items);
                        commit('setQuizWiseQuestionMeta', response.meta);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /*
        quiz save
    */
    async actSubmitQuiz ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/quiz', payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /*
        Quiz update
    */
        async actQuizUpdate (store,{quizId, payload})  {
            return new Promise((resolve, reject) => {
                console.log(payload, 'payload')
                try {
                    api('put', `api/v1/quiz/${quizId}`, payload)
                        .then( response => {
                            resolve(response);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },

    async actMaterials ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/materials${query}`)
                    .then(( {items} ) => {
                        commit('setMaterials',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    /*
        Question type details by type id
    */
    async actQuestionTypeDetails (store , typeId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/question-types/${typeId}`)
                    .then( ({ items }) => {
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
       /*
        Quiz type details by type id
    */
        async actQuizTypeDetails (store , typeId)  {
            return new Promise((resolve, reject) => {
                try {
                    api('get', `api/v1/quiz-type/${typeId}`)
                        .then( ({ items }) => {
                            resolve(items);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },
    /*
        questions save
    */
    async actSubmitQuestion ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/questions', payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Question listing api, query string for filtering
    */
    async actSectionWiseQuestion ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            try {
                api('get', `api/v1/questions/list${query}`)
                    .then( response => {
                        commit('setSectionWiseQuestion',response.items);
                        commit('setSectionWiseQuestionMeta', response.meta);
                        commit('setLoading', false);
                        resolve(response);
                    })
                    .catch(error => 
                        reject(error),
                        commit('setLoading', false)
                    )
                } catch (error) {
                    reject(error);
                    commit('setLoading', false)
                }
        })
    },
    /*
        Question listing api, query string for filtering
    */
        async actSectionWiseQuiz ({ commit } , query)  {
            return new Promise((resolve, reject) => {
                try {
                    api('get', `api/v1/quiz${query}`)
                        .then(({ items }) => {
                            commit('setSectionWiseQuiz',items);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },

    /*
        Type wise questions [type means 20 type of question]
    */
    async actTypeWiseQuestions ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions${query}`)
                    .then(({ items }) => {
                        commit('setTypeWiseQuestions',items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    /*
        Question delete
    */
    async actDeleteQuestion ({ commit } , questionId)  {
        return new Promise((resolve, reject) => {
            try {
                api('delete', `api/v1/questions/${questionId}`)
                    .then(({ message }) => {
                        commit('setRemoveDeletedItem', questionId)
                        resolve(message);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /*
        Quiz delete
    */
        async actDeleteQuiz ({ commit } , quizId)  {
            return new Promise((resolve, reject) => {
                try {
                    api('delete', `api/v1/quiz/${quizId}`)
                        .then(({ message }) => {
                            commit('setRemoveDeletedQuizItem', quizId)
                            resolve(message);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },
    /*
        Question update
    */
    async actUpdateQuestion (store, {questionId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/questions/${questionId}`, payload)
                    .then(({ message }) => {
                        resolve(message);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    /*
        Question details
    */
    async actQuestionDetails ({ commit } , questionId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions/${questionId}`)
                    .then(({ items }) => {
                        commit('setQuestionDetails', items);
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /*
        Quiz details
    */
        async actQuizDetails ({ commit } , quizId)  {
            return new Promise((resolve, reject) => {
                try {
                    api('get', `api/v1/quiz/${quizId}`)
                        .then(({ items }) => {
                            commit('setQuizDetails', items);
                            resolve(items);
                        })
                        .catch(error => reject(error))
                    } catch (error) {
                        reject(error);
                    }
            })
        },
    /*
        PTE practice Question all
    */
    async actPTEPracticeQuestionDetails ({ commit } , query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1//tests/pte-practice-list${query}`)
                    .then(({ items }) => {
                        commit('setPTEPracticeQuestionDetails', items);
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }