<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-5">
      <div @click="toggleSidebar" class="icon hidden-xs">
        <md-icon> {{ arrowIcon }} </md-icon>
      </div>
      <div class="mobile-menu">
        <span @click="tggleDrawer"
        ><md-icon class="text-white">menu</md-icon>
        </span>
      </div>
    </div>
    <div class="md-layout-item flex justify-end">
      <ul class="navigation">
        <li v-for="(item, index) in items" :key="index">
          <router-link :to="{name: item.path}">{{ $t(item.name) }}</router-link>
        </li>
      </ul>
    </div>
    <div class="md-layout-item flex justify-end md-size-15">
      <ul class="user-area">
        <li><md-icon>search</md-icon></li>
        <li @click="onToggleNotification"><md-icon>notifications_none</md-icon></li>
        <li @click="toggleMenuCard = !toggleMenuCard" class="pointer"><img width="25" src="/images/user.png" alt="" /></li>
        <li @click="toggleMenuCard = !toggleMenuCard">
          <md-icon>arrow_drop_down</md-icon>
        </li>
        <md-menu
          md-size="huge"
          :md-offset-x="-290"
          :md-offset-y="10"
          :md-active.sync="toggleMenuCard"
        >
          <md-menu-content class="rounded">
            <md-menu-item>{{ $t("menu.myProfile") }}</md-menu-item>
            <md-divider></md-divider>
            <md-menu-item>{{ $t("menu.settings") }}</md-menu-item>
            <md-divider></md-divider>
            <md-menu-item>{{ $t("menu.help") }}</md-menu-item>
            <md-divider></md-divider>
            <md-menu-item @click="onLogout">
              {{ $t("menu.logout") }}</md-menu-item
            >
          </md-menu-content>
        </md-menu>
        
        <md-menu
          v-if="notifications.length > 0"
          :md-offset-x="-290"
          :md-offset-y="10"
          :md-active.sync="toggleNotification"
        >
          <md-menu-content class="rounded">
            <template v-for="(item, index) in notifications">
              <md-list-item :key="'notification'+index">
                <md-icon class="mr-3 bg-victoria p-2 text-white rounded-full w-10 h-10">notifications</md-icon>
                <div class="md-list-item-text">
                  <p>New mock test created by <span class="text-victoria">kazi shahin</span></p>
                  <span class="text-italic text-sm opacity-75">2 min ago</span>
                </div>
              </md-list-item>
              <md-divider :key="'notification-divider'+index" class="md-inset"></md-divider>
            </template>
    
          </md-menu-content>
        </md-menu>

        <!-- <select class="lang" v-model="$i18n.locale">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{
            lang
          }}</option>
        </select> -->
      </ul>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    notifications: {
      type: Array,
      default:() => [
        1,2,3,4
      ]
    }
  },
  data() {
    return {
      langs: ["bn", "en"],
      toggleMenuCard: false,
      toggleNotification: false ,
      handleclass: "default_height",  
      handleclasses: ''
    };
  },
  computed: {
    ...mapState({
     sidebar : state => state.sidebar
    }),
    arrowIcon() {
      return this.sidebar === 'default' ? 'keyboard_backspace' : 'arrow_forward';
    }
  },
  methods: {
    ...mapMutations({
      setSidebar : 'setSidebar'
    }),
    changeLang() {
      this.$i18n.locale = "en";
    },
     tggleDrawer() {
      this.$store.commit("toggleDrawer", !this.$store.state.showNavigation);
    },
    toggleSidebar() {
      let side = 'default'
      if(this.sidebar === 'default') {
        side = 'MiniSidbarLayout'
      }
      this.setSidebar(side);
       let x = document.querySelector(".student")
      this.sidebar === 'default' ? x.classList.remove(this.handleclass) : x.classList.add(this.handleclass);
     
    },
    onLogout() {
      localStorage.clear();
      // localStorage.removeItem("user_id");
      // localStorage.removeItem("user_type");
      // localStorage.removeItem("organization_id");
      this.$router.push({ name: "login" });
    },
    onToggleNotification() {
      this.$emit('on-get-notification');
      this.toggleNotification = !this.toggleNotification
    }
  },
};
</script>

<style lang="scss">
@use '@/assets/sass/components/top-menu';
@media only screen and (max-width: 1400px){
  ul.navigation li a {
      font-size: 0.95rem;
  }
}
@media only screen and (max-width: 1366px){
  ul.navigation li a {
      font-size: 0.92rem;
  }
  ul.navigation li {
    margin: 0 16px;
  }
}
@media only screen and (max-width: 1280px){
  ul.navigation li {
    margin: 0 10px;
  }
}
</style>
