<template>
  <div class="outline-gray-400 p-1 rounded center">
      <md-icon
        class="p-4 text-white m-0 rounded text-2xl"
        :class="[ (next <= 1) ? 'bg-periwinkle-gray': 'pointer', bgColor]"
        @click.native="onPrevious"
      >
        keyboard_arrow_left
      </md-icon>
        <span class="mx-4 text-base font-semibold">{{ start }} - {{ end }} Of  {{ total }} </span>
      <md-icon @click.native="onNext" class="p-4 text-white m-0 rounded text-2xl"
      :class="[ (prev <= next) ? 'bg-periwinkle-gray': 'pointer', bgColor]"
      >
        keyboard_arrow_right
      </md-icon>
  </div>
</template>

<script>
export default {
    props: {
      start: {
        type: Number,
        default: () => 1
      },
      end: {
        type: Number,
        default: () => 100
      },
      next: {
        type: Number,
        default: () => 1
      },
      prev: {
        type: Number,
        default: () => 100
      },
      limit: {
        type: Number,
        default: () => 20
      },
      total: {
        type: Number
      },
      bgColor: {
        type: String,
        default: 'bg-victoria'
      }
    },
    methods: {
      onPrevious() {
        if(this.next > 1) {
           this.$emit('on-start', this.next-1);
        }
      },
      onNext() {
        if(this.prev > this.next) {
          this.$emit('on-end', this.next+1);
        }
      }
    }
};
</script>

