import { filter } from 'lodash';
export const setJobList = (state, jobList) => {
    
    state.jobList = jobList;
   
}
export const setJobMeta = (state, jobMeta) => {
    state.jobMeta = jobMeta;
}
export const setSelectedJobList = (state, jobList) => {
    state.selectedJobList = jobList
}

export const setRemoveDeletedItem = (state, jobId) => {
    state.jobList = filter(state.jobList, (item) => item.id !== jobId);
}