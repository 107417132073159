export const coachingCenter = [
    {
        path: '/coaching-center',
        name: 'CoachingCenter',
        component: () => import(
            /* webpackChunkName: "coaching-center" */
            '../views/coaching-center/View.vue'
        ),
        meta: {layout: 'coaching-center'},
        redirect: {name: 'coachingCenterBasicInfo'},
        children: [
            {
                path: 'basic-info',
                name: 'coachingCenterBasicInfo',
                component: () => import(
                    /*webpackChunkName: "basic-information" */
                    '../views/coaching-center/BasicInfo.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'address',
                name: 'coachingCenterAddress',
                component: () => import(
                    /*webpackChunkName: "address" */
                    '../views/coaching-center/Address.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'business-info',
                name: 'coachingCenterBusinessInfo',
                component: () => import(
                    /*webpackChunkName: "address" */
                    '../views/coaching-center/BusinessInfo.vue'
                ),
                meta: {layout: 'coaching-center'}
            }
            ,
            {
                path: 'package',
                name: 'coachingCenterPackage',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/Package.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'contract',
                name: 'coachingCenterContract',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/Contract.vue'
                ),
                meta: {layout: 'coaching-center'}
            }
            ,
            {
                path: 'payment',
                name: 'coachingCenterPayment',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/Payment.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'portal-access',
                name: 'coachingCenterPortalAccess',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/PortalAccess.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'portal-admin',
                name: 'coachingCenterPortalAdmin',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/PortalAdmin.vue'
                ),
                meta: {layout: 'coaching-center'}
            },
            {
                path: 'review',
                name: 'coachingCenterReview',
                component: () => import(
                    /*webpackChunkName: "package" */
                    '../views/coaching-center/Review.vue'
                ),
                meta: {layout: 'coaching-center'}
            }
        ]
    },
]
