import api from '../../api';
// import router from '@/router'

export const actGetOrganizationList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/dummy/organizations${query}`, null, 'public')
                .then( ({ items, meta }) => {
                    
                    commit('setOrganizationList', items);
                    commit('setOrganizationMeta', meta);
                    commit('setSelectedOrganizationList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actOrganizationSave = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/dummy/organizations`, payload, 'public')
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actCheckAvailableSubdomainOrEmail = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/dummy/check-available-subdomain-or-email`, payload, 'public')
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdateOrganization = ( store, {OrganizationId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/dummy/organizations/${OrganizationId}`, payload, 'public')
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actDeleteOrganization = ({ commit } , OrganizationId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/dummy/organizations/${OrganizationId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', OrganizationId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetOrganizationByOrganizationId = (store, OrganizationId = '') => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/dummy/organizations/${OrganizationId}`, null, 'public')
                .then( (response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}