import api from '../api';

const state = () => ({
    moreClasses: [],
    featuredClasses: [],
    summary: null,
    suggestions: [],
    classesMeta: null,
    classDetail: null,
    selectedModuleChapterSummary: [],
    selectedModule: null,
    selectedChapterDetail: null,
    allModules: [],
    selectedChapter: null,
    selectedSideNavModule: null,
    isLoading: false,
});

const getters = {
    getLoading: state => state.isLoading,
    getMoreClasses: state => state.moreClasses,
    getFeaturedClasses: state => state.featuredClasses,
    getSummary: state => state.summary,
    getSuggestions: state => state.suggestions,
    getClassesMeta: state => state.classesMeta,
    getClassDetail: state => state.classDetail,
    getSelectedModule: state => state.selectedModule,
    getSelectedChapterDetail: state => state.selectedChapterDetail,

    getAllModules: state => state.allModules,
    getSelectedChapter: state => state.selectedChapter,
    getSelectedSideNavModule: state => state.selectedSideNavModule,
};

const mutations = {
    setLoading(state, payload) {
        state.isLoading = payload;
    },
    setMoreClasses(state, payload) {
        state.moreClasses = payload;
    },
    setFeaturedClasses(state, payload) {
        state.featuredClasses = payload;
    },
    setSummary(state, payload) {
        state.summary = payload;
    },
    setSuggestions(state, payload) {
        state.suggestions = payload;
    },
    setClassListMeta(state, payload) {
        state.classesMeta = payload;
    },
    setClassDetail(state, payload) {
        state.classDetail = payload;
    },
    setSelectedModuleChapterSummary(state, payload) {
        if(state.classDetail && state.classDetail.modules) {
            let moduleIndex = state.classDetail.modules.findIndex((moduleObj) => moduleObj.id === payload.moduleId);

            if(moduleIndex > -1) {
                if(!state.classDetail.modules[moduleIndex].chapters) {
                    state.classDetail.modules[moduleIndex].chapters = [];
                    state.classDetail.modules[moduleIndex].chapters = payload.chapters;

                    // state.allModules = state.classDetail.modules;
                }else {
                    payload.chapters.forEach((chapter) => {
                        let chapterIndex = state.classDetail.modules[moduleIndex].chapters.findIndex((chp) => chp.id === chapter.id);
                        
                        if(chapterIndex < 0) {
                            state.classDetail.modules[moduleIndex].chapters.push(chapter);
                        }
                    });

                    // state.allModules = state.classDetail.modules;
                }

                // state.classDetail.modules[moduleIndex].chapters = [];
                // state.classDetail.modules[moduleIndex].chapters = payload.chapters;
            }
        }
    },
    setSelectedModuleChapterDetail(state, payload) {
        if(state.classDetail && state.classDetail.modules) {
            state.selectedChapterDetail = payload.chapter;

            let moduleIndex = state.classDetail.modules.findIndex((moduleObj) => moduleObj.id === payload.moduleId);

            if(moduleIndex > -1) {
                if(!state.classDetail.modules[moduleIndex].chapters) {
                    state.classDetail.modules[moduleIndex].chapters = [];
                    state.classDetail.modules[moduleIndex].chapters.push(payload.chapter);
                } else {
                    let chapterIndex = state.classDetail.modules[moduleIndex].chapters.findIndex((chp) => chp.id === payload.chapter.id);
                    
                    if(chapterIndex > -1) {
                        state.classDetail.modules[moduleIndex].chapters[chapterIndex] = payload.chapter;
                    } else {
                        state.classDetail.modules[moduleIndex].chapters.push(payload.chapter);
                    }
                }


                state.selectedModule = state.classDetail.modules[moduleIndex];
                state.selectedSideNavModule = state.classDetail.modules[moduleIndex];
                // state.allModules = state.classDetail.modules;

            }
        }
    },

    //nav special
    setSideNavModules(state, payload) {
        state.allModules = payload;
    },
    setSideNavSelectedSideNavModule(state, payload) {
        state.selectedSideNavModule = payload;
    },
    setSelectedChapter(state, payload) {
        state.selectedChapter = payload;
    },
};

const actions = {
    async fethAllClass ({ commit } , query)  {
        commit('setFeaturedClasses',[]);
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            try {
                api('get', `/api/v1/oc${query ? query : ''}`)
                    .then( (response) => {
                        commit('setMoreClasses',response?.more_classes?.items);
                        commit('setFeaturedClasses',response?.featured_classes?.items);
                        commit('setSummary',response?.summary);
                        commit('setSuggestions', response?.suggestions);
                        commit('setClassListMeta',response?.more_classes.meta);
                        resolve(response)
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
                } catch (error) {
                    reject(error);
                }
        })
    },

    async fetchModuleDetailsByClassId ({ commit } , id)  {
        commit('setLoading', true);
        return new Promise((resolve, reject) => {
            try {
                api('get', `/api/v1/oc/${id}`)
                    .then( ({ items }) => {
                        commit('setClassDetail',items);
                        resolve(items);
                    })
                    .catch(error => reject(error))
                    .finally(() => {
                        commit('setLoading', false);
                    })
                } catch (error) {
                    reject(error);
                }
        })
    },

    async fetchModulesForSideNavByClassId ({ commit } , id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `/api/v1/oc/${id}/chapter-list`)
                    .then( ({ items }) => {
                        commit('setSideNavModules',items.modules);
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async fetchChapterSummaryByClassIdAndModuleId ({ commit } , idObj)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `/api/v1/oc/${idObj.classId}/${idObj.moduleId}/chapters`)
                    .then( ({ items }) => {
                        let payload = {
                            chapters: items,
                            moduleId: idObj.moduleId
                        }
                        commit('setSelectedModuleChapterSummary',payload);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async fetchChapterDetailByClassIdAndModuleIdAndChapterId ({ commit } , idObj)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `/api/v1/oc/${idObj.classId}/${idObj.moduleId}/chapters/${idObj.chapterId}`)
                    .then( ({ items }) => {
                        items.parts.forEach((item) => {
                            item.is_completed = item.is_completed ? true : false;
                        });
                        let payload = {
                            chapter: items,
                            moduleId: idObj.moduleId,
                        }
                        commit('setSelectedModuleChapterDetail',payload);
                        commit('setSelectedChapter', items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async onMarkAsComplete(store, payload){
        return new Promise((resolve, reject) => {
            try {
                api('post', `/api/v1/oc/mark-as-complete`, payload)
                    .then( (response) => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async onBulkMarkAsComplete(store, payload){
        return new Promise((resolve, reject) => {
            try {
                api('post', `/api/v1/oc/mark-as-complete-bulk`, payload)
                    .then( (response) => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async onSetBookMark(store, payload){
        return new Promise((resolve, reject) => {
            try {
                api('post', `/api/v1/oc/bookmark`, payload)
                    .then( (response) => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    removeAllData({commit}) {
        commit('setMoreClasses', []);
        commit('setFeaturedClasses', []);
        commit('setSummary', null);
        commit('setSuggestions', []);
        commit('setClassListMeta', null);
        commit('setClassDetail', null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
