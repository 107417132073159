<template>
  <div>
    <md-chips v-model="value"
              @input="$emit('input', $event.target.value)"
              :md-limit="3"
              md-placeholder="Add Tags...">
      <div class="md-helper-text">Up to 3 Tags</div>
    </md-chips>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Boolean],
      default: () => "Editor label goes to here",
    },
    message: {
      type: String,
      default: () => null
    },
    italic: {
      type: String,
    },
    opacityClass: {
      type: String,
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    labelClass: {
      type: String,
      default: () => 'text-uppercase text-victoria mb-3'
    },
    value: {
      type: Array
    }
  },
  data: () => ({
    currentProject: 'Vue Material',

  }),
  computed: {
    editorData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {

  }
}

</script>

<style scoped>
  small {
    font-weight: 500;
  }
</style>