<template>
  <div>
    <label v-if="label"
      class="text-uppercase mb-2 inline-block mt-6"
      :class="labelClass"
      >
    {{ label }}
    </label>
    <textarea
      :class="textAreaClass"
      class="outline-gray-400 rounded p-3"
      :rows="rows"
      :cols="cols"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :placeholder="placeholder"
    ></textarea>
    <span
      id="scrollTop"
      class="text-xs text-danger"
      v-if="message">
        {{ message }}
      </span>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: () => null
        },
        value: {
          type: String
        },
        labelClass: {
          type: String,
          default: () => 'text-xl font-bold'
        },
        placeholder: {
          type: String,
          default: () => ''
        },
        message: {
            type: String,
            default: () => null
        },
        rows: {
          type: Number,
          default: () => 10
        },
        cols: {
          type: Number,
          default: () => 10
        },
        textAreaClass: {
          type: [String, Array],
          default: () => 'w-full'
        }
    }
};
</script>

<style></style>
