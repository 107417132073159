<template>
  <!-- <svg
      class="icon-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      xml:space="preserve"
    >
      <g>
        <path
          d="M482,121h-31V61h-60V30c0-16.569-13.431-30-30-30s-30,13.431-30,30v31h-60v180h180v-60h31c16.569,0,30-13.433,30-30   C512,134.431,498.569,121,482,121z"
        />
        <path
          d="M0,151c0,16.567,13.431,30,30,30h31v60h60v-30c0-16.569,13.431-30,30-30s30,13.431,30,30v30h60V61h-60V30   c0-16.569-13.431-30-30-30s-30,13.431-30,30v31H61v60H30C13.431,121,0,134.431,0,151z"
        />
        <path
          d="M482,331h-31v-60H271v60h30c16.569,0,30,13.431,30,30c0,16.567-13.431,30-30,30h-30v60h60v31c0,16.567,13.431,30,30,30   s30-13.433,30-30v-31h60v-60h31c16.569,0,30-13.433,30-30C512,344.431,498.569,331,482,331z"
        />
      </g>
    </svg> -->
    <div v-html="path"></div>
</template>

<script>
export default {
    props: {
        path: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>