import api from '../api'

const state = () => ({
    question: {},
    tests: [],
});


const getters = {
    getTests: state =>  state.tests,
    getQuestion: state =>  state.question,
}


const mutations = {

    setTestList(state, payload) {
        state.tests = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },

    setQuestionShow(state, payload) {
        state.question = payload;
    },
}

const actions = {
    async actAnswerSubmit(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/answer-sheets`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },

    async actTestList ({ commit }, query = '')  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/tests/pte-practice-list${query}`)
                    .then( response => {
                        console.log(response.items)
                        commit('setTestList', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actShowQuestion ({ commit }, question_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/questions/${question_id}`)
                    .then( response => {
                        commit('setQuestionShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actGetQuestionTestId(store , payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/question-test`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}