var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex",class:[_vm.container,
      _vm.inline ? 'flex-row align-center' : 'flex-col'
  ]},[(_vm.label)?_c('label',{staticClass:"pr-4 inline-block mb-1 text-uppercase font-bold",class:[
      _vm.requiredLabel ? 'required' : '',
      _vm.italic ? 'text-italic' : '',
    ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-col basis relative"},[_c('md-autocomplete',{staticClass:"autocomplete-component input-box-shadow pointer flat rounded min-h-auto",class:[
        _vm.borderNone ? 'border-none' : 'outline-gray-400', ..._vm.classes
      ],attrs:{"value":this.value,"md-options":this.items,"placeholder":_vm.placeholder,"md-layout":"box","md-dense":""},on:{"input":_vm.onSelect},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function({ item, term }){return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item.name))])]}}])},[(_vm.placeholder)?_c('label',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_c('template',{slot:"md-autocomplete-empty"},[_vm._v(" No item matching ")])],2),_c('md-icon',{staticClass:"arrow-icon",class:[_vm.iconClass]},[_vm._v("arrow_drop_down")]),(_vm.message)?_c('b',{staticClass:"text-danger",attrs:{"id":"scrollTop"}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }