<template>
  <div
    class="flex"
    :class="[
        inline ? 'flex-row align-center': 'flex-col',
    ]"
  >
    <label
      v-if="label"
      for="input"
      class="mb-1 pr-4 text-uppercase font-bold block"
      :class="[
      requiredLabel ? 'required' : '',
      italic ? 'text-italic' : '',
      opacityClass,
      labelClass
      ]">
      {{ label }}
    </label>
    <div class="flex flex-col basis">
      <md-field>
        <md-textarea 
        style="box-shadow: 0px 0px 4px 1px #bcc2d4cf"
        class="border border-solid rounded border-gray-400 input-box-shadow arrow_hidden"
        :class="[inputClass]"
        v-model="values"
        :disabled="disabled"
        :readonly="readOnly"
            md-autogrow>
        </md-textarea>
      </md-field>
      <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    italic: {
      type: Boolean,
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    inputClass: {
      type: String,
      default: () => 'p-3'
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    inline: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    value: {
      type: [String, Array, Number]
    },
    type: {
      type: String,
      default: () => 'text'
    },
    readOnly: {
      type: Boolean,
      default: () => false
    },
    condition: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    maxlength: {
      type: String,
    }
  },
  computed: {
    values: {
      get() {
        return console.log('kx',this.value)
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
};
</script>
<style>

</style>