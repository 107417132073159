<template>
  <md-dialog :md-active.sync="showDialog">
    <slot></slot>
  </md-dialog>
</template>

<script>

export default {
  computed: {
    showDialog: {
      get() {
        return this.$store.state.showDialog;
      },
      set(value) {
        this.$store.commit("setShowDialog", value);
      },
    },
  },
};
</script>