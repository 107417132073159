import api from '../api'

const state = () => ({
    explanation: {},
    OrganizationsList: [],
    OrganizationsMeta: [],
    SelectedOrganizationsList: [],
    isAcceptTerms: true
});




const getters = {
    getIsAcceptTerms: state => state.isAcceptTerms,
    getOrganizationsList: (state) => {
    
        return state.OrganizationsList.map((item, index) => {
             item.sl = index + 1;
             return item;
         })
     },
    // getOrganizationsList: state => console.log(state.OrganizationsList),
    getOrganizationsMeta: state => state.OrganizationsMeta,
    getSelectedOrganizationsList:state => state.SelectedOrganizationsList,
}


const mutations = {
    setIsAcceptTerms: (state, payload) => state.isAcceptTerms = payload,
    setPackageList: (state, PackageList) => state.PackageList = PackageList,
    setPackageMeta: (state, PackageMeta) => state.PackageMeta = PackageMeta,
    setSelectedPackageList: (state, PackageList) => state.SelectedPackageList = PackageList,
    setUsersList: (state, UsersList) => state.UsersList = UsersList,
    setUsersMeta: (state, UsersMeta) => state.UsersMeta = UsersMeta,
    setSelectedUsersList: (state, UsersList) => state.SelectedUsersList = UsersList,

    setOrganizationsList(state, OrganizationsList) {
    
        state.OrganizationsList = OrganizationsList;
    },

    // setOrganizationsList: (state, OrganizationsList) => state.OrganizationsList = OrganizationsList,
    setOrganizationsMeta: (state, OrganizationsMeta) => state.OrganizationsMeta = OrganizationsMeta,
    setSelectedOrganizationsList: (state, OrganizationsList) => state.SelectedOrganizationsList = OrganizationsList,

}

const actions = {
    async actCountryList ()  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/country-list`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPostalCodesByCountry (store, countryId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/postal-code/${countryId}`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPostalCodes ()  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/postal-code`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actStudentRegister(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/student-registration`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPackages({ commit } , query = '') {
    
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/packages${query}`)
                    .then(({items, meta}) => {
                        
                        commit('setPackageList', items);
                        commit('setPackageMeta', meta);
                        commit('setSelectedPackageList', items[0]);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPackageBasePrice() {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/package-base-price`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actPackagesList(store, organizationId) {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/packages/organization/${organizationId}`)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actInvoiceInstalment(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/invoice-instalment`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actTransaction(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/transaction`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },

    async actSavePackage(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/packages`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actUpdatePackage(store, {packageId, payload}) {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/packages/${packageId}`, payload)
                    .then(({ message }) => {
                        resolve(message);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actGetPackageById(store, packageId) {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/packages/${packageId}`)
                    .then((items) => {
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actOrganizationSave(store, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/organizations`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actGetPromoCode(store, promoCode) {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/promo-code/${promoCode}`)
                    .then((items) => {
                        resolve(items);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actGetUsers({ commit } , query = '') {
    
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/users${query}`)
                    .then(({items, meta}) => {
                        
                        commit('setUsersList', items);
                        commit('setUsersMeta', meta);
                        commit('setSelectedUsersList', items[0]);

                        
                        
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

    async actGetOrganizations({ commit } , query = '') {
    
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/organizations${query}`)
                    .then(({items, meta}) => {
                        
                        commit('setOrganizationsList', items);
                        commit('setOrganizationsMeta', meta);
                        commit('setSelectedOrganizationsList', items[0]);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }