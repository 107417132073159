<template>
  <div :class="bottomMargin" class="flex justify-between flex-wrap justify-sm-center justify-xs-center">
    <div class="inline-flex align-center m-2">
      <md-icon v-if="icon">{{ icon }}</md-icon>
      <slot v-else></slot>
      <h3 class="pl-2 text-uppercase font-semibold">{{ label }}</h3>
      <span v-if="count" class="text-uppercase ml-2">( Total {{ count }} Tests )</span>
    </div>
    <div class="inline-flex align-center">
      <SearchBox
        v-if="searchBox"
        placeholder="search"
        class="p-2 w-64 w-xs-48 m-2"
        v-model="keyword"
        :class="inputBg"
        @submit="submitSearch"
      />
      <md-button
        v-if="creatable"
        class="ml-5 text-white rounded-md md-dense m-0 text-uppercase"
        :class="buttonClass"
        @click="onCreate"
      >
        <md-icon class="text-white">add</md-icon> Create New
      </md-button>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/atom/SearchBox";
import { mapMutations } from "vuex";

export default {
  props: {
      icon: {
          type: String
      },
      label: {
          type: String,
          required: true
      },
      inputBg: {
        type: String,
        default: () => null
      },
      count: {
        type: Number,
        default: () => null
      },
      searchBox: {
        type: Boolean,
        default: () => true
      },
      buttonClass: {
        type: String,
        default: () => 'bg-green'
      },
      showDialog: {
        type: Boolean,
        default: () => true
      },
      creatable: {
        type: Boolean,
        default: () => true
      },
      bottomMargin: {
        type: String,
        default: () => 'mb-4'
      }
  },
  components: {
    SearchBox,
  },
  data() {
    return {
      keyword: ''
    }
  },
  watch: {
    keyword(oldValue, newValue) {
      this.$emit('getKeyword', {
        oldValue,
        newValue
      })
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onCreate() {
      this.$emit('on-create');
      if(this.showDialog) {
         this.dialog(true);
      }
    },
    submitSearch() {
      this.$emit('submit', this.keyword)
    }
  }
};
</script>
