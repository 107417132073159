<template>
  <div style="display: flex; flex-wrap: wrap">
    <div
      style="border: 5px solid transparent"
      :style="sidebar != 'default' ? 'width:100%': 'width:50%'"
      v-for="(item, index) in items"
      :key="index"
    >
      <MenuItem :item="item" :key="index"></MenuItem>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/atom/MenuItem";
import { mapState } from 'vuex';
export default {
  components: {
    MenuItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.sidebar,
    })
  },
};
</script>
