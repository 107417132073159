<template>
  <div class="switch">
    <template v-for="(item, index) in items">
      <div 
        @click="onClicked(item, index)"
        class="today"
        :class="{'active': index == currentIndex}"
        :key="item.id"
      >{{ item.name }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {id: 1, name: 'Today'},
        {id: 2, name: 'Next week'}
      ]
    },
    source: {
      type: Array
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    onClicked(item, index) {
      this.currentIndex = index;
      this.$emit('on-switch', {
        source: this.source,
        item: item
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/components/_switch';
</style>
