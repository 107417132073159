<template>
  <div class="event-item py-5" :class="isDivider">
    <h2 class="pb-2 text-uppercase">{{ title }}</h2>
    <div class="pb-2">
      <p class="label">Running</p>
      <p class="value">10</p>
    </div>
    <div>
      <p class="label">Running</p>
      <p class="value">10</p>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title : {
            type: String,
            default: () => 'Promotion'
        },
        divider: {
            type: Boolean
        }
    },
    computed: {
        isDivider() {
            return this.divider ? 'divider': ''
        }
    }
};
</script>

<style lang="scss">
.event-item {
  display: inline-block;
  h2 {
    font-size: 1rem;
  }
  .label {
    font-size: 1em;
    font-weight: 600;
  }
  .value {
    font-size: 19px;
    color: var(--md-theme-default-primary);
    font-weight: bold;
  }
}
.divider {
   position: relative;
   padding-left: 15px;
   &::after {
       content: '';
       position: absolute;
       top: 50%;
       left: 0;
       width: 1px;
       height: 60%;
       background: rgb(189, 185, 185);
       transform: translateY(-50%);
   }
}
</style>
