export const packages = [

    {
      path: '/package',
      name: 'master.package',
      component: () => import(/* webpackChunkName: "master-portal" */ '../views/package/IndexView.vue'),
      meta: { layout: 'master' },
    },
    {
      path: '/package',
      name: 'master.package.create',
      component: () => import(
        /* webpackChunkName: "package-create" */
        '../views/package/CreateView.vue'),
      meta: {layout: 'master'},
      redirect: {name: 'packageCreation.basicInformation'},
      children: [
          {
              path: 'basic-information',
              name: 'packageCreation.basicInformation',
              component: () => import(
                /* webpackChunkName: "package-basic-infromation" */
                '../views/package/BasicInformationView.vue'),
              meta: {layout: 'master'}
          },
          {
            path: 'type',
            name: 'packageCreation.courseType',
            component: () => import('../views/package/CourseTypeView.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'adons',
            name: 'packageCreation.adons',
            component: () => import('../views/package/adOns.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/pte-practice',
            name: 'packageCreation.properties.ptePractice',
            component: () => import('../views/package/PtePractice.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/practice-test',
            name: 'packageCreation.properties.practiceTest',
            component: () => import('../views/package/PracticeTest.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/mock-test',
            name: 'packageCreation.properties.mockTest',
            component: () => import('../views/package/MockTest.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/quiz',
            name: 'packageCreation.properties.quiz',
            component: () => import('../views/package/Quiz.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/materials',
            name: 'packageCreation.properties.materials',
            component: () => import('../views/package/Materials.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/live-class',
            name: 'packageCreation.properties.liveClass',
            component: () => import('../views/package/LiveClass.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/webinars',
            name: 'packageCreation.properties.webinars',
            component: () => import('../views/package/Webinars.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'properties/one-one-apointment',
            name: 'packageCreation.properties.OneToOneAppointment',
            component: () => import('../views/package/OneToOneAppointment.vue'),
            meta: {layout: 'master'}
          },
          {
            path: 'duration-price',
            name: 'packageCreation.durationAndPrice',
            component: () => import('../views/package/DurationAndPrice.vue'),
            meta: {layout: 'master'}
        },
        {
            path: 'review',
            name: 'packageCreation.review',
            component: () => import('../views/package/Review.vue'),
            meta: {layout: 'master'}
        },
        {
            path: 'preview',
            name: 'packageCreation.preview',
            component: () => import('../views/package/Preview.vue'),
            meta: {layout: 'master'}
        },
      ]
    }, 
];