<template>
  <md-drawer :md-active.sync="showNavigation" md-swipeable class="p-4">
    <UserAvatar class="mb-8" :avatar="avatar"/>
     <ul>
        <router-link
          :to="{ name: item.path }"
          v-for="(item, index) in items"
          :key="index"
          @click.native="linkClicked" 
        >
       <li  class="flex align-center mb-3 px-2 py-1 mobile-menu-item mobile-menu-active rounded">
            <SvgIcon style="width: 25px; margin-right: 15px" :path="item.icon" />
            <span class="inline-block text-base text-medium text-white text-uppercase">{{ $t(item.name) }}</span>
       </li>
       </router-link>
     </ul>
  </md-drawer>
</template>

<script>
import UserAvatar from "@/components/molecule/user/UserAvatar";
import { SvgIcon } from "@/components"
export default {
  components: {
    UserAvatar,
    SvgIcon
  },
   props: {
    items: {
      type: Array,
      required: true,
    },
    avatar: {
      type: Object
    }
  },
  computed: {
    showNavigation: {
      get() {
        return this.$store.state.showNavigation;
      },
      set(value) {
        this.$store.commit("toggleDrawer", value);
      },
    },
  },
  methods: {
    linkClicked() {
      this.$store.commit("toggleDrawer", false);
    }
  }
};
</script>

<style lang="scss">
.md-drawer {
  max-width: 250px !important;
  z-index: 10000000000 !important;
}
.mobile-menu-item {
  transition: .5s;
  .icon-svg {
    fill: #eeeeee !important;
  }
  &:hover {
    background-color: rgba(255,255,255, .2);
  }
}
.router-link-active .mobile-menu-active {
  background-color: rgba(255,255,255, .2);
}
</style>
