<template>
  <div class="flex">
      <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[0]"
          />
          <InputFieldComponent
              class="w-10 mr-5"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[1]"
          />
          <InputFieldComponent
            class="w-10 mr-1"
            maxlength="1"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="account_number[2]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[3]"
          />
          <InputFieldComponent
              class="w-10 mr-5"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[4]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[5]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[6]"
          />
          <InputFieldComponent
              class="w-10 mr-5"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[7]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[8]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[9]"
          />
          <InputFieldComponent
              class="w-10 mr-1"
              maxlength="1"
              :requiredLabel="true"
              label-class="text-sm"
              v-model.trim="account_number[10]"
          />

  </div>
</template>

<script>

import {
  InputFieldComponent,
} from "@/components"

export default {
   props: {
    data: {
      type: Array,
      default: () => null
    },
  },
  components: {
    InputFieldComponent
  },
  data() {
    return {
        account_number: []
    };
  },
  methods: {

  },
  created(){
    if(this.data) this.account_number = this.data;
  }
}
</script>
