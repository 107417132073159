import api from '../../api';
// import router from '@/router'

export const actGetApplicantList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/trainer-application${query}`)
                .then( ({ items, meta }) => {
                 
                    commit('setApplicantList', items);
                    commit('setApplicantMeta', meta);
                    commit('setSelectedApplicantList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetApplicantListByJobId = ({ commit } , jobId = '', query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/job-circular/trainer-application/${jobId}${query}`)
                .then( ({ items, meta }) => {
                    commit('setApplicantList', items);
                    commit('setApplicantMeta', meta);
                    commit('setSelectedApplicantList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actSaveJobApplication = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            api('post', `api/v1/trainer-application`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actUpdateJobApplication = ( store, {trainerApplicationId, payload})  => {
    return new Promise((resolve, reject) => {
        try {
            api('put', `api/v1/trainer-application/${trainerApplicationId}`, payload)
                .then( response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actDeleteApplication = ({ commit } , jobId) => {
    return new Promise((resolve, reject) => {
        try {
            api('delete', `api/v1/trainer-application/${jobId}`)
                .then(({ message }) => {
                    commit('setRemoveDeletedItem', jobId)
                    resolve(message);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}


