import api from '../api';

const state = () => ({
    
   
});


const getters = {
    
   
}


const mutations = { }
const actions = {
    async actExamEnroll ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', 'api/v1/exam-enrolls', payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actExamEnrolledQuestions ( store , examEnrollId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/exam-enrolls/questions/${examEnrollId}`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actSaveAnswerSheet ( store , payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/answer-sheets`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /**
        Exam enroll list for teacher panel (Manual evaluation)
    */
    async actExamEnrollList ()  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/exam-enrolls/list`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /**
        Preview question exam enroll wise for teacher manual evaluation
    */
    async actExamEnrollPreview (store, examEnrollId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/exam-enrolls/questions/${examEnrollId}`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actSaveAnswerEvaluation (store, payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/answer-sheets/${payload.answerSheetId}`, payload.data)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actStudentExamEnrolledList (store, query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/exam-enrolls${query}`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    /**
        Student exam result enrolled wise
    */
    async actStudentExamEnrolledResult (store, examEnrollId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/result-cards/${examEnrollId}`)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }