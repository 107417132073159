import axios from 'axios';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

function getToken(){
  return localStorage.getItem('token');
}

const  instance =  axios.create({
    baseURL: (process.env.NODE_ENV === "development") ? process.env.VUE_APP_BASE_URL_DEV: process.env.VUE_APP_BASE_URL_PROD,
    headers: {
      Authorization: 'Bearer ' + getToken(),
      token2: localStorage.getItem('token2')
    }
});



/**
 * @param acl {'private' | 'public'}
 */
export default (method, url, data = null,  acl = 'private') => {
  
  return new Promise((resolve, reject) => {
      instance({method, url, data})
      .then(res => {
        
        resolve(res.data);
      }).catch(error => {
      
        errorHandler(error.response.status, acl);
      reject(error);
    })
  })
};


// Global error handling
function errorHandler(code, acl) {
 
  // Unauthorized handler
  if(code == '401') {
    // localStorage.removeItem('token')
    // localStorage.removeItem('user_id')
    
    if(acl === 'private'){
      location.replace('/');
      // var firstSegment = window.location.pathname.split('/')[1];
      // var userType = localStorage.getItem('user_type')
      // if(userType){
      //   if(userType.toLowerCase() !== firstSegment && (firstSegment == 'master' || firstSegment == 'admin' || firstSegment == 'student' || firstSegment == 'teacher' )){
      //     // window.location.replace('/'+userType.toLowerCase()+'/portal');
      //   }
      // }else{
      //   // location.replace('/');
      // }
    }
  }
}