export default [
    {
        path: '/registration',
        name: 'registration',
        component: () => import(
            /* webpackChunkName: "registration" */
            '../views/registration/RegistrationView.vue'
            ),
        meta: { layout: 'registration' },
        redirect: {name: 'basicInformation'},
        children: [
            {
                path: 'basic-information',
                name: 'basicInformation',
                component: () => import(
                    /* webpackChunkName: "basic-information" */
                    '../views/registration/BasicInformationView.vue'),
                meta: { layout: 'registration' }
            },
            {
                path: 'details',
                name: 'registrationDetails',
                component: () => import(
                    /* webpackChunkName: "details" */
                    '../views/registration/DetailsView.vue'),
                meta: { layout: 'registration' }
            },
            {
                path: 'course',
                name: 'registrationCourse',
                component: () => import(
                    /* webpackChunkName: "details" */
                    '../views/registration/CourseView.vue'),
                meta: { layout: 'registration' }
            },
            {
                path: 'confirmation',
                name: 'registrationConfirmation',
                component: () => import(
                    /* webpackChunkName: "confirmation" */
                    '../views/registration/ConfirmationView.vue'),
                meta: { layout: 'registration' }
            },
            {
                path: 'payment',
                name: 'registrationPayment',
                component: () => import(
                    /* webpackChunkName: "payment" */
                    '../views/registration/PaymentView.vue'),
                meta: { layout: 'registration' }
            },
            {
                path: 'success',
                name: 'registrationSuccess',
                component: () => import(
                    /* webpackChunkName: "payment" */
                    '../views/registration/SuccessView.vue'),
                meta: { layout: 'registration' }
            },
            
        ]
    },
    {
        path: '/set-password',
        name: 'setPassword',
        component: () => import(
            /* webpackChunkName: "payment" */
            '../views/registration/PasswordView.vue'),
        meta: { layout: 'registration' }
    },
]