export const branchManagement = [
    {
       path: 'branch-management',
       name: 'master.branchManagement',
       component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/branch-management/IndexView.vue'),
       meta: { layout: 'master' },
       redirect: {name: 'master.branchManagement.branchCreation'},
       children: [
           /*
             01. ======= Branch Creation Routes Start ========
           */
           {
               path: 'branch-creation',
               name: 'master.branchManagement.branchCreation',
               redirect: {name: 'master.branchManagement.branchCreation.listOfBranch'},
               component: () => import(
                   /* webpackChunkName: "master-branch-management-toolKit" */
                   '../../views/master/branch-management/branch-creation/IndexView.vue'
                   ),
               meta: { layout: 'master' },
               children: [
                   {
                       path: 'list-of-branch',
                       name: 'master.branchManagement.branchCreation.listOfBranch',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-toolKit" */
                           '../../views/master/branch-management/branch-creation/ListOfBranchView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'branch-contract',
                       name: 'master.branchManagement.branchCreation.branchContract',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-toolKit" */
                           '../../views/master/branch-management/branch-creation/BranchContractView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'support',
                       name: 'master.branch-management.branchCreation.support',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-support" */
                           '../../views/master/branch-management/branch-creation/SupportView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'branch-ad-ons',
                       name: 'master.branch-management.branchCreation.branchAdOns',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-branch-ad-ons" */
                           '../../views/master/branch-management/branch-creation/BranchAdOnsView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'payment',
                       name: 'master.branch-management.branchCreation.payment',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-payment" */
                           '../../views/master/branch-management/branch-creation/PaymentView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'communication',
                       name: 'master.branch-management.branchCreation.communication',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-communication" */
                           '../../views/master/branch-management/branch-creation/CommunicationView.vue'),
                       meta: { layout: 'master' },                                                                                         
                   },

               ]
           },
           /*
             02. ======= Enrollment Management Routes Start ========
           */
           {
               path: 'enrollment-management',
               name: 'master.branch-management.enrollmentManagement',
               redirect: {name: 'master.branch-management.enrollmentManagement.enrollmentData'},
               component: () => import(
                   /* webpackChunkName: "master-branch-management-enrollment-management" */
                   '../../views/master/branch-management/enrollment-management/IndexView.vue'
                   ),
               meta: { layout: 'master' },
               children: [
                   {
                       path: 'enrollment-data',
                       name: 'master.branch-management.enrollmentManagement.enrollmentData',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-enrollment-management-enrollment-data" */
                           '../../views/master/branch-management/enrollment-management/EnrollmentDataView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'discount-or-promotion',
                       name: 'master.branch-management.testManagement.discountOrPromotion',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-enrollment-management-discount-or-promotion" */
                           '../../views/master/branch-management/enrollment-management/DiscountOrPromotionView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'due-and-collection',
                       name: 'master.branch-management.testManagement.dueAndCollection',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-enrollment-management-due-and-collection" */
                           '../../views/master/branch-management/enrollment-management/DueAndCollectionView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'expired-student',
                       name: 'master.branch-management.testManagement.expiredStudent',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-enrollment-management-expired-student" */
                           '../../views/master/branch-management/enrollment-management/ExpiredStudentView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'communication',
                       name: 'master.branch-management.testManagement.communication',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-enrollment-management-communication" */
                           '../../views/master/branch-management/enrollment-management/CommunicationView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
               ]
           },
           /*
             03. ======= Course Management Routes Start ========
           */
           {
               path: 'course-management',
               name: 'master.branch-management.courseManagement',
               redirect: {name: 'master.branch-management.courseManagement.courseList'},
               component: () => import(
                   /* webpackChunkName: "master-branch-management-course-management" */
                   '../../views/master/branch-management/course-management/IndexView.vue'
                   ),
               meta: { layout: 'master' },
               children: [
                   {
                       path: 'course-list',
                       name: 'master.branch-management.courseManagement.courseList',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management-course-list" */
                           '../../views/master/branch-management/course-management/CourseListView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'course-contract',
                       name: 'master.branch-management.courseManagement.courseContract',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management-course-contract" */
                           '../../views/master/branch-management/course-management/CourseContractView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'course-ad-ons',
                       name: 'master.branch-management.courseManagement.courseAdOns',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management-course-ad-ons" */
                           '../../views/master/branch-management/course-management/CourseAdOnsView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   },
                   {
                       path: 'course-comparison',
                       name: 'master.branch-management.courseManagement.courseComparison',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management-course-comparison" */
                           '../../views/master/branch-management/course-management/CourseComparisonView.vue'
                           ),
                       meta: { layout: 'master' },
                   },
                   {
                       path: 'faqs',
                       name: 'master.branch-management.courseManagement.faqs',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management-faqs" */
                           '../../views/master/branch-management/course-management/FAQSView.vue'
                           ),
                       meta: { layout: 'master' },
                   },
                   {
                       path: 'communication',
                       name: 'master.branch-management.courseManagement.communication',
                       component: () => import(
                           /* webpackChunkName: "master-branch-management-course-management" */
                           '../../views/master/branch-management/course-management/CommunicationView.vue'
                           ),
                       meta: { layout: 'master' },                                                                                         
                   }
               ]
           }
       ]
   }
]
  
