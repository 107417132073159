<template>
  <div
  class="flex"
  :class="[{'flex-col' : block}, align]"
  >
    <label
      v-if="label"
      class="pr-4 text-right text-uppercase font-bold"
      :class="topLabelClass"
      >
      {{ label }}
    </label>
    <template v-for="(item, index) in radioItems">
      <md-radio
        :key="index"
        v-model="radio"
        :value="item.id"
        :class="[labelClass, item.active]"
      >
        <span
          class="rounded p-2 py-1 pr-6"
          :class="{'outline-gray-400':labelOutline}"
          >{{ item.label }}</span
        >
      </md-radio>
    </template>
    <div id="scrollTop" class="text-xs text-danger" v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => null
    },
    message: {
      type: String,
      default: () => null,
    },
    labelClass: {
      type: String,
      default: () => null
    },
    topLabelClass: {
      type: String,
      default: () => null
    },
    labelOutline: {
      type: Boolean,
      default: () => false
    },
    block: {
      type: Boolean,
      default: () => false
    },
    align: {
      type: String,
      default: () => 'align-center'
    },
    activeClass: {
      type: String,
      default: () => null
    },
    returnType: {
      type: String
    },
    selectedIndex: {
      type: Number
    },
    value: {
      type: [String, Number, Array],
    },
    
    items: {
      type: Array,
      default: () => [
        { id: 1, label: "Answer 01", active: ""},
        { id: 2, label: "Answer 02", active: "" },
      ],
    },
  },
  data() {
    return {
       handleSection: ''
    }
  },
  mounted(){
    console.log("dd",this.items)
  },
  computed: {
    radioItems() {
      if(this.activeClass) {
        return this.items.map((item, index) => {
          item.active = "";
          if(index == 0) {
             item.active = this.activeClass
          }
          return item
        })
      }
     
      return this.items
    },
    radio: {
      get() {
        if (this.items.length > 0) {
          if(this.selectedIndex) {
            return this.items[this.selectedIndex].id;
          }
          if(this.value) {
            return this.value;
          }
          // return this.items[0].id;
        }

        return null;
      },
      set(value) {
        let index = this.items.findIndex(item => item.id == value);
        if(this.returnType == 'object') {
          this.$emit('getObject', this.items[index]);
          console.log("checkd",this.items[index])
        }
        this.$emit("input", value);
        console.log("check",value)
        // if(value != "MANUAL"){
        //   if(value != "FULL_MOCK" && value != "PARTIAL_MOCK" && value != "FULL_MOCK"){
        //     localStorage.setItem("HandleItems", value);
        //     if(value != "speaking" && value != "writing" && value != "reading"  && value != "listening"){
        //       localStorage.setItem("QType", value);
        //     } 
        //   }
        // } 
      }
    },
  }
};
</script>
