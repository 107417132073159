<template>
  <div class="task-list-component rounded-lg white-text bg-green">
    <md-list class="p-3">
      <md-list-item class="task-divider">
        <md-icon>assignment</md-icon>
        <span class="title md-list-item-text">{{ $t(label) }}</span>
      </md-list-item>
      <template v-for="(item, index) in tasks">
        <md-list-item
          @click="$emit('on-change-status',index)"
          class="task-divider"
          :key="index"
          :class="{'done': item.done}"
        >
          <md-icon>{{ (item.done) ? 'check_circle': 'check_box_outline_blank' }}</md-icon>
          <span class="md-list-item-text">{{ item.label }}</span>
        </md-list-item>
      </template>
    </md-list>
    <div class="remaining">
      {{ remaining }} tasks remaining
      </div>
  </div>
</template>
<script>
export default {
  name: "Task-List",
  props: {
    label: {
      type: String,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    remaining: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss">
.task-divider {
  border-bottom: 1px solid rgb(218, 218, 218);
  .md-list-item-content,
  .md-icon {
    color: #fff !important;
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}
.done {
  .md-list-item-content {
    opacity: 0.8;
  }
}
.remaining {
  background: rgba($color: #000, $alpha: .3);
  padding: 8px 0;
  text-align: center;
  color: #fff;
  opacity: .5;
  font-size: 15px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.task-list-component .md-list.md-theme-default {
  background-color: transparent !important;
}
</style>
