import api from '../api'

const state = () => ({
    question: {},
    getQuizs: [],
    quizs: [],
    quizData: [],
    quizId: {},
    evaluation: [],
    QuizListMeta: []
});


const getters = {
    getQuizs: state =>  state.quizs,
    getQuizData: state =>  state.quizData,
    getQuestion: state =>  state.question,
    getQuizId: state =>  state.quizId,
    getEvaluation: state => state.evaluation,
    getQuizListMeta: state => state.QuizListMeta,
}


const mutations = {

    setQuizList(state, payload) {
        state.quizs = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setQuizData(state, payload) {
        state.quizData = payload;
    },
    setShowQuizs(state, payload) {
        state.question = payload;
    },
    setQuizSetId(state, payload) {
        state.quizId = payload;
    },
    setEvaluationData(state, payload){
        state.evaluation = payload;
    },
    setQuizListMeta(state, QuizListMeta) {
        state.QuizListMeta = QuizListMeta
    },
}

const actions = {
    async actAnswerSubmit(store,payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/quiz-test/evaluation/${payload.quiz_set_id}`, payload)
                    .then( response => {
                        resolve(response);
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actQuizStart({commit}, payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/quiz-test/start`, payload)
                    .then(response => {
                        commit('setQuizSetId',response.quiz_test);
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actQuizSetList ({ commit }, query)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-test${query}`)
                    .then(response => {
                        commit('setQuizList', response.items);
                        commit('setQuizListMeta', response.meta);
                        commit('setQuizData', response.summary);
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    async actShowQuizs ({ commit }, quiz_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-test/${quiz_id}?order=random`)
                    .then( response => {
                        commit('setShowQuizs', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actGetQuizSetId(store , payload) {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/question-test`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
        })
    },
    async actEvaluationData ({ commit }, quiz_set_id)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/quiz-test/evaluation/${quiz_set_id}`)
                    .then( ({items}) => {
                        commit('setEvaluationData',items);
                        resolve(items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


