<template>
  <div class="rounded p-5 mb-3" :class="bgColor">
    <h3 class="text-uppercase text-xl" :class="`text-${align} text-${color}`">
      {{ label }}
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      validator: function(value) {
        return ["left", "center", "right"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      default: () => "white",
    },
    bgColor: {
      type: String,
      default: () => "bg-victoria",
    },
  },
};
</script>

<style></style>
